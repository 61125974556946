import BaseModel from "./BaseModel";
import ENDPOINTS from "../constants/Endpoints";
import Channel from "../interfaces/Channel";
import {Gender} from "../interfaces/Gender";


class ChannelModel extends BaseModel<Channel, null> {

    getInstanceId(item: Channel): number | null {
        return item.id;
    }

    public createBlank = async (): Promise<null> => {
        return null;
    }

    public changeActiveStatus = (item: Channel): Promise<Channel> => {
        const channel = {...item, isActive: !item.isActive};
        return this.update(channel);
    }

    public changeGender = (item: Channel, gender: Gender | undefined): Promise<Channel> => {
        const channel = { ...item, gender: gender !== undefined ? gender : null };
        return this.update(channel);
    }

}

export default new ChannelModel(ENDPOINTS.channel);