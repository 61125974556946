import {MouseEventHandler, PureComponent} from "react";
import {Props, State} from "./ChannelsListShapes";
import Template from "./ChannelsListTemplate";
import {message} from "antd";
import Channel from "../../interfaces/Channel";
import ChannelModel from "../../models/ChannelModel";
import ROUTES from "../../constants/Routes";
import TelegramService from "../../services/TelegramService";
import {HandlerGenderChangeWithId} from "../../interfaces/Gender";

export class ChannelsList extends PureComponent<Props, State> {

    text = {
        removeSuccess: 'Канал ":title" успешно архивирован',
        removeError: 'При архивировании канала возникла ошибка',
        restoreSuccess: 'Канал ":title" успешно восстановлен',
        restoreError: 'При восстановлении канала произошла ошибка',
        genderChanged: 'Пол канала ":title" успешно изменен',
        genderChangedError: 'При изменении пола канала ":title" произошла ошибка',
        genderReset: 'Пол канала ":title" успешно сброшен',
        genderResetError: 'При сбросе пола канала ":title" произошла ошибка',
    }

    constructor(props: Props) {
        super(props);

        this.state = {
            items: [],
            status: "active",
            telegramBotName: null,
        };
    }

    componentDidMount() {
        this.itemsLoadFromServer()
            .catch(err => console.error(err));

        TelegramService.getBotName()
            .then((response) => {this.setState({telegramBotName: response})});
    }

    itemsLoadFromServer = async () => {
        try {
            const items = await ChannelModel.getAll();
            this.itemsUpdateInList(items);
        } catch (err) {
            console.error(err)
        }
    }

    itemsUpdateInList = (items: Channel[]) => {
        this.setState(state => ({...state, items}));
    };

    itemActiveStatusChange: MouseEventHandler<HTMLButtonElement> = async (e) => {
        const id = Number(e.currentTarget.dataset.id);
        if (!id) return;

        const items = [...this.state.items];
        const index = items.findIndex(el => el.id === id);
        try {
            items[index] = await ChannelModel.changeActiveStatus(items[index]);

            this.setState(state => ({...state, items}), () => {
                message.success(items[index].isActive
                    ? this.text.restoreSuccess.replace(':title', items[index].title)
                    : this.text.removeSuccess.replace(':title', items[index].title)
                );
            });
        } catch (err) {
            items[index].isActive
                ? message.error(this.text.removeError)
                : message.error(this.text.restoreError);

            console.error(err.message);
        }
    }

    itemGenderChange: HandlerGenderChangeWithId = async (gender, id) => {
        if (!id) return;

        const items = [...this.state.items];
        const index = items.findIndex(el => el.id === id);

        try {
            items[index] = await ChannelModel.changeGender(items[index], gender);

            this.setState(state => ({...state, items}), () => {
                message.success(items[index].gender
                    ? this.text.genderChanged.replace(':title', items[index].title)
                    : this.text.genderReset.replace(':title', items[index].title)
                );
            });
        } catch (err) {
            items[index].gender
                ? message.error(this.text.genderChangedError)
                : message.error(this.text.genderResetError);

            console.error(err.message);
        }
    }

    showActive = () => {
        this.setState(state => ({
            ...state,
            status: this.state.status === 'active' ? 'all' : 'active',
        }));
    };

    showArchived = () => {
        this.setState(state => ({
            ...state,
            status: this.state.status === 'archived' ? 'all' : 'archived',
        }));
    };

    get items() {
        switch (this.state.status) {
            case "active":
                return this.state.items.filter(el => el.isActive);
            case "archived":
                return this.state.items.filter(el => !el.isActive);
            default:
                return this.state.items;
        }
    }

    handleGoToList = () => {
        this.props.history.push(ROUTES.channels.replace(':cid?', ''))
    }

    render() {
        return Template({
            ...this.state,
            ...this.props.match.params,
            items: this.items,
            onItemRemove: this.itemActiveStatusChange,
            onItemRestore: this.itemActiveStatusChange,
            showActive: this.showActive,
            showArchived: this.showArchived,
            handleGoToList: this.handleGoToList,
            onItemGenderChange: this.itemGenderChange,
        });
    };
}

export default ChannelsList;
