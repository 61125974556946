import React from "react";
import {Props} from "./ChannelCardShapes";
import {Avatar} from "antd";
import {FrownOutlined} from "@ant-design/icons";
import "./ChannelCardStyles.scss";

const ChannelCard: React.FC<Props> = ({item}) => (
    <article className="channel-card" data-channel-active={item.isActive}>
        <div className="channel-card__head">
            {
                item.image
                    ? <Avatar className="channel-card__image" src={item.image} size="large"/>
                    : <Avatar className="channel-card__image" icon={<FrownOutlined/>} size="large"/>
            }
        </div>
        <div className="channel-card__body">
            <div className="channel-card__title">{item.title}</div>
            {
                item.description &&
                <div className="channel-card__description">{item.description}</div>
            }
            <div className="channel-card__id">{item.cid}</div>
        </div>
    </article>
);

export default ChannelCard;
