import styled from "styled-components";


export const Wrap = styled.div`
    .ant-transfer-list {
        flex-grow: 1;
        height: 260px;
    }
`;

export const SelectorSearch = styled.span`
    position: absolute;
`;

export const SelectorInput = styled.input`
`;

export const SelectorItem = styled.input`
`;

export const Selector = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

`;

export const SelectorTitle = styled.span`
`;

export const SelectorImage = styled.img`
    width: 15px;
    height: 15px;

    margin-right: 5px;
`;