import React, {useState} from "react";
import {Props} from "./AuthDefaultShapes";
import {Button, Form, Input, message} from "antd";
import {AuthInputChange} from "../../interfaces/AuthInputChange";
import AuthService from "../../../../services/AuthService";
import {LoginDefault} from "../../../../interfaces/LoginDefault";


const AuthDefault: React.FC<Props> = (props) => {
    const [login, setLogin] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const loginChange: AuthInputChange = (e) => {
        const value = e.currentTarget.value;
        setLogin(value);
    };

    const passwordChange: AuthInputChange = (e) => {
        const value = e.currentTarget.value;
        setPassword(value);
    };

    const submit = async () => {
        try {
            setIsLoading(true);
            const authData: LoginDefault = {login, password};
            const user = await AuthService.loginDefault(authData);

            if (user && props.afterSubmit) {
                props.afterSubmit(user);
            }

        } catch (err) {
            setIsLoading(false);
            switch (err.status) {
                case 400:
                    message.warn('Логин или пароль заполнены не правильно!');
                    break;
                default:
                    message.error('При авторизации возникла ошибка!');
                    break;
            }

        }
    };

    return (
        <Form style={{width: 450}}>
            <Form.Item name="login" style={{marginBottom: 10}}>
                <label>
                    <span style={{opacity: 0.6}}>Логин:</span>
                    <Input value={login} onChange={loginChange} autoComplete="off" style={{marginTop: 5}}/>
                </label>
            </Form.Item>
            <Form.Item name="password">
                <label>
                    <span style={{opacity: 0.6}}>Пароль:</span>
                    <Input type="password" value={password} onChange={passwordChange} autoComplete="off"
                           style={{marginTop: 5}}/>
                </label>
            </Form.Item>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                {props.renderAction && props.renderAction()}
                <Button type="primary" htmlType="submit" onClick={submit} disabled={!login || !password || isLoading}>
                    Войти
                </Button>
            </div>
        </Form>
    );
};

export default AuthDefault;