import {AdditionalTag} from "./../../interfaces/AdditionalTag";
import {DatePickerProps} from "antd/lib/date-picker";
import {PureComponent} from "react";
import {message} from "antd";
import {Props, State, TemplateProps} from "./ProductViewShapes";
import Template from "./ProductViewTemplate";
import FilterModel from "../../models/FilterModel";
import Filter from "../../interfaces/Filter";
import HandlerTextInputChange from "../../interfaces/HandlerTextInputChange";
import {Product, ProductSaved} from "../../interfaces/Product";
import {ProductGalleryProps} from "../../components/ProductGallery/ProductGallery-types";
import ProductModel from "../../models/ProductModel";
import {productConvertForTransferData, productFollowerFound, productsLoadFromServerUtil, validateBeforeSendToTelegram} from "../../helpers/ProductUtils";
import TelegramService from "../../services/TelegramService";
import Channel from "../../interfaces/Channel";
import ChannelModel from "../../models/ChannelModel";
import Attachment from "../../interfaces/Attachment";
import AdditionalTagModel from "../../models/AdditionalTagModel";
import ColorModel from "../../models/ColorModel";

export class ProductView extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            product: null,
            products: [],
            productsForTransfer: [],
            filters: [],
            filtersSelected: {},
            channels: [],
            colors: [],
            channelsSelected: {},
            channelSelectVisible: false,
            isSending: false,
            isLoading: false,
            additionalTag: [],
        };
    }

    componentDidMount() {
        if (!this.props.isArchive) {
            this.filtersLoadFromServer()
                .catch(err => console.error(err));

            this.channelsLoadFromServer()
                .catch(err => console.error(err));

            this.additionalTagLoadFromServer()
                .catch(err => console.error(err));

            this.colorsLoadFromServer()
                .catch(err => console.error(err));
        }

        this.productLoadFromServer()
                .catch(err => console.error(err));
    }

    channelsLoadFromServer = async () => {
        try {
            const channels = await ChannelModel.getAll();
            this.channelsUpdateInList(channels);
        } catch (err) {
            console.error(err)
        }
    }

    channelsUpdateInList = (channels: Channel[]) => {
        this.setState(state => ({
            ...state,
            channels: this.props.isArchive
                ? channels
                : channels.filter(el => el.isActive)
        }));
    };

    colorsLoadFromServer = async () => {
        try {
            const colors = await ColorModel.getAllActive();

            this.setState(state => ({
                ...state, colors,
            }));
        } catch (err) {
            console.error(err)
        }
    }

    channelClick = (channel: Channel) => {
        const channelsSelected = {...this.state.channelsSelected};

        if (channelsSelected.hasOwnProperty(channel.id)) {
            delete channelsSelected[channel.id];
        } else {
            channelsSelected[channel.id] = "";
        }

        this.setState(state => ({...state, channelsSelected}));
    };

    productLoadFromServer = async () => {
        try {
            const product = await ProductModel.getById(this.props.productId);

            this.productsLoadFromServer(product)
                .catch(err => console.error(err));

            this.productUpdateInList(product);
        } catch (err) {
            console.error(err)
        }
    }

    productUpdateInList = (product: ProductSaved) => {
        const filtersSelected: State["filtersSelected"] = {};
        const channelsSelected: State["channelsSelected"] = {};

        product.filters.forEach(el => {
            filtersSelected[el.id] = "";
        });

        product.channels.forEach(el => {
            channelsSelected[el.id] = "";
        });

        this.setState(state => {
            return (
                {
                    ...state,
                    product,
                    filters: !this.props.isArchive ? [...state.filters] : [...product.filters],
                    channels: !this.props.isArchive ? [...state.channels] : [...product.channels],
                    filtersSelected,
                    channelsSelected,
                })
        });
    }

    productsLoadFromServer = async (product: ProductSaved) => {
        const products = await productsLoadFromServerUtil(this.props.isArchive);

        const productsWithoutCurrent = products?.reduce((accumulatorProducts: ProductSaved[], currentProduct) => {
            if (currentProduct.id !== product.id) {
                accumulatorProducts.push(currentProduct);
            }

            return accumulatorProducts;
        }, []);

        const productsForTransfer = productsWithoutCurrent?.map(productConvertForTransferData);

        if (productsForTransfer && products) {
            this.setState(state => ({
                ...state,
                productsForTransfer,
                products,
            }));
        }
    }

    filtersLoadFromServer = async () => {
        try {
            const filters = await FilterModel.getAll();
            this.filtersUpdateInList(filters);
        } catch (err) {
            console.error(err)
        }
    }

    filtersUpdateInList = (filters: Filter[]) => {
        this.setState(state => ({
            ...state,
            filters: this.props.isArchive
                ? filters
                : filters.filter(el => el.isActive),
        }))
    }

    additionalTagLoadFromServer = async () => {
        try {
            const additionalTag = await AdditionalTagModel.getAllActive();

            this.setState(state => ({
                ...state, additionalTag,
            }));
        } catch (err) {
            console.error(err)
        }
    }

    filterClick = (filter: Filter) => {
        const filtersSelected = {...this.state.filtersSelected};

        if (filtersSelected.hasOwnProperty(filter.id)) {
            delete filtersSelected[filter.id];
        } else {
            filtersSelected[filter.id] = "";
        }

        this.setState(state => ({...state, filtersSelected}));
    };

    changeSelectedFilters = (filtersIds: number[]) => {
        const filtersSelected: State["filtersSelected"] = {};

        filtersIds.forEach(filtersId => {
            filtersSelected[filtersId] = "";
        });

        this.setState(state => ({...state, filtersSelected}));
    };

    handleAdditionalTagChange: TemplateProps["onAdditionalTagChange"] = (values) => {
        if (this.state.product) {
            const resultProduct = {...this.state.product};
            const resultAdditionalTag: AdditionalTag[] = [];
            const additionalTag = [...this.state.additionalTag];

            for (let index = 0; index < additionalTag.length; index++) {
                const element = additionalTag[index];

                const result = values.find(value => value === element.title);

                if (result) {
                    resultAdditionalTag.push(element);
                }
            }

            resultProduct.additionalTag = [...resultAdditionalTag];

            this.setState(state => ({...state, product: resultProduct}));
        };
    }

    productTitleChange: HandlerTextInputChange = (e) => {
        if (this.state.product) {
            const product = {...this.state.product};
            product.title = e.currentTarget.value;
            this.setState(state => ({...state, product}));
        }
    }

    productPriceChange: HandlerTextInputChange = (e) => {
        if (this.state.product) {
            const field: keyof Product = e.currentTarget.name as 'price' | 'priceDiscount';
            const product = {...this.state.product};
            product[field] = e.currentTarget.value.replace(/[^\d.]/g, '');
            this.setState(state => ({...state, product}));
        }
    }

    productColorChange: HandlerTextInputChange = (e) => {
        if (this.state.product) {
            const product = {...this.state.product};
            product.color = e.currentTarget.value;
            this.setState(state => ({...state, product}));
        }
    }

    productCompositionChange: HandlerTextInputChange = (e) => {
        if (this.state.product) {
            const product = {...this.state.product};
            product.composition = e.currentTarget.value;
            this.setState(state => ({...state, product}));
        }
    };

    productVendorCodeChange: HandlerTextInputChange = (e) => {
        if (this.state.product) {
            const product = {...this.state.product};
            product.vendorCode = e.currentTarget.value;
            this.setState(state => ({...state, product}));
        }
    }

    handleBestChange: TemplateProps["onBestChange"] = (e) => {
        if (this.state.product) {
            const product = {...this.state.product};
            product.isBest = !product.isBest;

            this.setState(state => ({...state, product}));
        };
    }

    productSave = async (product: ProductSaved, filters: Filter[] = [], channels: Channel[] = []): Promise<ProductSaved> => {
        return ProductModel.update({
            ...product,
            priceDiscount: product.priceDiscount || null,
            vendorCode: product.vendorCode || null,
            filters,
            channels,
        });
    }

    imagesChange: ProductGalleryProps["onImageRemove"] = (link) => {
        if (this.state.product) {
            const product = {...this.state.product};
            product.images = product.images.filter(image => image.link !== link);

            this.setState(state => ({...state, product}));
        }
    }

    imagesSelected: ProductGalleryProps["onImageSelect"] = ({currentTarget: {dataset: {link}}}) => {
        if (this.state.product) {
            const product = {...this.state.product};

            product.images = product.images.map(image => ({
                ...image,
                toTelegram: image.link === link ? !image.toTelegram : image.toTelegram,
            }))

            this.setState(state => ({...state, product}));
        }
    };

    updatePositionImages = (images: Attachment[]) => {
        if (this.state.product) {
            const product = {...this.state.product};

            product.images = [...images];

            this.setState(state => ({...state, product}));
        }
    }

    handleChannelSelectOpen = () => {
        this.setState(state => ({...state, channelSelectVisible: true}));
    }

    handleChannelSelectClose = () => {
        this.setState(state => ({...state, channelSelectVisible: false, channelToSend: undefined, isSending: false}))
    }

    handleProductsSend = (channelId: string) => {
        this.setState(state => ({...state, isSending: true}), async () => {
            await this.handleProductSendToTelegram(channelId);
            await this.handleChannelSelectClose();
        })
    };

    handleParseAgain = () => {
        this.setState(state => ({...state, isLoading: true}), async () => {
            await this.parseAgain();
        });
    };

    parseAgain = async () => {
        try {
            if (this.state.product) {
                const product = await ProductModel.updateDraft({...this.state.product});
                this.setState(state => ({...state, isLoading: false, product}))
            }
        } catch (err) {
            message.error("Ошибка при получении данных о товаре");
            console.error(`Product data load error: ${err.message}`);
        }
    }

    handleProductSendToTelegram = async (channelId: string) => {
        if (this.state.product && this.state.product.images.filter(image => image.toTelegram).length > 0) {
            try {
                const product = await this.productSave(
                    this.state.product,
                    this.state.filters.filter(el => this.state.filtersSelected.hasOwnProperty(el.id)),
                    this.state.channels.filter(el => this.state.channelsSelected.hasOwnProperty(el.id))
                )

                if (validateBeforeSendToTelegram(product)) {
                    await TelegramService.sendProduct(product.id, channelId, window.location.origin);
                    message.success("Товар успешно обновлен и отправлен в телегрм канал");
                }

            } catch (err) {
                message.error("При отправке в телеграм произошла ошибка!");
            }
        } else {
            message.warn("Выбрано недостаточно изображений");
        }
    }

    handleDateAddChange: DatePickerProps["onChange"] = (date, dateString) => {
        if (this.state.product) {
            const product = {...this.state.product};

            product.dateAdded = date?.toDate();

            this.setState(state => ({...state, product}));
        }
    }

    handleProductFollowersChange: TemplateProps['onProductFollowersChange'] = (items) => {
        if (this.state.product) {
            const localProduct = {...this.state.product};
            const products = [...this.state.products];

            if (localProduct.followers) {
                localProduct.followers = productFollowerFound(items, products)
            }

            this.setState(state => ({...state, product: localProduct}));
        };
    }

    handleColorSelectedChange: TemplateProps["onColorSelectedChange"] = (values) => {
        if (this.state.product) {
            const resultProduct = {...this.state.product};

            const resultColor = this.state.colors.find(color => {
                return color.title === values;
            })

            if (resultColor) {
                resultProduct.colorSelect = {...resultColor};

                this.setState(state => ({...state, product: resultProduct}));
            }
        }
    }

    handleSeasonSelectedChange: TemplateProps["onSeasonSelectedChange"] = (season) => {
        this.setState(state => {
            return state.product
                ? {...state, product: {...state.product, season}}
                : state
        })
    }

    handleColorSelectedSearch: TemplateProps["onColorSelectedSearch"] = (values) => {
    }

    save = async () => {
        const isChannelsSelected = Object.keys(this.state.channelsSelected).length > 0;

        if (this.state.product && isChannelsSelected) {
            try {
                const product = await this.productSave(
                    this.state.product,
                    this.state.filters.filter(el => this.state.filtersSelected.hasOwnProperty(el.id)),
                    this.state.channels.filter(el => this.state.channelsSelected.hasOwnProperty(el.id)),
                );

                message.success("Товар успешно обновлен");

                if (this.props.afterSaved)
                    this.props.afterSaved(product);

            } catch (err) {
                message.error("Не удалось соханить товар!");
                console.error(err);
            }
        } else {
            !isChannelsSelected && message.error('Выберите хотя бы один "Канал"', 3);
            !this.state.product && message.error("Не удалось найти товар!", 3);
        }
    };

    render() {
        return Template({
            ...this.state,
            isArchive: this.props.isArchive,
            productId: this.props.productId,
            onImagesChanged: this.imagesChange,
            onImagesSelected: this.imagesSelected,
            onTitleChange: this.productTitleChange,
            onPriceChange: this.productPriceChange,
            onColorChange: this.productColorChange,
            onCompositionChange: this.productCompositionChange,
            onVendorCodeChange: this.productVendorCodeChange,
            onSave: this.save,
            onFilterClick: this.filterClick,
            onChannelClick: this.channelClick,
            onChangeSelectedFilters: this.changeSelectedFilters,
            onUpdateProductImages: this.updatePositionImages,
            onDateAddChange: this.handleDateAddChange,
            onBestChange: this.handleBestChange,
            onAdditionalTagChange: this.handleAdditionalTagChange,
            onProductFollowersChange: this.handleProductFollowersChange,
            onColorSelectedChange: this.handleColorSelectedChange,
            onColorSelectedSearch: this.handleColorSelectedSearch,
            onSeasonSelectedChange: this.handleSeasonSelectedChange,

            onProductsSend: this.handleProductsSend,
            onChannelSelectOpen: this.handleChannelSelectOpen,
            onChannelSelectClose: this.handleChannelSelectClose,
            onParseAgain: this.handleParseAgain,
        });
    };
}


export default ProductView;
