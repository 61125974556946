import React from "react";
import {Props} from "./AuthWelcomeShapes";
import {Divider, Typography} from "antd";
import {Link} from "../Routing";
import GooglePlay from "../../assets/svg/mobile-app-googleplay.svg";
import AppleStore from "../../assets/svg/mobile-app-applestore.svg";

const AuthWelcome: React.FC<Props> = () => (
    <>
        <Typography.Title style={{color: "#fff", textAlign: "center"}} level={3}>
            Добро пожаловать <br/> к нам в гости
        </Typography.Title>
        <Typography.Paragraph style={{color: "#fff", textAlign: "center", opacity: 0.5}}>
            Мы еще не определились, какой функционал будет на нашем сайте, но уже можем порадовать вас
            классным конструктором образов!
        </Typography.Paragraph>

        <div style={{marginTop: 20}}>
            <a href="https://olesyaorlova.ru" target="_blank" style={{color: "#40a9ff"}} rel="noopener noreferrer">
                Тут немного о стилисте Олесе Орловой
            </a>
        </div>

        <Divider/>

        <div style={{color: "rgba(255,255,255, 0.7)"}}>У нас скоро будет мобильное приложение</div>
        <div style={{color: "rgba(255,255,255, 0.7)"}}>Но это не точно...</div>

        <div style={{width: "70%", display: "flex", justifyContent: "space-between", paddingTop: 30}}>
            <Link to="#" target="_blank"><img src={GooglePlay} height={38} alt="Загрузить с Play Market"/></Link>
            <Link to="#" target="_blank"><img src={AppleStore} height={40} alt="Загрузить с Apple Store"/></Link>
        </div>
    </>
);

export default AuthWelcome;