import React from "react";
import {TemplateProps} from "./ProductEditorShapes";
import {
    Select,
    Input,
    Button,
    Popconfirm,
    DatePicker,
    Checkbox,
} from "antd";
import moment from "moment";
import "moment/locale/ru";
import locale from "antd/lib/date-picker/locale/ru_RU";

import "./ProductEditorStyles.scss";
import TagCheckable from "../TagCheckable/TagCheckable";
import UploadDropzone from "../UploadDropzone/UploadDropzone";
import {getCurrencySymbol} from "../../helpers/CurrencyTools";
import Cascader from "../Cascader/Cascader";
import TransferItems from "../TransferItems/TransferItems";

import {getSeasonsOptions} from "../../helpers/SeasonUtils";

const {Option, OptGroup} = Select;

const ProductEditorTemplate: React.FC<TemplateProps> = (props) => (
    <div className="product-editor">
        <div className="product-editor__section">
            <div className="product-editor__section-body">
                {props.product?.images && (
                    <UploadDropzone
                        images={props.product.images}
                        onImageRemove={props.onRemoveImage}
                        onImageSelect={props.onSelectImage}
                        onImagesChanged={props.onChangedImages}
                        onUpdatePosition={props.onUpdatePositionImages}
                    />
                )}
            </div>
        </div>

        <div className="product-editor__section">
            <div className="product-editor__section-head">
                <div className="product-editor__label required">Магазин:</div>
            </div>
            <div className="product-editor__section-body">
                <div className="product-editor__source">
                    <div className="product-editor__source-select">
                        <Select
                            className="product-editor__select"
                            onChange={props.onChangeSource}
                        >
                            <OptGroup label="Без парсера">
                                <Option value="custom">
                                    Добавить свой магазин
                                </Option>
                            </OptGroup>
                            <OptGroup label="С парсером">
                                {Object.keys(props.storesList).map((code) => (
                                    <Option value={code} key={code}>
                                        {code}
                                    </Option>
                                ))}
                            </OptGroup>
                        </Select>
                    </div>

                    {props.isSourceCustomVisible && (
                        <div className="product-editor__source-input">
                            <Input
                                className="product-editor__input"
                                value={props.sourceCustom || ""}
                                onChange={props.onChangeSourceCustom}
                                name="sourceCustom"
                                placeholder="Введите название магазина"
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>

        <div className="product-editor__section">
            <div className="product-editor__section-head">
                <div className="product-editor__label">Артикул:</div>
            </div>
            <div className="product-editor__section-body">
                <Input
                    value={props.product?.vendorCode || ""}
                    onChange={props.onChangeVendorCode}
                    name="vendorCode"
                    placeholder="Введите артикул"
                />
            </div>
        </div>

        <div className="product-editor__section">
            <div className="product-editor__section-head">
                <div className="product-editor__label required">
                    Ссылка на оригинал:
                </div>
            </div>
            <div className="product-editor__section-body">
                <Input
                    value={props.product?.sourceUrl || ""}
                    onChange={props.onChangeSourceUrl}
                    name="sourceUrl"
                    placeholder="Введите ссылку на товар"
                />
            </div>
        </div>

        <div className="product-editor__section">
            <div className="product-editor__section-head">
                <div className="product-editor__label required">Название:</div>
            </div>
            <div className="product-editor__section-body">
                <Input
                    value={props.product?.title || ""}
                    onChange={props.onChangeTitle}
                    name="title"
                    placeholder="Введите название"
                />
            </div>
        </div>

        <div className="product-editor__section">
            <div className="product-editor__section-head">
                <div className="product-editor__label required">Стоимость:</div>
            </div>
            <div className="product-editor__section-body">
                <Input
                    value={props.product?.price || ""}
                    onChange={props.onChangePrice}
                    name="price"
                    addonAfter={getCurrencySymbol(props.product?.currency)}
                    placeholder="Введите стоимость"
                    className="product-editor__price"
                />
            </div>
        </div>

        <div className="product-editor__section">
            <div className="product-editor__section-head">
                <div className="product-editor__label">
                    Стоимость со скидкой:
                </div>
            </div>
            <div className="product-editor__section-body">
                <Input
                    value={props.product?.priceDiscount || ""}
                    onChange={props.onChangePriceDiscount}
                    name="priceDiscount"
                    addonAfter={getCurrencySymbol(props.product?.currency)}
                    placeholder="Введите стоимость со скидкой"
                    className="product-editor__price"
                />
            </div>
        </div>

        <div className="product-editor__section">
            <div className="product-editor__section-head">
                <div className="product-editor__label">Цвет:</div>
            </div>
            <div className="product-editor__section-body">
                <Select
                    allowClear
                    showSearch
                    style={{width: "100%"}}
                    placeholder="Введите цвет"
                    onChange={props.onColorSelectedChange}
                    onSearch={props.onColorSelectedSearch}
                    options={props.colorsList.map((color) => {
                        return {
                            label: color.title,
                            value: color.title,
                        };
                    })}
                />
            </div>
        </div>

        <div className="product-editor__section">
            <div className="product-editor__section-head">
                <div className="product-editor__label">Сезон:</div>
            </div>
            <div className="product-editor__section-body">
                <Select
                    allowClear
                    style={{width: "100%"}}
                    placeholder="Выберите сезон"
                    onChange={props.onSeasonSelectedChange}
                    defaultValue={props.product?.season || undefined}
                    options={getSeasonsOptions()}
                />
            </div>
        </div>

        <div className="product-editor__section">
            <div className="product-editor__section-head">
                <div className="product-editor__label">Состав:</div>
            </div>
            <div className="product-editor__section-body">
                <Input
                    value={props.product?.composition || ""}
                    onChange={props.onChangeComposition}
                    name="composition"
                    placeholder="Введите состав товара"
                />
            </div>
        </div>

        <div className="product-editor__section">
            <div className="product-editor__section-head">
                <div className="product-editor__label">Доп. теги:</div>
            </div>
            <div className="product-editor__section-body">
                <Select
                    mode="multiple"
                    allowClear
                    style={{width: "100%"}}
                    placeholder="Введите тег"
                    defaultValue={[]}
                    onChange={props.onAdditionalTagChange}
                    options={props.additionalTagList.map((tag) => {
                        return {
                            label: tag.title,
                            value: tag.title,
                        };
                    })}
                />
            </div>
        </div>

        <div className="product-editor__section">
            <div className="product-editor__section-head">
                <div className="product-editor__label">Фильтры каталога:</div>
            </div>

            <div className="product-editor__tags">
                <Cascader
                    items={props.filtersList}
                    selectedFilters={Object.keys(props.selectedFilters)}
                    onChecked={props.onChangeSelectedFilters}
                />
            </div>
        </div>

        <div className="product-editor__section">
            <div className="product-editor__section-head">
                <div className="product-editor__label required">Каналы:</div>
            </div>

            <div className="product-editor__section-body">
                {props.channelsList &&
                    props.channelsList.map((channel) => (
                        <TagCheckable
                            key={channel.id}
                            checked={props.selectedChannels.hasOwnProperty(
                                channel.id
                            )}
                            onChange={(isChecked) =>
                                props.onClickChannel(channel, isChecked)
                            }
                        >
                            {channel.title}
                        </TagCheckable>
                    ))}
            </div>
        </div>

        <div className="product-editor__section">
            <div className="product-editor__section-head">
                <div className="product-editor__label">Лучшее:</div>
            </div>

            <div className="product-editor__section-body">
                <Checkbox
                    onChange={props.onBestChange}
                    checked={props.product?.isBest}
                />
            </div>
        </div>

        <div className="product-editor__section">
            <div className="product-editor__section-head">
                <div className="product-editor__label">Дата:</div>
            </div>

            <div className="product-editor__section-body">
                {
                    <DatePicker
                        defaultValue={moment(props.product?.dateAdded) || undefined}
                        onChange={props.onDateAddChange}
                        locale={locale}
                    />
                }
            </div>
        </div>

        <div className="product-editor__section">
            <div className="product-editor__section-head">
                <div className="product-editor__label">Связь с товарами:</div>
            </div>

            <div className="product-editor__section-body">
                <div className="categories-list__body">
                    <TransferItems
                        items={props.productsForTransfer}
                        transferItemsKeys={
                            props.product?.followers?.map((child) =>
                                String(child.id)
                            ) || []
                        }
                        onTransferItemsChange={props.onProductFollowersChange}
                    />
                </div>
            </div>
        </div>

        <div className="product-editor__actions">
            <Button
                className="product-editor__submit"
                type="primary"
                onClick={props.onFormSubmit}
            >
                Сохранить
            </Button>

            <Popconfirm
                title={
                    <>
                        <strong>Вы уверены, что хотите выйти?</strong>
                        <br />
                        <span>
                            Изменения, которые вы сделали, не будут сохранены!
                        </span>
                    </>
                }
                cancelText="Вернуться к редактированию!"
                okText="Да, я хочу выйти!"
                onConfirm={props.onFormCancel}
            >
                <Button
                    className="product-editor__cancel-button"
                    type="default"
                >
                    Отмена
                </Button>
            </Popconfirm>
        </div>
    </div>
);

export default ProductEditorTemplate;
