import {CascaderTree} from './Cascader-types';


export const treeChildrenFind = (dataTree: CascaderTree[], id: number): CascaderTree[] => {
    let result: CascaderTree[] = [];

    for (let index = 0; index < dataTree.length; index++) {
        const node = dataTree[index];
        const checkId = Number(node.key) === id;

        if (checkId && node.children?.length) {
            result = [...node.children];

            break;
        } else if (checkId) {
            result = [];

            break;
        } else if (node.children?.length) {
            result = [...treeChildrenFind(node.children, id)];

            if (result.length) {
                break;
            }
        }
    }

    return result;
}

export const treeParentFind = (dataTree: CascaderTree[], id: number): number[] => {
    let result: number[] = [];

    for (let index = 0; index < dataTree.length; index++) {
        const node = dataTree[index];
        const nodeKey = Number(node.key);
        const checkId = nodeKey === id;

        if (!checkId && node.children?.length) {
            result = [...result, ...treeParentFind(node.children, id)];

            if (result.length) {
                result.unshift(nodeKey);

                break;
            }
        } else if (checkId && node.children?.length) {
            result.push(id);

            break;
        }
    }

    return result;
}

export const treeChildrenReplace = (currentDataTree: CascaderTree[], newDataTree: CascaderTree[]): CascaderTree[] => {
    let result: CascaderTree[] = [];
    let tempDataTree = [...currentDataTree]

    if (newDataTree.length) {
        const data = newDataTree[0];
        const dataKey = Number(data.key);

        for (let index = 0; index < tempDataTree.length; index++) {
            let node = tempDataTree[index];
            const checkId = Number(node.key) === dataKey;

            if (!checkId && node.children?.length) {

                result = treeChildrenReplace(node.children, newDataTree);

                if (result.length) {
                    tempDataTree[index].children = [...result];
                    result = [...tempDataTree];

                    break;
                }
            } else if (checkId) {
                result = newDataTree;

                break;
            }
        }
    }

    return result;
}

export const treeSelectedFind = (dataTree: CascaderTree[], id: number): number[] => {
    let result: number[] = [];

    for (let index = 0; index < dataTree.length; index++) {
        const node = dataTree[index];
        const nodeKey = Number(node.key);
        const checkId = nodeKey === id;

        if (!checkId && node.children?.length) {
            result = [...result, ...treeSelectedFind(node.children, id)];

            if (result.length) {
                result.unshift(nodeKey);

                break;
            }
        } else if (checkId) {
            result.push(id);

            break;
        }
    }

    return result;
}
