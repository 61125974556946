import ServiceTypecheck from "../services/ServiceTypecheck";

export enum Season {
    winter = 'winter',
    spring = 'spring',
    summer = 'summer',
    autumn = 'autumn',
}

export const isSeason = (content: unknown): content is Season => {
    return ServiceTypecheck.isEnumValue(content, Season);
}

export interface HandlerSeasonChange {
    (value: Season | undefined): void
}