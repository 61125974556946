import React from "react";
import {TemplateProps} from "./ProductsArchiveLooksShapes";
import LayoutPage from "../../components/LayoutPage/LayoutPage";
import LookCardArchive from "../../components/LookCardArchive/LookCardArchive";
import {Pagination} from "antd";

const ProductsArchiveLooksTemplate = (props: TemplateProps) => (
    <LayoutPage className="looks-list" title="Образы">

        {
            props.products &&
            props.products.length > 0 &&
            <>
                <div className="looks-list__body products-list__body">
                    {
                        props.products.map((product, index) => (
                            <div className="products-list__item" key={product.id} data-position={index + 1}>
                                <div className="look-card-archive">
                                    <LookCardArchive
                                        product={product}
                                    />
                                </div>
                            </div>
                        ))
                    }
                </div>

                <Pagination
                    className="products-list__pagination"
                    current={props.page}
                    pageSize={props.pageSize}
                    onChange={props.onPageChange}
                    total={props.productsTotal}
                    showSizeChanger={false}
                />
            </>
        }

    </LayoutPage>
);

export default ProductsArchiveLooksTemplate;
