import {Gender} from "../interfaces/Gender";

export const getGendersOptions = () => {
    return Object.values(Gender).map((gender) => ({
        label: getGenderLabel(gender),
        value: gender,
    }));
}

export const getGenderLabel = (season: Gender): string | null => {
    const labels = {
        [Gender.male]: 'мужской',
        [Gender.female]: 'женский',
    };

    return labels[season] || null;
}
