import React from 'react';
import {RouteProps} from "react-router";
import {Route, Redirect} from "..";
import ROUTES from "../../../constants/Routes";
import AuthService from "../../../services/AuthService";

const RoutePrivate: React.FC<RouteProps> = ({children, component, ...props}) => {
    return AuthService.hasPrivateAccess()
        ? <Route {...props} component={component}/>
        : <Redirect to={ROUTES.login}/>
};

export default RoutePrivate;