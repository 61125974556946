import React, {FC, memo, useMemo} from 'react';
import {Popconfirm} from "antd";
import {DeleteOutlined, EditOutlined, ExportOutlined, ImportOutlined, CaretRightOutlined} from "@ant-design/icons";

import {
    Wrap,
    Head,
    Body,
    Foot,
    Title,
    ActionArchive,
    ActionEdit,
    ActionRemove,
    ChildArrow
} from './CascaderNode-styles';
import {CascaderItemProps} from './CascaderNode-types';
import {stopPropagation} from "../../../helpers/ActionUtils";
import {useCascaderItemActionHandler} from "./CascaderNode-hooks";


const CascaderNode: FC<CascaderItemProps> = (props) => {
    const {item, cascadeIds, isEdit, isCheckbox, onClick, onEdit, onRemove, onArchive} = props;

    const handleClick = useCascaderItemActionHandler(item.key, onClick);
    const handleEdit = useCascaderItemActionHandler(item.key, onEdit);
    const handleRemove = useCascaderItemActionHandler(item.key, onRemove);
    const handleArchive = useCascaderItemActionHandler(item.key, onArchive);

    const isSelected = useMemo(() => cascadeIds.includes(item.key), [cascadeIds, item.key]);
    const hasChildren = !!item.children?.length;

    return (
        <Wrap
            isSelected={isSelected}
            isArchived={!item.isActive}
            onClick={handleClick}
            data-is-selected={isSelected}
            isEdit={isEdit}
            isCheckbox={isCheckbox}
            >

            <Head>
                {
                    onArchive &&
                    <ActionArchive
                        type="link"
                        onClick={handleArchive}
                        data-item-id={item.key}
                        data-is-archive={item.isActive}>

                        {
                            item.isActive
                                ? <ImportOutlined style={{transform: "rotate(-90deg)"}} title="Архивировать" />
                                : <ExportOutlined style={{transform: "rotate(-90deg)"}} title="Достать из архива" />
                        }
                    </ActionArchive>
                }
            </Head>

            <Body>
                <Title data-key={item.key} data-is-children-presence={true}>
                    {item.title}
                </Title>
            </Body>

            <Foot>
                {
                    onEdit &&
                    <ActionEdit type="link" onClick={handleEdit}>
                        <EditOutlined />
                    </ActionEdit>
                }

                {
                    onRemove &&
                    <Popconfirm title={`Точно хотите удалить "${item.title}"?`}
                        okText="Да, удаляй"
                        cancelText="Отмена"
                        onConfirm={handleRemove}>
                        <ActionRemove type="link" onClick={stopPropagation}>
                            <DeleteOutlined />
                        </ActionRemove>
                    </Popconfirm>
                }

                <ChildArrow>
                    {
                        hasChildren &&
                        <CaretRightOutlined />
                    }
                </ChildArrow>
            </Foot>
        </Wrap>
    );
}

export default memo(CascaderNode);
