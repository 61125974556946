import React from 'react';
import {Props} from './AppShapes';
import {Router, Switch, RoutePrivate, Redirect, Route, RoutePublic} from "./components/Routing";
import AppPrivate from "./modules/AppPrivate/AppPrivate";
import AppPublic from "./modules/AppPublic/AppPublic";
import Login from "./modules/Login/Login";
import ROUTES from "./constants/Routes";


const App: React.FC<Props> = () => (
    <Router>
        <Switch>
            <Route path={ROUTES.login} component={Login} exact/>
            <RoutePrivate path={ROUTES.privateApp} component={AppPrivate}/>
            <RoutePublic path={ROUTES.publicApp} component={AppPublic}/>
            <Redirect from="/" to={ROUTES.publicApp}/>
        </Switch>
    </Router>
);

export default App;
