import {useCallback, MouseEvent} from "react";
import {CascaderTree} from '../Cascader-types';
import {CascaderItemActionHandler} from "./CascaderNode-types";


export const useCascaderItemActionHandler = (key: CascaderTree['key'], action?: CascaderItemActionHandler) => {
    return useCallback((e?: MouseEvent<HTMLElement>) => {
        e?.stopPropagation();

        action?.(key, e);
    }, [key, action]);
};
