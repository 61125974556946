import React, {useState, useEffect} from "react";
import {ChannelViewProps} from "./ChannelViewShapes";
import Channel from "../../interfaces/Channel";
import ChannelModel from "../../models/ChannelModel";
import Filter from "../../interfaces/Filter";
import FilterModel from "../../models/FilterModel";
import {Avatar, Button, message} from "antd";
import TagCheckable from "../../components/TagCheckable/TagCheckable";

import "./ChannelViewStyles.scss";

interface FiltersSelected {
    [key: string]: Filter;
}

const ChannelView: React.FC<ChannelViewProps> = (props) => {
    const [channel, channelFilters, setChannelFilters] = useChannel(props.cid, null);
    const [filters] = useFilters([]);

    const handleFilterSelect = (filter: Filter): void => {
        if (channelFilters.hasOwnProperty(filter.id)) {
            const selected = {...channelFilters};
            delete selected[filter.id];
            setChannelFilters(selected);
        } else {
            const selected = {...channelFilters};
            selected[filter.id] = filter;
            setChannelFilters(selected);
        }
    };

    const handleSave = (): void => {
        if (channel) {
            const data = {
                ...channel,
                filters: Object.values(channelFilters),
            };

            ChannelModel.update(data)
                .then(channel => {
                    message.success('Канал успешно обновлен');
                    return channel;
                })
                .then(channel => props.afterSaved(channel))
                .catch(err => {
                    message.error('При сохранении канала произошла ошибка.');
                    console.error(err);
                });
        }
    }

    return (
        <div className="channel-view">
            {
                channel &&
                <div className="channel-view__head">
                    <div className="channel-view__image">
                        <Avatar src={channel.image} alt="" size={60}/>
                    </div>
                    <h3 className="channel-view__title">{channel.title}</h3>
                    <div className="channel-view__description">{channel.description}</div>
                </div>
            }

            <div className="channel-view__body">
                {
                    channel &&
                    filters &&
                    filters.map((filter) => (
                        <TagCheckable
                            key={filter.id}
                            checked={channelFilters.hasOwnProperty(filter.id)}
                            onChange={() => handleFilterSelect(filter)}>
                            {filter.title}
                        </TagCheckable>
                    ))
                }
            </div>

            {
                channel &&
                <div className="channel-view__actions">
                    <Button type="primary" onClick={handleSave}>Сохранить</Button>
                </div>
            }
        </div>
    );
}

export default ChannelView;


function useChannel(cid: string, defaultValue: Channel | null = null): [ Channel | null , FiltersSelected, (items: any) => void] {
    const [channel, setChannel] = useState<Channel | null>(defaultValue);
    const [channelFilters, setChannelFilters] = useState<FiltersSelected>({});

    useEffect(() => {
        const channelId = Number(cid);
        ChannelModel.getById(channelId)
            .then(result => {
                setChannel(result);
                setChannelFilters(result.filters.reduce<FiltersSelected>((selected, filter) => {
                    selected[filter.id] = filter;
                    return selected;
                }, {}));
            })
            .catch(err => {
                message.error('Во время загрузки канала произошла ошибка.');
                console.error(err);
            });
    }, [cid]);

    return [ channel, channelFilters, setChannelFilters ];
}


function useFilters(defaultValue: Filter[] = []): [ Filter[] ] {
    const [filters, setFilters] = useState<Filter[]>(defaultValue);

    useEffect(() => {
        FilterModel.getAllActive()
            .then(filters => setFilters(filters))
            .catch(err => {
                message.error('Во время загрузки списка фильтров произошла ошибка.');
                console.error(err);
            })
    }, []);

    return [ filters ];
}