import React from "react";
import {ProductCardProps} from "./ProductDraftFailedCardShapes";
import {Popconfirm} from "antd";
import {CloseSquareOutlined} from "@ant-design/icons";

import "./ProductDraftFailedCardStyles.scss";

const ProductDraftFailedCard: React.FC<ProductCardProps> = ({item, ...props}) => {
    const stopPropagation = (e: any) => {
        e.stopPropagation();
    }

    const handleRemove = (e: any) => {
        e.stopPropagation();
        props.onRemove(item);
    };

    return (

        <div className="product-draft-failed-card" data-source={item.source}>
            <div className="product-draft-failed-card__body">

                <div className="product-draft-failed-card__images">
                    <div className="product-draft-failed-card__image">
                        При парсинге товара возникла ошибка
                    </div>
                    <div className="product-draft-failed-card__actions">
                        <Popconfirm title="Вы точно хотите удалить?" onConfirm={handleRemove} onCancel={stopPropagation}>
                            <button className="product-draft-failed-card__remove" title="Удалить" onClick={stopPropagation}>
                                <CloseSquareOutlined/>
                            </button>
                        </Popconfirm>
                    </div>
                </div>

                <div className="product-draft-failed-card__info">
                    <div className="product-draft-failed-card__url">{item.sourceUrl}</div>
                    <div className="product-draft-failed-card__store">#{item.source}</div>
                </div>

            </div>
        </div>
    )
}

export default ProductDraftFailedCard;