import ServiceTypecheck from "../../services/ServiceTypecheck";

export enum OnlineStoreName {
    MassimoDutti = 'massimodutti',
    Mango = 'mango',
    Lime = 'lime',
    Zara = 'zara',
    HM = 'hm',
    Vagabond = 'vagabond',
    Ekonika = 'ekonika',
    TwelweStoreez = '12storeez',
    Gate31 = 'gate31',
    CosStores = 'cosstores',
    AllWeNeed = 'allweneed',
    IamStudio = 'iamstudio',
    AndOtherStories = 'andotherstories',
    Agreeg = 'agreeg',
    PoisonDrop = 'poisondrop',
    Uniqlo = 'uniqlo',
    Uterque = 'uterque',
    Zarina = 'zarina',
    Bershka = 'bershka',
    PullAndBear = 'pullandbear',
    Reserved = 'reserved',
    Stradivarius = 'stradivarius',
    ArnyPraht = 'arnypraht',
    TwoMoodStore = '2moodstore',
    Farfetch = 'farfetch',
    EmkaShop = 'emkashop',
    Incity = 'incity',
    Studio29 = 'studio29',
    Charuel = 'charuel',
    VivaLaVika = 'vivalavika',
    AshRussia = 'ashrussia',
    Rendezvous = 'rendezvous',
    Divnome = 'divnome',
    Loverepublic = 'loverepublic',
    Lichi = 'lichi',
    Stores1811 = 'stores1811',
    Arket = 'arket',
}

export const isOnlineStoreName = (content: unknown): content is OnlineStoreName => {
    return ServiceTypecheck.isEnumValue(content, OnlineStoreName);
}
