import React, {useState} from "react";
import {Button, Form, Input, message} from "antd";
import {Props} from "./AuthSimpleShapes";
import {AuthInputChange} from "../../interfaces/AuthInputChange";
import AuthService from "../../../../services/AuthService";
import {LoginSimpleVerify} from "../../../../interfaces/LoginSimpleVerify";
import {LoginSimple} from "../../../../interfaces/LoginSimple";
// import CountdownTimer from "../../../CountdownTimer/CountdownTimer";

import "./AuthSimpleStyles.scss";

const AuthSimple: React.FC<Props> = (props) => {
    const [isRequested, setIsRequested] = useState<boolean>(false);
    // const [isTimeout, setIsTimeout] = useState<boolean>(false);
    const [login, setLogin] = useState<string>("");
    const [passcode, setPasscode] = useState<string>("");

    const loginChange: AuthInputChange = (e) => {
        const value = e.currentTarget.value;
        setLogin(value);
    };

    const passcodeChange: AuthInputChange = (e) => {
        const value = e.currentTarget.value.replace(/[^0-9.]/g, '');
        if (value.length <= 6) {
            setPasscode(value);
        }
    };

    const stepBack = () => {
        setIsRequested(false);
    };

    const requestCode = async () => {
        try {
            const data: LoginSimple = {login};
            await AuthService.loginSimple(data);
            // setIsTimeout(false);
            setIsRequested(true);
        } catch (err) {
            errorHandler(err);
        }
    };

    const requestCodeAgain = async () => {
        try {
            await requestCode();
            message.success('Код отправлен повторно');
        } catch (err) {
            message.error('Ошибка при повторном запросе');
        }
    };

    // const afterTimerFinished = () => {
    //     setIsTimeout(true);
    // };

    const submit = async () => {
        try {
            const data: LoginSimpleVerify = {login, passcode};
            const user = await AuthService.loginSimpleVerify(data);

            if (user && props.afterSubmit) {
                props.afterSubmit(user);
            }
        } catch (err) {
            errorHandler(err);
        }
    };

    const errorHandler = (err: any) => {
        if (!AuthService.isCancel(err)) {
            switch (err.response.status) {
                case 400: message.error('Неправильный логин или код подтверждения'); break;
                case 429: message.error('Вы превысили количество попыток. Запросите код повторно!'); break;
                case 500: message.error('Ошибка при авторизации'); break;
            }
        }
    };

    return isRequested ? (
        <Form className="auth-simple">
            <Form.Item className="auth-simple__login" name="login">
                <label className="auth-simple__input">
                    <span className="auth-simple__label">
                        Код подтверждения:
                    </span>
                    <Input className="auth-simple__field"
                           autoComplete="off"
                           placeholder="______"
                           value={passcode}
                           onChange={passcodeChange}/>

                        {/*<span className="auth-simple__timer" data-timeout={isTimeout}>*/}
                        {/*    {*/}
                        {/*        isTimeout*/}
                        {/*            ? <span>00 : 00</span>*/}
                        {/*            : <CountdownTimer minutes={10} seconds={0} afterFinished={afterTimerFinished} />*/}
                        {/*    }*/}
                        {/*</span>*/}

                </label>
            </Form.Item>
            <Form.Item className="auth-simple__message">
                <label>
                    <span>
                        Если код не пришел, вы можете <span className="auth-simple__resend" onClick={requestCodeAgain}>запросить код повторно</span>
                        {/*<br/> Код действителен в течение 10 минут.*/}
                    </span>
                </label>
            </Form.Item>
            <div className="auth-simple__actions">
                <Button type="link" onClick={stepBack}>{"< Назад"}</Button>
                <Button type="primary" htmlType="submit" disabled={!passcode} onClick={submit}>Войти</Button>
            </div>
        </Form>
    ) : (
        <Form style={{width: 450}}>
            <Form.Item name="login" style={{marginBottom: 10}}>
                <label>
                    <span style={{opacity: 0.6}}>Ваш e-mail:</span>
                    <Input value={login} onChange={loginChange} autoComplete="off" style={{marginTop: 5}}/>
                </label>
            </Form.Item>
            <Form.Item className="auth-simple__message">
                <label>
                    <span>
                        Вам выслан на почту будет выслан одноразовый пароль для входа, используйте его, чтобы
                        авторизоваться на сайте
                    </span>
                </label>
            </Form.Item>
            <div className="auth-simple__actions">
                <div>{props.renderAction && props.renderAction()}</div>
                <Button type="primary" htmlType="submit" disabled={!login} onClick={requestCode}>
                    Получить код
                </Button>
            </div>
        </Form>
    );

}

export default AuthSimple;