import {Canceler} from "axios";
import ApiService from "./ApiService";
import ENDPOINTS from "../constants/Endpoints";
import {LoginTelegram} from "../interfaces/LoginTelegram";
import User from "../interfaces/User";
import TokenService from "./TokenService";
import {LoginDefault} from "../interfaces/LoginDefault";
import {LoginSimple} from "../interfaces/LoginSimple";
import {LoginSimpleVerify} from "../interfaces/LoginSimpleVerify";

class AuthService extends ApiService {


    private cancel: null | Canceler = null;
    protected storageAuthData: string = "ORLUSER";

    public loginDefault = async (data: LoginDefault): Promise<User | null> => {
        const user = await this.post<User | null>(ENDPOINTS.authDefault, data);

        if (user) {
            this.setAuthData(user);
        }

        return user;
    };

    public loginTelegram = async (data: LoginTelegram): Promise<User | null> => {
        const user = await this.post<User | null>(ENDPOINTS.authTelegram, data) || null;

        if (user) {
            this.setAuthData(user);
        }

        return user;
    };

    public loginSimple = async (data: LoginSimple): Promise<any> => {
        if (this.cancel !== null) {
            this.cancel();
            this.cancel = null;
        }

        const cancelToken = new this.cancelToken(cancel => this.cancel = cancel);
        return this.post(ENDPOINTS.authSimple, data, {cancelToken});
    };

    public loginSimpleVerify = async (data: LoginSimpleVerify): Promise<User | null> => {
        const user = await this.post<User | null>(ENDPOINTS.authSimpleVerify, data) || null;

        if (user) {
            this.setAuthData(user);
        }

        return user;
    };


    public logout = async () => {
        TokenService.removeToken();
        this.removeAuthData();
    }


    public hasPublicAccess = (): boolean => {
        const token = TokenService.getToken();
        const user = this.getAuthData();
        return !!token && !!user && (user.role === "USER" || user.role === "ADMIN");
    };

    public hasPrivateAccess = (): boolean => {
        const token = TokenService.getToken();
        const user = this.getAuthData();
        return !!token && !!user && user.role === "ADMIN";
    };


    public setAuthData = (user: User) => {
        localStorage.setItem(this.storageAuthData, JSON.stringify(user));
    }

    public getAuthData = (): User | null => {
        const user = localStorage.getItem(this.storageAuthData);
        return user ? JSON.parse(user) : null;
    };

    public removeAuthData = () => {
        localStorage.removeItem(this.storageAuthData);
    };


}

export default new AuthService();