const ERROR_CODES = {
    SERVER_ERROR: 500 as number,
    OK: 200 as number,
    BAD_REQUEST: 400 as number,
    AUTH_FAILED: 401 as number,
    FORBIDDEN: 403 as number,
    NOT_FOUND: 404 as number,
    GATEWAY_TIMEOUT: 504 as number,
}

export default ERROR_CODES;
