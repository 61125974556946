import {Currency} from "./../../interfaces/Currency";
import {ProductSaved} from "../../interfaces/Product";


export const sortProductById = (productA: ProductSaved, productB: ProductSaved) => {
    return productB.id - productA.id;
}

export const sortProductPriceAscending = (productA: ProductSaved, productB: ProductSaved, currency: Currency) => {
    const {priceA, priceB, priceDiscountA, priceDiscountB} = setPrice(productA, productB, currency);

    if (priceDiscountA && priceDiscountB) {
        return priceDiscountA - priceDiscountB;
    } else if (priceDiscountA) {
        return priceDiscountA - priceB;
    } else if (priceDiscountB) {
        return priceA - priceDiscountB;
    } else {
        return priceA - priceB;
    }
}

export const sortProductPriceDescending = (productA: ProductSaved, productB: ProductSaved, currency: Currency) => {
    const {priceA, priceB, priceDiscountA, priceDiscountB} = setPrice(productA, productB, currency);

    if (priceDiscountA && priceDiscountB) {
        return priceDiscountB - priceDiscountA;
    } else if (priceDiscountA) {
        return priceB - priceDiscountA;
    } else if (priceDiscountB) {
        return priceDiscountB - priceA;
    } else {
        return priceB - priceA;
    }
}

const setPrice = (productA: ProductSaved, productB: ProductSaved, currency: Currency) => {
    const currencyRateKeyA = productA.currency;
    let priceA = Number(productA.price);
    let priceDiscountA = Number(productA.priceDiscount);

    if (currencyRateKeyA && currencyRateKeyA !== currency.base) {
        const {price, priceDiscount} = convertCurrency(currency, currencyRateKeyA, priceA, priceDiscountA);

        priceA = price;
        priceDiscountA = priceDiscount;
    }

    const currencyRateKeyB = productB.currency;
    let priceB = Number(productB.price);
    let priceDiscountB = Number(productB.priceDiscount);

    if (currencyRateKeyB && currencyRateKeyB !== currency.base) {
        const {price, priceDiscount} = convertCurrency(currency, currencyRateKeyB, priceB, priceDiscountB);

        priceB = price;
        priceDiscountB = priceDiscount;
    }

    return {priceA, priceB, priceDiscountA, priceDiscountB};
}

const convertCurrency = (currency: Currency, currencyRateKey: string, price: number, priceDiscount: number) => {
    const localRateKey = Object.keys(currency.rates).find(rateKey => rateKey === currencyRateKey);

    if (localRateKey) {
        const rateValue = currency.rates[localRateKey];

        price = price && price / rateValue;
        priceDiscount = priceDiscount && priceDiscount / rateValue;;
    }

    return {price, priceDiscount};
}
