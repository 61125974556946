import React from "react";
import {Button} from "antd";
import {TemplateProps} from "./FiltersListShapes";
import LayoutPage from "../../components/LayoutPage/LayoutPage";
import "./FiltersListStyles.scss";
import {PlusCircleOutlined} from "@ant-design/icons";
import DrawerPanel from "../../components/DrawerPanel/DrawerPanel";
import FilterEditor from '../../components/FilterEditor/FilterEditor';
import Cascader from '../../components/Cascader/Cascader';

const FiltersListTemplate = (props: TemplateProps) => (
    <LayoutPage className="filters-list" title="Фильтры" extra={
        <Button icon={<PlusCircleOutlined />} type="primary" onClick={props.onEditorToggle}>Новый фильтр</Button>
    }>

        <div className="filters-list__items">
            <Cascader
                items={props.items}
                onEdit={props.onEditorToggle}
                onRemove={props.onItemRemove}
                onArchive={props.onItemActiveToggle}
                onUpdatePosition={props.onUpdatePosition}
                isEdit />
        </div>

        <DrawerPanel
            visible={props.editorVisible}
            title={props.editorItemId ? 'Редактирование фильтра' : 'Создание фильтра'}
            onClose={props.onEditorClose}
            destroyOnClose>

            <FilterEditor
                itemId={props.editorItemId || undefined}
                items={props.items}
                onSubmit={props.onEditorSubmit}
                onCancel={props.onEditorToggle} />

        </DrawerPanel>


    </LayoutPage>
);

export default FiltersListTemplate;
