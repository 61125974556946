import React from "react";
import {DropdownProps} from "./TopbarUserShapes";
import {Menu} from "antd";
import {LogoutOutlined/*, SettingOutlined, UserOutlined*/} from "@ant-design/icons";
// import {Link} from "../Routing";
import "./TopbarUserStyles.scss";

const TopbarUserDropdown: React.FC<DropdownProps> = (props) => (
    <Menu className="topbar-user__dropdown">
        {/*<Menu.Item key="1">*/}
        {/*    <Link to={"profile"}>*/}
        {/*        <UserOutlined/> Профиль пользователя*/}
        {/*    </Link>*/}
        {/*</Menu.Item>*/}
        {/*<Menu.Item key="2">*/}
        {/*    <Link to="/app/settings">*/}
        {/*        <SettingOutlined/> Настройки*/}
        {/*    </Link>*/}
        {/*</Menu.Item>*/}
        {/*<Menu.Divider/>*/}
        {
            props.onLogout &&
            <Menu.Item key="3" icon={<LogoutOutlined/>} onClick={props.onLogout}>
                Выйти
            </Menu.Item>
        }
    </Menu>
);

export default TopbarUserDropdown;