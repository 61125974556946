import "./FilterEditor-styles.scss";

import {Input, message, Switch, Tree} from 'antd';
import {TreeProps} from 'antd/lib/tree';
import React, {ChangeEvent, FC, useCallback, useEffect, useMemo, useState} from 'react'
import {convertFiltersToDataNodes, searchParents} from '../../helpers/FilterTreeUtils';
import Channel from '../../interfaces/Channel';
import Filter, {FilterEmpty} from '../../interfaces/Filter';
import {FilterEditorProps} from './FilterEditor-types';
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import DrawerPanelFooter from "../DrawerPanel/DrawerPanelFooter";
import {objectKeyToggle} from "../../helpers/ObjectHelpers";
import ChannelItem from "./ChannelItem/ChannelItem";
import {useChannelsLoad, useFilterCreate, useFilterLoad, useFilterUpdate} from "./FilterEditor-hooks";

const arrayWithZero = [0];

const arrayTwo = ["1", "2"];
const arrayFive = ["3", "4", "5", "6", "7"];

const FilterEditor: FC<FilterEditorProps> = (props) => {
    const {itemId, items, onSubmit, onCancel} = props;

    const [item, setItem] = useState<Filter | FilterEmpty | null>(null);
    const [channels, setChannels] = useState<Channel[]>([]);
    const [channelsSelected, setChannelsSelected] = useState<{[key: number]: Channel}>({});
    const [lookBlocksSelected, setLookBlocksSelected] = useState<{[key: string]: ''}>({});
    const [selectedKeys, setSelectedKeys] = useState<number[]>([]);
    const [isItemFilterVisible, setIsItemFilterVisible] = useState(true);

    const dataTree = useMemo(() => items ? convertFiltersToDataNodes(items, itemId) : [], [items, itemId]);

    const loadFilterData = useFilterLoad(itemId);
    const loadChannelsData = useChannelsLoad();

    const filterCreate = useFilterCreate();
    const filterUpdate = useFilterUpdate();

    const isFilter = (value: Filter | FilterEmpty): value is Filter => {
        return value.id != null;
    }

    const handleFilterSubmit = useCallback(async () => {
        if (item && items) {
            const itemLocal = {...item};

            const relatives = items.filter(localItem => localItem.parentId === itemLocal.parentId);

            if (relatives.length) {
                itemLocal.position = relatives[relatives.length - 1].position + 1;
            }

            try {
                const itemCurrent: Filter = isFilter(itemLocal)
                    ? await filterUpdate(itemLocal, channelsSelected, lookBlocksSelected)
                    : await filterCreate(itemLocal, channelsSelected, lookBlocksSelected);

                onSubmit(itemCurrent);
            } catch (err) {
                message.error('При сохранении фильтра возникла ошибка');
            }
        }
    }, [item, items, filterUpdate, channelsSelected, lookBlocksSelected, filterCreate, onSubmit]);

    const handleFilterCancel = useCallback((e: any) => {
        onCancel(e);
    }, [onCancel]);

    const handleFilterTitleChange = useCallback((e: any) => {
        if (item) {
            const title: string = e.currentTarget.value;

            setItem({...item, title});
        }
    }, [item]);

    const handleFilterStatusChange = useCallback((isActive: boolean) => {
        if (item) {
            setItem({...item, isActive});
        }
    }, [item]);

    const handleChannelClick = useCallback((channel: Channel) => {
        const selected = objectKeyToggle<{[key: number]: Channel}>(channelsSelected, channel.id, channel);
        setChannelsSelected(selected);
    }, [channelsSelected]);

    const handleLookBlockClick = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const selected = objectKeyToggle(lookBlocksSelected, e.currentTarget.value, '');
        setLookBlocksSelected(selected);
    }, [lookBlocksSelected]);

    const handleFilterItemCollapse = useCallback(() => {
        setIsItemFilterVisible(!isItemFilterVisible);
    }, [isItemFilterVisible]);

    const handleFilterCheck: TreeProps['onCheck'] = useCallback((checkedKeys, info) => {
        if (item) {
            const key = Number(info.node.key);
            const parentId = key || null;
            const parentsIds = items && searchParents(items, parentId);

            setItem({...item, parentId})
            setSelectedKeys(parentsIds?.length ? parentsIds : arrayWithZero);
        }
    }, [items, item]);

    useEffect(() => {
        loadChannelsData()
            .then(response => setChannels(response || []))
            .catch(err => console.error(err));

    }, [loadChannelsData]);

    useEffect(() => {
        loadFilterData()
            .then(response => {
                if (response) {
                    setItem(response.item);
                    setChannelsSelected(response.selectedChannels);
                    setLookBlocksSelected(response.selectedLookBlocks)
                }
            })
            .catch(err => console.error(err));
    }, [loadFilterData])

    useEffect(() => {
        if (items) {
            if (itemId && items) {
                const parentsIds = searchParents(items, itemId);
                const result = parentsIds.length > 1 ? parentsIds : arrayWithZero;

                setSelectedKeys(result);
            } else {
                setSelectedKeys(arrayWithZero);
            }
        }
    }, [itemId, items]);

    return (
        <div className="filter-editor">
            <div className="filter-editor__body">

                <label className="filter-editor__row">
                    <span className="filter-editor__label">Название фильтра</span>
                    <Input className="filter-editor__input" value={item?.title} onChange={handleFilterTitleChange} />
                </label>

                <label className="filter-editor__row">
                    <div className="filter-editor__items-wrap">
                        <span className="filter-editor__label">Родительская категория</span>
                        <span className="filter-editor__visible-arrow" onClick={handleFilterItemCollapse}
                            data-is-collapsed={isItemFilterVisible}>
                            <svg width="13" height="9" viewBox="0 0 13 9" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0.15298 8.6574C0.252994 8.77311 0.387253 8.83789 0.527059 8.83789C0.666865 8.83789 0.801125 8.77311 0.901138 8.6574L6.5 2.19294L12.0977 8.6574C12.1978 8.77311 12.332 8.83789 12.4718 8.83789C12.6116 8.83789 12.7459 8.77311 12.8459 8.6574C12.8947 8.60116 12.9334 8.53392 12.9599 8.45964C12.9864 8.38536 13 8.30556 13 8.22494C13 8.14432 12.9864 8.06451 12.9599 7.99023C12.9334 7.91596 12.8947 7.84871 12.8459 7.79247L6.89088 0.918093C6.7863 0.797356 6.64604 0.729782 6.5 0.729782C6.35396 0.729782 6.2137 0.797356 6.10912 0.918093L0.1541 7.79247C0.105332 7.84871 0.0665702 7.91596 0.0401031 7.99023C0.013636 8.06451 0 8.14432 0 8.22494C0 8.30556 0.013636 8.38536 0.0401031 8.45964C0.0665702 8.53392 0.105332 8.60116 0.1541 8.6574H0.15298Z"
                                    fill="black" />
                                <path
                                    d="M0.15298 8.6574C0.252994 8.77311 0.387253 8.83789 0.527059 8.83789C0.666865 8.83789 0.801125 8.77311 0.901138 8.6574L6.5 2.19294L12.0977 8.6574C12.1978 8.77311 12.332 8.83789 12.4718 8.83789C12.6116 8.83789 12.7459 8.77311 12.8459 8.6574C12.8947 8.60116 12.9334 8.53392 12.9599 8.45964C12.9864 8.38536 13 8.30556 13 8.22494C13 8.14432 12.9864 8.06451 12.9599 7.99023C12.9334 7.91596 12.8947 7.84871 12.8459 7.79247L6.89088 0.918093C6.7863 0.797356 6.64604 0.729782 6.5 0.729782C6.35396 0.729782 6.2137 0.797356 6.10912 0.918093L0.1541 7.79247C0.105332 7.84871 0.0665702 7.91596 0.0401031 7.99023C0.013636 8.06451 0 8.14432 0 8.22494C0 8.30556 0.013636 8.38536 0.0401031 8.45964C0.0665702 8.53392 0.105332 8.60116 0.1541 8.6574H0.15298Z"
                                    stroke="black" strokeWidth="0.5" />
                            </svg>
                        </span>
                    </div>
                    {
                        dataTree?.length &&
                        <div className="filter-editor__tree" data-is-collapsed={isItemFilterVisible}>
                            <Tree
                                checkable
                                checkStrictly
                                checkedKeys={selectedKeys}
                                onCheck={handleFilterCheck}
                                treeData={dataTree}
                                height={300}
                            />
                        </div>
                    }

                </label>

                <label className="filter-editor__row">
                    <span className="filter-editor__label">Статус</span>
                    <Switch className="filter-editor__switch"
                        checked={item?.isActive}
                        onChange={handleFilterStatusChange}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />} />
                </label>

                <div className="filter-editor__row">
                    <span className="filter-editor__label">Каналы</span>
                    <div className="filter-editor__tags">
                        {
                            channels?.map((channel) => (
                                <ChannelItem
                                    channel={channel}
                                    isChecked={channelsSelected.hasOwnProperty(channel.id)}
                                    onChange={handleChannelClick}
                                    key={channel.id} />
                            ))
                        }
                    </div>
                </div>

                <div className="filter-editor__row">
                    <span className="filter-editor__label">Конструктор образов</span>

                    <div className="filter-editor__look">

                        <div className="filter-editor__look-column" data-column="left">
                            {
                                arrayTwo.map(el => (
                                    <label className="filter-editor__look-block" data-block-id={el} key={el}>
                                        <input className="filter-editor__look-block-input"
                                            type="checkbox"
                                            value={el}
                                            onChange={handleLookBlockClick}
                                            checked={lookBlocksSelected.hasOwnProperty(el)} />
                                    </label>
                                ))
                            }
                        </div>
                        <div className="filter-editor__look-column" data-column="right">
                            {
                                arrayFive.map(el => (
                                    <label className="filter-editor__look-block" data-block-id={el} key={el}>
                                        <input className="filter-editor__look-block-input"
                                            type="checkbox"
                                            value={el}
                                            onChange={handleLookBlockClick}
                                            checked={lookBlocksSelected.hasOwnProperty(el)} />
                                        <span className="filter-editor__look-block-zone" />
                                    </label>
                                ))
                            }
                        </div>


                    </div>
                </div>
            </div>

            <div className="filter-editor__foot">
                <DrawerPanelFooter
                    textClose="Отмена"
                    textSubmit="Сохранить"
                    onSubmit={handleFilterSubmit}
                    onClose={handleFilterCancel} />
            </div>
        </div>
    )
}


export default FilterEditor



