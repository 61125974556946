interface option {
    label: string,
    value: string,
}

export const optionsLabelSort = (tagA: option, tagB: option) => {
    if (tagA.label < tagB.label) {
        return -1;
    }
    if (tagA.label > tagB.label) {
        return 1;
    }
    return 0;
}