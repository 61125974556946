import ApiService from "./ApiService";
import ENDPOINTS from "../constants/Endpoints";

class TelegramService extends ApiService {

    public getBotName = async (): Promise<string | null> => {
        return this.get(ENDPOINTS.telegramGetBotName)
            .then((response: any) => response.hasOwnProperty('telegramBotName')
                ? response.telegramBotName
                : null
            );
    }


    public sendProduct = async (productId: number, channelId: string, host: string): Promise<boolean> => {
        return this.post(ENDPOINTS.telegramSendProduct, {productId, cid: channelId, host});
    }

    public sendProductsList = async (productsIds: number[], channelId: string, host: string): Promise<boolean> => {
        return this.post(ENDPOINTS.telegramSendProudctsList, {productsIds, cid: channelId, host});
    }
    // parse = async (url: string): Promise<{}> => {
    //     return this.post<{}>(ENDPOINTS.parseStore, {url});
    // };

}

export default new TelegramService();