import ApiService from "./ApiService";
import ENDPOINTS from "../constants/Endpoints";
import AttachmentUploaded from "../interfaces/AttachmentUploaded";
import ROUTES from "../constants/Routes";

class UploadService extends ApiService {

    fileUpload = async (file: File): Promise<AttachmentUploaded> => {
        const formData = new FormData();
        formData.append("file", file);

        return this.post<AttachmentUploaded>(ENDPOINTS.upload, formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then((el: AttachmentUploaded) => {
                el.file.link = ROUTES.uploads(el.file.name);
                return el;
            });
    }

}

export default new UploadService();