import React from "react";
import {TemplateProps} from "./LookBuilderShapes";
import {Button, Input, Select} from "antd";
import LookBlock from "../LookBlock/LookBlock";

import "./LookBuilderStyles.scss";

const {Option} = Select;

const LookBuilderTemplate: React.FC<TemplateProps> = (props) => (
    <main className="look-builder">
        <header className="look-builder__head">
            <Input className="look-builder__title"
                   value={props.title}
                   onChange={props.onTitleChange}
                   placeholder="Введите название"/>

            <Select className="look-builder__presets" value={props.presetSelected || ""} onChange={props.onPresetSelect}>
                <Option value="">
                    Пресет не выбран
                </Option>
                {
                    props.presets.map(preset => (
                        <Option value={preset.id} key={preset.id}>
                            {preset.title}
                        </Option>
                    ))
                }
            </Select>
        </header>

        <header className="look-builder__meta">

            <Button className="look-builder__start" type="primary" onClick={props.onStart}>Генерировать образ</Button>

            {
                props.total > 0 &&
                <div className="look-builder__total">{props.total} ₽</div>
            }

        </header>

        <section className="look-builder__body">
            <div className="look-builder__column" data-column="left">
                {
                    ['1', '2'].map(blockId => (
                        <LookBlock
                            blockId={blockId}
                            key={blockId}
                            isLocked={props.blocksLocked.hasOwnProperty(blockId)}
                            filters={props.filters}
                            lookBlock={props.blocks[blockId] || null}
                            onChange={props.onFilterChange}
                            onClear={props.onFilterClear}
                            onLock={props.onBlockLock}/>
                    ))
                }
            </div>
            <div className="look-builder__column" data-column="right">
                {
                    ['3', '4', '5', '6', '7'].map(blockId => (
                        <LookBlock
                            blockId={blockId}
                            key={blockId}
                            isLocked={props.blocksLocked.hasOwnProperty(blockId)}
                            filters={props.filters}
                            lookBlock={props.blocks[blockId] || null}
                            onChange={props.onFilterChange}
                            onClear={props.onFilterClear}
                            onLock={props.onBlockLock}/>
                    ))
                }
            </div>
        </section>

        <footer className="look-builder__foot">
            <Button className="look-builder__save"
                    type="primary"
                    onClick={props.onSave}>
                Сохранить
            </Button>
        </footer>




    </main>
);

export default LookBuilderTemplate;