import React from "react";
import {TemplateProps} from "./ProductViewShapes";
import {Button, Checkbox, DatePicker, Input, Select} from "antd";
import ProductGallery from "../../components/ProductGallery/ProductGallery";
import ChannelSelectModal from "../../components/ChannelSelectModal/ChannelSelectModal";
import TagCheckable from "../../components/TagCheckable/TagCheckable";
import {getCurrencySymbol} from "../../helpers/CurrencyTools";
import Cascader from "../../components/Cascader/Cascader";
import moment from "moment";
import "moment/locale/ru";
import locale from "antd/lib/date-picker/locale/ru_RU";
import TransferItems from "../../components/TransferItems/TransferItems";

import {getSeasonsOptions} from "../../helpers/SeasonUtils";

const ProductViewTemplate = (props: TemplateProps) => (
    <div
        style={{
            padding: 24,
            opacity: props.isLoading ? 0.3 : 1,
            pointerEvents: props.isLoading ? "none" : "auto",
        }}
    >
        {props.product && (
            <>
                <div className="scrapper-section scrapper-section--to-telegram">
                    <div className="scrapper-section__body">
                        <div className="scrapper-section__gallery">
                            <ProductGallery
                                images={props.product.images}
                                onImageRemove={props.onImagesChanged}
                                onImageSelect={props.onImagesSelected}
                                onUpdatePosition={props.onUpdateProductImages}
                            />
                        </div>
                    </div>
                </div>
                {props.product.source && (
                    <div className="scrapper-section scrapper-section--to-telegram">
                        <div className="scrapper-section__head">
                            <div className="scrapper-section__label">
                                Магазин:
                            </div>
                        </div>
                        <div className="scrapper-section__body">
                            <span>#{props.product.source}</span>
                        </div>
                    </div>
                )}
                <div className="scrapper-section scrapper-section--to-telegram">
                    <div className="scrapper-section__head">
                        <div className="scrapper-section__label">Артикул:</div>
                    </div>
                    <div className="scrapper-section__body">
                        <Input
                            value={props.product.vendorCode || ""}
                            onChange={props.onVendorCodeChange}
                            placeholder="Введите артикул"
                            style={{width: "300px"}}
                        />
                    </div>
                </div>
                <div className="scrapper-section scrapper-section--to-telegram">
                    <div className="scrapper-section__head">
                        <div className="scrapper-section__label">
                            Ссылка на оригинал:
                        </div>
                    </div>
                    <div className="scrapper-section__body">
                        <div className="scrapper-section__link">
                            <a
                                href={props.product.sourceUrl}
                                className="scrapper-section__link-text"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {props.product.sourceUrl}
                            </a>
                        </div>
                    </div>
                </div>

                <div className="scrapper-section scrapper-section--to-telegram">
                    <div className="scrapper-section__head">
                        <div className="scrapper-section__label">Название:</div>
                    </div>
                    <div className="scrapper-section__body">
                        <Input
                            value={props.product.title}
                            onChange={props.onTitleChange}
                            placeholder="Введите название товара"
                        />
                    </div>
                </div>

                <div className="scrapper-section scrapper-section--to-telegram">
                    <div className="scrapper-section__head">
                        <div className="scrapper-section__label">
                            Стоимость:
                        </div>
                    </div>
                    <div className="scrapper-section__body">
                        <Input
                            name="price"
                            value={props.product.price}
                            onChange={props.onPriceChange}
                            placeholder="Введите стоимость товара"
                            addonAfter={getCurrencySymbol(
                                props.product.currency
                            )}
                            style={{width: "300px"}}
                        />
                    </div>
                </div>

                <div className="scrapper-section scrapper-section--to-telegram">
                    <div className="scrapper-section__head">
                        <div className="scrapper-section__label">
                            Стоимость со скидкой:
                        </div>
                    </div>
                    <div className="scrapper-section__body">
                        <Input
                            name="priceDiscount"
                            value={props.product.priceDiscount || ""}
                            onChange={props.onPriceChange}
                            placeholder="Введите стоимость со скидкой товара"
                            addonAfter={getCurrencySymbol(
                                props.product.currency
                            )}
                            style={{width: "300px"}}
                        />
                    </div>
                </div>

                <div className="scrapper-section">
                    <div className="scrapper-section__head">
                        <div className="scrapper-section__label">Цвет:</div>
                    </div>
                    <div className="scrapper-section__body">
                        <Select
                            allowClear
                            showSearch
                            style={{width: "100%"}}
                            placeholder="Введите цвет"
                            defaultValue={props.product.colorSelect?.title || undefined}
                            onChange={props.onColorSelectedChange}
                            onSearch={props.onColorSelectedSearch}
                            options={props.colors.map((color) => ({
                                label: color.title,
                                value: color.title,
                            }))}
                        />
                    </div>
                </div>

                <div className="scrapper-section">
                    <div className="scrapper-section__head">
                        <div className="scrapper-section__label">Сезон:</div>
                    </div>
                    <div className="scrapper-section__body">
                        <Select
                            allowClear
                            style={{width: "100%"}}
                            placeholder="Выберите сезон"
                            defaultValue={props.product.season || undefined}
                            onChange={props.onSeasonSelectedChange}
                            options={getSeasonsOptions()}
                        />
                    </div>
                </div>

                <div className="scrapper-section">
                    <div className="scrapper-section__head">
                        <div className="scrapper-section__label">Состав:</div>
                    </div>
                    <div className="scrapper-section__body">
                        <Input
                            value={props.product.composition || ""}
                            onChange={props.onCompositionChange}
                            placeholder="Введите состав товара"
                        />
                    </div>
                </div>

                <div className="scrapper-section">
                    <div className="scrapper-section__head">
                        <div className="scrapper-section__label">
                            Доп. теги:
                        </div>
                    </div>

                    <div className="scrapper-section__body">
                        <Select
                            mode="multiple"
                            allowClear
                            style={{width: "100%"}}
                            placeholder="Введите тег"
                            defaultValue={
                                props.product.additionalTag &&
                                props.product.additionalTag.map(
                                    (tag) => tag.title
                                )
                            }
                            onChange={props.onAdditionalTagChange}
                            options={props.additionalTag.map((tag) => {
                                return {
                                    label: tag.title,
                                    value: tag.title,
                                };
                            })}
                        />
                    </div>
                </div>

                <div className="scrapper-section">
                    <div className="scrapper-section__head">
                        <div className="scrapper-section__label">
                            Фильтры каталога:
                        </div>
                    </div>
                    <div className="scrapper-section__body">
                        <Cascader
                            items={props.filters}
                            selectedFilters={Object.keys(props.filtersSelected)}
                            onChecked={props.onChangeSelectedFilters}
                        />
                    </div>
                </div>

                <div className="scrapper-section">
                    <div className="scrapper-section__head">
                        <div className="scrapper-section__label required">Каналы:</div>
                    </div>
                    <div className="scrapper-section__body">
                        {props.channels &&
                            props.channels.map((channel) => (
                                <TagCheckable
                                    key={channel.id}
                                    checked={props.channelsSelected.hasOwnProperty(
                                        channel.id
                                    )}
                                    onChange={(checked) =>
                                        props.onChannelClick(channel, checked)
                                    }
                                >
                                    {channel.title}
                                </TagCheckable>
                            ))}
                    </div>
                </div>

                <div className="scrapper-section">
                    <div className="scrapper-section__head">
                        <div className="scrapper-section__label">Лучшее:</div>
                    </div>

                    <div className="scrapper-section__body">
                        <Checkbox
                            onChange={props.onBestChange}
                            checked={props.product?.isBest}
                        />
                    </div>
                </div>

                <div className="scrapper-section">
                    <div className="scrapper-section__head">
                        <div className="scrapper-section__label">Дата:</div>
                    </div>

                    <div className="scrapper-section__body">
                        <DatePicker
                            defaultValue={moment(props.product.dateAdded)}
                            onChange={props.onDateAddChange}
                            locale={locale}
                        />
                    </div>
                </div>

                <div className="scrapper-section">
                    <div className="scrapper-section__head">
                        <div className="scrapper-section__label">
                            Связь с товарами:
                        </div>
                    </div>

                    <div className="scrapper-section__body">
                        <TransferItems
                            items={props.productsForTransfer}
                            transferItemsKeys={
                                props.product?.followers?.map((childe) =>
                                    String(childe.id)
                                ) || []
                            }
                            onTransferItemsChange={
                                props.onProductFollowersChange
                            }
                        />
                    </div>
                </div>

                {!props.isArchive && (
                    <div className="scrapper-actions">
                        <Button
                            type="primary"
                            onClick={props.onSave}
                            style={{marginRight: "20px"}}
                        >
                            Сохранить
                        </Button>
                        {props.product.dateParsed != null &&
                            props.product.dateDrafted == null &&
                            props.product.dateDeleted == null && (
                                <Button
                                    type="dashed"
                                    onClick={props.onChannelSelectOpen}
                                >
                                    Отправить в Telegram
                                </Button>
                            )}
                        {props.product.dateDrafted !== null && (
                            <div style={{marginLeft: "auto"}}>
                                <Button
                                    type="dashed"
                                    onClick={props.onParseAgain}
                                >
                                    Спарсить повторно
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </>
        )}

        <ChannelSelectModal
            visible={props.channelSelectVisible}
            channels={props.channels}
            isSending={props.isSending}
            onSubmit={props.onProductsSend}
            onCancel={props.onChannelSelectClose}
        />
    </div>
);

export default ProductViewTemplate;
