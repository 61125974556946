import {PureComponent} from "react";
import {Props, State, TemplateProps} from "./ProductParserShapes";
import Template from "./ProductParserTemplate";
import ScrapperService from "../../services/ScrapperService";
import {message} from "antd";
import {Product, ProductSaved, ProductScrapped} from "../../interfaces/Product";
import HandlerTextInputChange from "../../interfaces/HandlerTextInputChange";
import {ProductGalleryProps} from "../../components/ProductGallery/ProductGallery-types";
import ERROR_CODES from "../../constants/ErrorCodes";
import ROUTES from "../../constants/Routes";
import FilterModel from "../../models/FilterModel";
import Filter from "../../interfaces/Filter";
import ProductModel from "../../models/ProductModel";
import {DetectShopByLink} from "../../helpers/StoreHelpers";
import {productConvertForTransferData, productFollowerFound, productsLoadFromServerUtil, validateBeforeSendToTelegram} from "../../helpers/ProductUtils";
import TelegramService from "../../services/TelegramService";
import ProductImage from "../../interfaces/ProductImage";
import Channel from "../../interfaces/Channel";
import ChannelModel from "../../models/ChannelModel";
import Attachment from "../../interfaces/Attachment";
import {DatePickerProps} from "antd/lib/date-picker";
import AdditionalTagModel from "../../models/AdditionalTagModel";
import {AdditionalTag} from "../../interfaces/AdditionalTag";
import ColorModel from "../../models/ColorModel";


interface ProductImagesObject {
    [key: string]: ProductImage,
}

export class ProductParser extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            url: "",
            store: null,
            isLoading: false,
            product: null,
            products: [],
            productsForTransfer: [],
            currency: '',
            filters: [],
            filtersSelected: {},
            channels: [],
            colors: [],
            channelsSelected: {},
            channelSelectVisible: false,
            isSending: false,
            additionalTag: [],
        };
    }

    componentDidMount() {
        this.filtersLoadFromServer()
            .catch(err => console.error(err));

        this.channelsLoadFromServer()
            .catch(err => console.error(err));

        this.additionalTagLoadFromServer()
            .catch(err => console.error(err));

        this.colorsLoadFromServer()
            .catch(err => console.error(err));

        this.productsLoadFromServer()
            .catch(err => console.error(err));
    }


    channelsLoadFromServer = async () => {
        try {
            const channels = await ChannelModel.getAll();
            this.channelsUpdateInList(channels);
        } catch (err) {
            console.error(err)
        }
    }

    productsLoadFromServer = async () => {
        const products = await productsLoadFromServerUtil();

        const productsForTransfer = products?.map(productConvertForTransferData);

        if (productsForTransfer && products) {
            this.setState(state => ({
                ...state,
                productsForTransfer,
                products,
            }));
        }
    }

    channelsUpdateInList = (channels: Channel[]) => {
        this.setState(state => ({
            ...state,
            channels: channels.filter(el => el.isActive),
        }));
    };

    colorsLoadFromServer = async () => {
        try {
            const colors = await ColorModel.getAllActive();

            this.setState(state => ({
                ...state, colors,
            }));
        } catch (err) {
            console.error(err)
        }
    }

    channelClick = (channel: Channel) => {
        const channelsSelected = {...this.state.channelsSelected};

        if (channelsSelected.hasOwnProperty(channel.id)) {
            delete channelsSelected[channel.id];
        } else {
            channelsSelected[channel.id] = "";
        }

        this.setState(state => ({...state, channelsSelected}));
    };


    filtersLoadFromServer = async () => {
        try {
            const filters = await FilterModel.getAllActive();
            this.filtersUpdateInList(filters);
        } catch (err) {
            console.error(err)
        }
    }

    filtersUpdateInList = (filters: Filter[]) => {
        this.setState(state => ({...state, filters}));
    }

    additionalTagLoadFromServer = async () => {
        try {
            const additionalTag = await AdditionalTagModel.getAllActive();

            this.setState(state => ({
                ...state, additionalTag,
            }));
        } catch (err) {
            console.error(err)
        }
    }

    filterClick = (filter: Filter) => {
        const filtersSelected = {...this.state.filtersSelected};

        if (filtersSelected.hasOwnProperty(filter.id)) {
            delete filtersSelected[filter.id];
        } else {
            filtersSelected[filter.id] = "";
        }

        this.setState(state => ({...state, filtersSelected}));
    };

    changeSelectedFilters = (filtersIds: number[]) => {
        const filtersSelected: State["filtersSelected"] = {};

        filtersIds.forEach(filtersId => {
            filtersSelected[filtersId] = "";
        });

        this.setState(state => ({...state, filtersSelected}));
    };

    handleUrlChange: HandlerTextInputChange = (e) => {
        const url = e.currentTarget.value
            .replace('/m/', '/')
            .replace('m.zara.com', 'www.zara.com');

        this.setState(state => ({...state, url}));
    };

    handleUrlSubmit = () => {
        const url = this.state.url.toLowerCase();
        const store = DetectShopByLink(url);

        if (url.substr(0, 8) !== "https://" && url.substr(0, 7) !== "http://") {
            message.warn("Ссылка должна начинаться с http:// или https://");
            return;
        }

        if (!store) {
            message.warn("Введенный адрес не соответствует магазину для парсинга");
            return;
        }


        this.setState(state => ({...state, store, isLoading: true}), () => {
            this.urlProcessing(this.state.url)
                .catch(err => console.error(err));
        });
    };

    handleProductFollowersChange: TemplateProps['onProductFollowersChange'] = (items) => {
        if (this.state.product) {
            const localProduct = {...this.state.product};
            const products = [...this.state.products];

            if (localProduct.followers) {
                localProduct.followers = productFollowerFound(items, products)
            }

            this.setState(state => ({...state, product: localProduct}));
        };
    }


    urlProcessing = async (url: string = "") => {
        try {
            const product = await this.getProductData(url);

            if (product) {
                this.setState(state => ({...state, product, isLoading: false}));
            }
        } catch (err) {
            this.setState(state => ({...state, isLoading: false}));
            console.error(`Url processing error: ${err.data}`);

            switch (err.status) {
                case ERROR_CODES.NOT_FOUND:
                case ERROR_CODES.GATEWAY_TIMEOUT:
                    message.error("Сервер недоступен, обратитесь к разработчику!");
                    break;
                default:
                    message.error("При загрузке данных произошла ошибка. Проверьте, правильно ли введена ссылка");
                    break;
            }
        }
    }

    getProductData = async (url: string): Promise<ProductScrapped | undefined> => {
        try {
            const link = url.replace(
                '//m.uniqlo.com/ru/estore/product?pid=',
                '//www.uniqlo.com/ru/estore/ru_RU/product-detail.html?productCode='
            );

            const product = await ScrapperService.parse(link);

            const source = DetectShopByLink(link);
            if (source) {
                product.source = source;
            }
            product.sourceUrl = link;
            product.dateAdded = new Date();
            return product;
        } catch (err) {
            message.error("Ошибка при получении данных о товаре");
            console.error(`Product data load error: ${err.message}`);
        }
    }

    handleAdditionalTagChange: TemplateProps["onAdditionalTagChange"] = (values) => {
        if (this.state.product) {
            const resultProduct = {...this.state.product};
            const resultAdditionalTag: AdditionalTag[] = [];
            const additionalTag = [...this.state.additionalTag];

            for (let index = 0; index < additionalTag.length; index++) {
                const element = additionalTag[index];

                const result = values.find(value => value === element.title);

                if (result) {
                    resultAdditionalTag.push(element);
                }
            }

            resultProduct.additionalTag = [...resultAdditionalTag];

            this.setState(state => ({...state, product: resultProduct}));
        };
    }

    productTitleChange: HandlerTextInputChange = (e) => {
        if (this.state.product) {
            const product = {...this.state.product};
            product.title = e.currentTarget.value;
            this.setState(state => ({...state, product}));
        }
    }

    productPriceChange: HandlerTextInputChange = (e) => {
        if (this.state.product) {
            const field: keyof Product = e.currentTarget.name as 'price' | 'priceDiscount';

            const product = {...this.state.product};
            product[field] = e.currentTarget.value.replace(/[^\d.]/g, '');
            this.setState(state => ({...state, product}));
        }
    }

    productColorChange: HandlerTextInputChange = (e) => {
        if (this.state.product) {
            const product = {...this.state.product};
            product.color = e.currentTarget.value;
            this.setState(state => ({...state, product}));
        }
    }

    productCompositionChange: HandlerTextInputChange = (e) => {
        if (this.state.product) {
            const product = {...this.state.product};
            product.composition = e.currentTarget.value;
            this.setState(state => ({...state, product}));
        }
    };

    productVendorCodeChange: HandlerTextInputChange = (e) => {
        if (this.state.product) {
            const product = {...this.state.product};
            product.vendorCode = e.currentTarget.value;
            this.setState(state => ({...state, product}));
        }
    }

    handleBestChange: TemplateProps["onBestChange"] = (e) => {
        if (this.state.product) {
            const product = {...this.state.product};
            product.isBest = !product.isBest;

            this.setState(state => ({...state, product}));
        };
    }

    productSave = async (product: ProductScrapped, filters: Filter[] = [], channels: Channel[] = []): Promise<ProductSaved | null> => {
        if (this.state.store) {
            return ProductModel.createFromScrapped({
                ...product,
                vendorCode: product.vendorCode || null,
                filters,
                channels,
                source: this.state.store,
            });
        } else {
            message.error("Ошибка при сохранении: Не указано название магазина");
            return null;
        }
    }


    imagesProductConvertToObject = (images: ProductImage[]): ProductImagesObject => {
        const _images: ProductImagesObject = {};
        images.forEach((image: ProductImage) => {
            _images[image.url] = {...image};
        });
        return _images;
    }

    imagesChange: ProductGalleryProps["onImageRemove"] = (link) => {
        if (this.state.product) {
            const product = {...this.state.product};
            product.images = product.images.filter(image => image.link !== link);

            this.setState(state => ({...state, product}));
        }
    }

    imagesSelected: ProductGalleryProps["onImageSelect"] = ({currentTarget: {dataset: {link}}}) => {
        if (this.state.product) {
            const product = {...this.state.product};

            product.images = product.images.map(image => ({
                ...image,
                toTelegram: image.link === link ? !image.toTelegram : image.toTelegram,
            }))

            this.setState(state => ({...state, product}));
        }
    };

    handleUpdatePositionImages: ProductGalleryProps["onUpdatePosition"] = (images: Attachment[]) => {
        if (this.state.product) {
            const product = {...this.state.product};

            product.images = [...images];

            this.setState(state => ({...state, product}));
        }
    }


    handleChannelSelectOpen = () => {
        this.setState(state => ({...state, channelSelectVisible: true}));
    }

    handleChannelSelectClose = () => {
        this.setState(state => ({...state, channelSelectVisible: false, channelToSend: undefined, isSending: false}))
    }


    handleProductsSend = (channelId: string) => {
        this.setState(state => ({...state, isSending: true}), async () => {
            await this.handleProductSendToTelegram(channelId);
            await this.handleChannelSelectClose();
        });
    };

    handleProductSendToTelegram = async (channelId: string) => {
        if (this.state.product && this.state.product.images.filter(image => image.toTelegram).length > 0) {
            let product: ProductSaved | null = null;

            try {
                product = await this.productSave(
                    this.state.product,
                    this.state.filters.filter(el => this.state.filtersSelected.hasOwnProperty(el.id)),
                    this.state.channels.filter(el => this.state.channelsSelected.hasOwnProperty(el.id))
                )
            } catch (err) {
                console.error(err);
                message.error("Проблема при отправке товара в телеграм");
            }

            try {

                if (product && validateBeforeSendToTelegram(product)) {
                    await TelegramService.sendProduct(product.id, channelId, window.location.origin);

                    message.success("Товар успешно обновлен и отправлен в телегрм канал");
                    this.props.history.push(ROUTES.products.replace(':id?', product.id.toString()));
                }

            } catch (err) {
                message.error("Товар успешно обновлен, но при отпрввке в телеграм произошла ошибка");
            }
        } else {
            message.warn("Выбрано недостаточно изображений");
        }
    }

    handleDateAddChange: DatePickerProps["onChange"] = (date, dateString) => {
        if (this.state.product) {
            const product = {...this.state.product};

            product.dateAdded = date?.toDate();

            this.setState(state => ({...state, product}));
        }
    }

    handleColorSelectedChange: TemplateProps["onColorSelectedChange"] = (values) => {
        if (this.state.product) {
            const resultProduct = {...this.state.product};

            const resultColor = this.state.colors.find(color => {
                return color.title === values;
            });

            if (resultColor) {
                resultProduct.colorSelect = {...resultColor};

                this.setState(state => ({...state, product: resultProduct}));
            }
        }
    }

    handleSeasonSelectedChange: TemplateProps["onSeasonSelectedChange"] = (season) => {
        this.setState(state => {
            return state.product
                ? {...state, product: {...state.product, season}}
                : state
        })
    }

    handleColorSelectedSearch: TemplateProps["onColorSelectedSearch"] = (values) => {
    }

    save = async () => {
        const isChannelsSelected = Object.keys(this.state.channelsSelected).length > 0;

        if (this.state.product && isChannelsSelected) {
            try {
                await this.productSave(
                    this.state.product,
                    this.state.filters.filter(el => this.state.filtersSelected.hasOwnProperty(el.id)),
                    this.state.channels.filter(el => this.state.channelsSelected.hasOwnProperty(el.id))
                );

                this.props.history.push(ROUTES.products.replace(':id?', ''));
                message.success("Товар успешно сохранен");

            } catch (err) {
                message.error("Не удалось сохранить товар!");
                console.error(err);
            }
        } else {
            !this.state.product && message.error("Не удалось найти товар!", 3);
            !isChannelsSelected && message.error('Выберите хотя бы один "Канал"', 3);
        }
    };


    render() {
        return Template({
            ...this.state,
            onUrlChange: this.handleUrlChange,
            onUrlSubmit: this.handleUrlSubmit,
            onImagesChanged: this.imagesChange,
            onImagesSelected: this.imagesSelected,
            onTitleChange: this.productTitleChange,
            onPriceChange: this.productPriceChange,
            onColorChange: this.productColorChange,
            onCompositionChange: this.productCompositionChange,
            onVendorCodeChange: this.productVendorCodeChange,
            onSave: this.save,
            onFilterClick: this.filterClick,
            onChannelClick: this.channelClick,
            onChangeSelectedFilters: this.changeSelectedFilters,
            onUpdatePositionImages: this.handleUpdatePositionImages,
            onDateAddChange: this.handleDateAddChange,
            onBestChange: this.handleBestChange,
            onAdditionalTagChange: this.handleAdditionalTagChange,
            onProductFollowersChange: this.handleProductFollowersChange,
            onColorSelectedChange: this.handleColorSelectedChange,
            onColorSelectedSearch: this.handleColorSelectedSearch,
            onSeasonSelectedChange: this.handleSeasonSelectedChange,

            onProductsSend: this.handleProductsSend,
            onChannelSelectOpen: this.handleChannelSelectOpen,
            onChannelSelectClose: this.handleChannelSelectClose,
        });
    };
}


export default ProductParser;
