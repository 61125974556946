import React from "react";
import {PageHeader} from "antd";
import {Props} from "./LayoutPageShapes";

const LayoutPage: React.FC<Props> = ({children, ...props}) => (
    <>
        {
            props.title &&
            <PageHeader {...props} title={props.title} ghost={false} />
        }
        {children}
    </>
);

export default LayoutPage;