import {ChangeEventHandler, PureComponent} from "react";
import {Props, State} from "./FiltersListShapes";
import Template from "./FiltersListTemplate";
import FilterModel from "../../models/FilterModel";
import Filter from "../../interfaces/Filter";
import {message} from "antd";
import {
    CascaderItemArchiveClickHandler,
    CascaderItemEditClickHandler,
} from "../../components/Cascader/CascaderNode/CascaderNode-types";
import {CascaderTree} from "../../components/Cascader/Cascader-types";

export class FiltersList extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: true,
            items: [],
            find: "",
            editorVisible: false,
            editorItemId: null,
        };
    }

    componentDidMount() {
        this.itemsLoadFromServer().catch((err) => console.error(err));
    }

    itemsLoadFromServer = async () => {
        try {
            const items = await FilterModel.getAll();

            this.itemsUpdateInList(items);
        } catch (err) {
            message.error("Проблема при загрузке данных с сервера");
            console.error(err);
        }
    };

    itemsUpdateInList = (items: Filter[]) => {
        this.setState((state) => ({...state, items, isLoading: false}));
    };

    itemRemove = async (itemId: number) => {
        try {
            await FilterModel.remove(itemId);

            const items = this.state.items.filter((el) => el.id !== itemId);
            this.setState(
                (state) => ({...state, items}),
                () => {
                    message.success("Фильтр успешно удален");
                }
            );
        } catch (err) {
            message.error("Не удается получить ID фильтра");
            console.error(err);
        }
    };

    itemActiveToggle: CascaderItemArchiveClickHandler = async (filterId) => {
        try {
            const items = [...this.state.items];
            const itemIndex: number = items.findIndex(
                (el) => el.id === filterId
            );

            if (itemIndex >= 0) {
                const item = {...this.state.items[itemIndex]};
                item.isActive = !item.isActive;

                await FilterModel.update(item);

                items[itemIndex] = item;

                this.setState(
                    (state) => ({...state, items}),
                    () => {
                        message.success(
                            item.isActive
                                ? "Фильтр успешно восстановлен"
                                : "Фильтр успешно деактивирован"
                        );
                    }
                );
            } else {
                message.error("Не удается получить ID фильтра");
            }
        } catch (err) {
            message.error("При архивировании произошла ошибка");
            console.error(err);
        }
    };

    itemsSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
        const find = e.currentTarget.value;
        this.setState((state) => ({...state, find}));
    };

    editorToggle: CascaderItemEditClickHandler = (key) => {
        const editorItemId = Number(key) || null;

        this.setState((state) => ({
            ...state,
            editorItemId,
            editorVisible: !this.state.editorVisible,
        }));
    };

    editorClose = () => {
        this.setState((state) => ({
            ...state,
            editorVisible: !this.state.editorVisible,
        }));
    };

    editorSubmit = (item: Filter) => {
        const items = [...this.state.items];
        const itemIndex = items.findIndex((el) => el.id === item.id);

        if (itemIndex >= 0) {
            items.splice(itemIndex, 1, {...item});
        } else {
            items.unshift({...item});
        }

        this.setState(
            (state) => ({
                ...state,
                items,
                editorItemId: null,
                editorVisible: false,
            }),
            () => {
                message.success(
                    itemIndex >= 0
                        ? "Фильтр успешно отредактирован"
                        : "Фильтр успешно создан"
                );
            }
        );
    };

    itemsFilter = () => {
        const find = this.state.find.toLowerCase();

        return this.state.items
            .filter((el) => el.title.toLowerCase().includes(find))
            .sort(function (a, b) {
                if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
                if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
                return 0;
            });
    };

    updatePosition = async (cascaderItems: CascaderTree[] = []) => {
        if (cascaderItems.length) {
            const filters: Filter[] = [];

            for (let index = 0; index < cascaderItems.length; index++) {
                const element = cascaderItems[index];

                let indexResult = this.state.items.findIndex(
                    (item) => item.id === element.key
                );

                if (indexResult >= 0) {
                    const item = {
                        ...this.state.items[indexResult],
                        position: element.position,
                    };

                    filters.push(item);
                }
            }

            const items: Filter[] = this.state.items.map((stateItem) => {
                const result = filters.find((item) => item.id === stateItem.id);

                if (result) {
                    return result;
                }

                return stateItem;
            });

            this.setState((state) => ({...state, items}));

            const chunksFilters = [];
            const chunkSize = 100;

            for (let index = 0; index < filters.length; index += chunkSize) {
                const chunk = filters.slice(index, index + chunkSize);

                chunksFilters.push(chunk);
            }

            await Promise.all(
                chunksFilters.map((chunk) => FilterModel.updateList(chunk))
            );
        }
    };

    render() {
        return Template({
            ...this.state,
            items: this.itemsFilter(),
            onItemRemove: this.itemRemove,
            onItemActiveToggle: this.itemActiveToggle,
            onItemsSearch: this.itemsSearch,
            onEditorToggle: this.editorToggle,
            onEditorClose: this.editorClose,
            onEditorSubmit: this.editorSubmit,
            onUpdatePosition: this.updatePosition,
        });
    }
}

export default FiltersList;
