import React from "react";
import {TemplateProps} from "./LookCreateShapes";
import LookBuilder from "../../components/LookBuilder/LookBuilder";

import "./LookCretaeStyles.scss";

const LookCreateTemplate: React.FC<TemplateProps> = (props) => (
    <div className="look-create">
        <LookBuilder onAfterSave={props.onAfterSave}/>
    </div>
);

export default LookCreateTemplate;