import React from "react";
import {ProductCardProps} from "./ProductCardShapes";
import {Link} from "../Routing";
import ROUTES from "../../constants/Routes";
import {Popconfirm} from "antd";
import {FormOutlined, CloseSquareOutlined} from "@ant-design/icons";

import "./ProductCardStyles.scss";
import {stopPropagation} from "../../helpers/ActionUtils";
import { getCurrencySymbol } from "../../helpers/CurrencyTools";


const ProductCard: React.FC<ProductCardProps> = ({item, productUrl = ROUTES.products, ...props}) => {
    const handleRemove = (e: any) => {
        e.stopPropagation();
        props.onRemove(item);
    };

    return (

        <div className="product-card" data-source={item.source}>
            <Link className="product-card__body" to={productUrl.replace(':id?', item.id.toString())}>

                <div className="product-card__images">
                    {
                        item.images.map((image, index) => (index > 3)
                            ? null
                            : <div className="product-card__image"
                                   key={image.name}
                                   style={{backgroundImage: `url(${ROUTES.uploads(image.name)})`}}/>
                        )
                    }
                    <div className="product-card__actions">
                        <Popconfirm title="Вы точно хотите удалить?" onConfirm={handleRemove} onCancel={stopPropagation}>
                            <button className="product-card__remove" title="Удалить" onClick={stopPropagation}>
                                <CloseSquareOutlined/>
                            </button>
                        </Popconfirm>

                        <button className="product-card__edit" title="Редактировать">
                            <FormOutlined/>
                        </button>
                    </div>
                </div>

                <div className="product-card__info">
                    <div className="product-card__title">{item.title}</div>
                    <div className="product-card__price">{item.priceDiscount || item.price} {getCurrencySymbol(item.currency)}</div>
                    <div className="product-card__url">{item.sourceUrl}</div>
                    <div className="product-card__store">#{item.source}</div>
                </div>

            </Link>
        </div>
    )
}

export default ProductCard;