import React, {FC, memo, useCallback} from "react";
import TagCheckable from "../../TagCheckable/TagCheckable";
import {ChannelItemProps} from "./ChannelItem-types";

const ChannelItem: FC<ChannelItemProps> = (props) => {
    const {channel, isChecked, onChange} = props;

    const handleChange = useCallback((checked: boolean) => {
        onChange(channel, checked);
    }, [channel, onChange]);

    return (
        <TagCheckable checked={isChecked} onChange={handleChange}>
            {channel.title}
        </TagCheckable>
    )
}

export default memo(ChannelItem);
