import BaseModel from "./BaseModel";
import ENDPOINTS from "../constants/Endpoints";
import {ProductSaved, ProductEmpty, ProductScrapped, Product} from "../interfaces/Product";
import ROUTES from "../constants/Routes";
import {SYSTEM_PER_PAGE} from "../constants/SystemConfig";
import RequestOptions from "../interfaces/RequestOptions";
import {ITEM_STATUS_ARCHIVED, ITEM_STATUS_DRAFT} from "../constants/ItemStatuses";
import {ResponseProductSave} from "../interfaces/ResponseProductSave";
import Filter from "../interfaces/Filter";
import Channel from "../interfaces/Channel";

class ProductModel extends BaseModel<ProductSaved, ProductEmpty, ProductScrapped> {

    getInstanceId(item: ProductSaved): number | null {
        return item.id;
    }

    public getAllArchived = async (page: number = 1, perPage: number = SYSTEM_PER_PAGE, options: RequestOptions = {}): Promise<ProductSaved[]> => {
        const optionsDraft = {status: ITEM_STATUS_ARCHIVED, ...options};

        return this.getAll(page, perPage, optionsDraft);
    }

    public getDrafts = async (page: number = 1, perPage: number = SYSTEM_PER_PAGE, options: RequestOptions = {}): Promise<ProductSaved[]> => {
        const optionsDraft = {status: ITEM_STATUS_DRAFT, ...options};
        return this.getAll(page, perPage, optionsDraft);
    }

    public createVer2 = async (product: Product, filters: Filter[], channels: Channel[]): Promise<ResponseProductSave> => {
        const url = ENDPOINTS.productVer2;

        return this.post<ResponseProductSave>(url, {product, filters, channels})
            .then((response: ResponseProductSave) => response);
    }

    public createFromScrapped = async (item: ProductScrapped): Promise<ProductSaved> => {
        const url = ENDPOINTS.product;

        return this.post<ProductSaved>(url, item)
            .then((item: ProductSaved) => item);
    }

    public createBlank = async (): Promise<ProductEmpty> => {
        const item: ProductEmpty = {
            title: "",
            price: "",
            priceDiscount: null,
            color: "",
            composition: "",
            vendorCode: null,
            images: [],
            filters: [],
            channels: [],
            source: null,
            sourceUrl: "",
            isBest: false,
            followers: [],
            additionalTag: [],
            season: null,
        }
        return Promise.resolve(item)
            .then(result => this.enrichItem<ProductEmpty>(result));
    }

    public updateDraft = async (item: ProductSaved): Promise<ProductSaved> => {
        const itemId = this.getInstanceId(item);
        const url = `${this.endpointSingle}/draft/${itemId}`;

        const itemPrepared = this.prepareForSave(item);

        return this.put<ProductSaved>(url, itemPrepared)
            .then((item: ProductSaved) => this.enrichItem<ProductSaved>(item));
    };



    public enrichItem = <X extends ProductSaved | ProductEmpty>(item: X): X => ({
        ...item,
        images: item.images.map(image => ({
            ...image,
            link: image.id == null ? image.link : ROUTES.uploads(image.name),
        }))
    });
}

export default new ProductModel(ENDPOINTS.product);