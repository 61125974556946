import React from "react";
import {RouteComponentProps} from 'react-router-dom';
import {Redirect, RoutePublic, Switch} from "../../components/Routing";
import RouteOption from "../../interfaces/RouteOption";
import ROUTES from "../../constants/Routes";
import LookCreate from "../LookCreate/LookCreate";
import LooksList from "../LooksList/LooksList";
import LooksView from "../LooksView/LooksView";
import LayoutPublic from "../../components/LayoutPublic/LayoutPublic";

const AppPublicRoutes: RouteOption[] = [
    {component: LookCreate, path: ROUTES.looksBuilder},
    {component: LooksView, path: ROUTES.looksView},
    {component: LooksList, path: ROUTES.looks},
];

const AppPublic: React.FC<RouteComponentProps> = () => (
    <LayoutPublic>
        <Switch>
            {
                AppPublicRoutes.map(route => (
                    <RoutePublic path={route.path}
                                 exact={route.exact}
                                 component={route.component}
                                 key={route.path}/>
                ))
            }
            <Redirect from={ROUTES.publicApp} to={ROUTES.looks} exact />
        </Switch>
    </LayoutPublic>
);

export default AppPublic;