import ServiceTypecheck from "../services/ServiceTypecheck";

export enum Gender {
    male = 'male',
    female = 'female',
}

export const isGender = (content: unknown): content is Gender => {
    return ServiceTypecheck.isEnumValue(content, Gender);
}

export interface HandlerGenderChangeWithId {
    (value: Gender | undefined, option: number): void;
}

export interface HandlerGenderChange {
    (value: Gender): void;
}