import {PureComponent} from "react";
import {Props, State} from "./ProductCreatorShapes";
import Template from "./ProductCreatorTemplate";
import {
    ProductEditorCancelHandler,
    ProductEditorSubmitHandler
} from "../../components/ProductEditor/ProductEditorShapes";
import ProductModel from "../../models/ProductModel";
import {message} from "antd";
import ROUTES from "../../constants/Routes";

export class ProductCreator extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    submit: ProductEditorSubmitHandler = async (product, filters, channels) => {
        try {
            const {product: productSaved} = await ProductModel.createVer2(product, filters, channels);
            if (productSaved != null && productSaved.id) {
                this.props.history.push(ROUTES.products.replace(":id?", productSaved.id.toString()));
            }
        } catch (err) {
            message.error("Ошибка при сохранении товара!");
            return null;
        }
    };

    cancel: ProductEditorCancelHandler = () => {
        this.props.history.push(ROUTES.products.replace(":id?", ""));
    };

    render() {
        return Template({
            ...this.state,
            onSubmit: this.submit,
            onCancel: this.cancel,
        });
    };
}

export default ProductCreator;