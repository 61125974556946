import BaseModel from "./BaseModel";
import ENDPOINTS from "../constants/Endpoints";
import Filter from "../interfaces/Filter";
import {Look, LookEmpty} from "../interfaces/Look";
import {FilterSelectable} from "../interfaces/FilterSelectable";


class LookModel extends BaseModel<Look, LookEmpty> {

    getInstanceId(item: Look): number | null {
        return item.id;
    }

    public createBlank = async (): Promise<LookEmpty> => {
        const item: LookEmpty = {
            title: "",
            blocks: {},
        };

        return Promise.resolve(item);
    }


    public getLookFilters = async (): Promise<FilterSelectable[]> => {
        return this.get<Filter[]>(ENDPOINTS.lookFilters)
            .then((filters: Filter[]) => filters.filter(el => el.isActive))
            .then((filters: Filter[]) => this.convertFiltersToSelectable(filters))
    }

    public getLookData = async (filterIds: number[]): Promise<FilterSelectable[]> => {
        return this.get<Filter[]>(ENDPOINTS.lookVariant, {params: {filterIds}})
            .then((filters: Filter[]) => this.convertFiltersToSelectable(filters));
    }

    public convertFiltersToSelectable = (filters: Filter[]): FilterSelectable[] => {
        return filters.map<FilterSelectable>((filter: Filter) => ({...filter, isSelected: false}));
    }

}

export default new LookModel(ENDPOINTS.look);