import React, {FC, memo, useEffect, useState} from "react";
import ContentListNode from "./ContentListNode/ContentListNode";
import {ContentListNodeType} from "./ContentListNode/ContentListNode-types";
import {Body, Wrap} from "./ContentListView-styles";
import {ContentListViewProps} from "./ContentListView-types";

const ContentListView: FC<ContentListViewProps> = (props) => {
    const {items, isEdit, onEdit, onRemove, onArchive} = props;

    const [ContentList, setContentListView] =
        useState<ContentListNodeType[]>(items);

    useEffect(() => {
        setContentListView(items);
    }, [items]);

    return (
        <Wrap>
            {ContentList?.map((item) => {
                return (
                    <Body key={item.id}>
                        <ContentListNode
                            item={item}
                            isEdit={isEdit}
                            onEdit={onEdit}
                            onRemove={onRemove}
                            onArchive={onArchive}
                        />
                    </Body>
                );
            })}
        </Wrap>
    );
};

export default memo(ContentListView);