import React, {FC, memo} from "react";
import {Popconfirm} from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    ExportOutlined,
    ImportOutlined,
} from "@ant-design/icons";
import {stopPropagation} from "../../../helpers/ActionUtils";
import {ContentListNodeProps} from "./ContentListNode-types";
import {
    Wrap,
    Head,
    ActionArchive,
    Body,
    Title,
    Foot,
    ActionEdit,
    ActionRemove,
} from "./ContentListNode-styles";
import {useContentListNodeActionHandler} from "./ContentListNode-hooks";

const ContentListNode: FC<ContentListNodeProps> = (props) => {
    const {item, isEdit, onEdit, onRemove, onArchive} = props;

    const handleEdit = useContentListNodeActionHandler(item.id, onEdit);
    const handleRemove = useContentListNodeActionHandler(item.id, onRemove);
    const handleArchive = useContentListNodeActionHandler(item.id, onArchive);

    return (
        <Wrap isArchived={!item.isActive} isEdit={isEdit}>
            <Head>
                {onArchive && (
                    <ActionArchive
                        type="link"
                        onClick={handleArchive}
                        data-item-id={item.id}
                        data-is-archive={item.isActive}
                    >
                        {item.isActive ? (
                            <ImportOutlined
                                style={{transform: "rotate(-90deg)"}}
                                title="Архивировать"
                            />
                        ) : (
                            <ExportOutlined
                                style={{transform: "rotate(-90deg)"}}
                                title="Достать из архива"
                            />
                        )}
                    </ActionArchive>
                )}
            </Head>

            <Body>
                <Title data-key={item.id} data-is-children-presence={true}>
                    {item.title}
                </Title>
            </Body>

            <Foot>
                {onEdit && (
                    <ActionEdit type="link" onClick={handleEdit}>
                        <EditOutlined />
                    </ActionEdit>
                )}

                {onRemove && (
                    <Popconfirm
                        title={`Точно хотите удалить "${item.title}"?`}
                        okText="Да, удаляй"
                        cancelText="Отмена"
                        onConfirm={handleRemove}
                    >
                        <ActionRemove type="link" onClick={stopPropagation}>
                            <DeleteOutlined />
                        </ActionRemove>
                    </Popconfirm>
                )}
            </Foot>
        </Wrap>
    );
};

export default memo(ContentListNode);
