const ROUTES = {
    uploads: (filename: string): string => `/uploads/${filename}`,
    publicApp: '/app' as string,
    privateApp: '/private' as string,
    login: '/login' as string,

    productParser: '/private/products/parse' as string,
    productCreator: '/private/products/create' as string,
    products: '/private/products/:id?' as string,
    drafts: '/private/drafts/:id?' as string,
    settings: '/private/settings' as string,
    channels: '/private/settings/channels/:cid?' as string,
    lookPreset: '/private/settings/look-preset' as string,
    filters: '/private/settings/filters' as string,
    colors: '/private/settings/colors' as string,
    archive: '/private/archive/:id?' as string,
    archiveLooks: '/private/archive-looks/' as string,
    additionalTag: '/private/settings/tags' as string,

    looksBuilder: '/app/looks/builder' as string,
    looksView: '/app/looks/id/:id?' as string,
    looks: '/app/looks' as string,
};

export default ROUTES;