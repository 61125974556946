import React from "react";
import {Button} from "antd";
import {FooterProps} from "./DrawerPanelShapes";
import "./DrawerPanelFooterStyles.scss";

const DrawerPanelFooter: React.FC<FooterProps> = (props) => (
    <div className="drawer-panel-footer">
        <Button className="drawer-panel-footer__cancel" type="dashed" onClick={props.onClose}>
            {props.textClose || 'Отмена'}
        </Button>
        <Button className="drawer-panel-footer__submit" type="primary" onClick={props.onSubmit}>
            {props.textSubmit || 'Сохранить'}
        </Button>
    </div>
);

export default DrawerPanelFooter;