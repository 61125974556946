import {Transfer} from "antd";
import {TransferDirection} from "antd/lib/transfer";
import React, {FC, useEffect, useState} from "react";
import {TransferData} from "../../interfaces/TransferData";

import {
    Selector,
    SelectorImage,
    SelectorTitle,
    Wrap,
} from "./TransferItems-styles";
import {TransferItemsProps} from "./TransferItems-types";

const TransferItems: FC<TransferItemsProps> = (props) => {
    const {items, transferItemsKeys, onTransferItemsChange} = props;

    const [targetKeys, setTargetKeys] = useState<string[]>(items.map((item) => item.key));
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

    useEffect(() => {
        if (transferItemsKeys) {
            setTargetKeys(transferItemsKeys);
        }
    }, [transferItemsKeys]);

    const handleChange = (
        nextTargetKeys: string[],
        direction: TransferDirection,
        moveKeys: string[]
    ) => {
        onTransferItemsChange(nextTargetKeys);
    };

    const handleSelectChange = (
        sourceSelectedKeys: string[],
        targetSelectedKeys: string[]
    ) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    const handleSearch = (dir: TransferDirection, value: string) => {};

    const filterOption = (inputValue: string, option: TransferData) => {
        const title = option.title.toLowerCase();
        const value = inputValue.toLowerCase();

        return title.indexOf(value) > -1;
    };

    return (
        <Wrap>
            <Transfer
                dataSource={items}
                titles={["Все товары", "Связан с"]}
                targetKeys={targetKeys}
                selectedKeys={selectedKeys}
                onChange={handleChange}
                onSelectChange={handleSelectChange}
                onSearch={handleSearch}
                filterOption={filterOption}
                oneWay
                showSearch
                render={(item) => {
                    return (
                        <Selector>
                            <SelectorImage src={item.description} alt="" />
                            <SelectorTitle>{item.title}</SelectorTitle>
                        </Selector>
                    );
                }}
                locale={{
                    itemUnit: "кол-во",
                    itemsUnit: "кол-во",
                    selectAll: "Выбрать все",
                    selectInvert: "Инвертировать выбор",
                    removeAll: "Удалить все",
                    searchPlaceholder: "Введите название продукта",
                }}
                style={{marginBottom: 16}}
            />
        </Wrap>
    );
};

export default TransferItems;
