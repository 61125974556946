export const getCurrencySymbol = function (currencyCode: string | null | undefined = 'RUB') {
    switch (currencyCode) {
        case 'EUR':
            return '€';
        case 'USD':
            return '$';
        case 'GBP':
            return '£';
        case 'RUB':
        default:
            return '₽'
    }
}
