import React from "react";
import {Props} from "./TopbarUserShapes";
import {Avatar, Dropdown,Space} from "antd";
import {TeamOutlined} from "@ant-design/icons";
import TopbarUserDropdown from "./TopbarUserDropdown";
import "./TopbarUserStyles.scss";

const TopbarUser: React.FC<Props> = (props) => (
    <Dropdown overlay={<TopbarUserDropdown onLogout={props.onLogout} />}>
        <Space className="topbar-user">
            <Avatar className="topbar-user__image" size="large" icon={<TeamOutlined/>}/>
            <div className="topbar-user__title">
            <span className="topbar-user__name">
                {props.username}
            </span>
                <span className="topbar-user__role">
                {props.company}
            </span>
            </div>
        </Space>
    </Dropdown>
);

export default TopbarUser;