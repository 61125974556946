import {message} from "antd";
import {useCallback} from "react";
import {
    AdditionalTag,
    AdditionalTagEmpty,
} from "../../interfaces/AdditionalTag";
import AdditionalTagModel from "../../models/AdditionalTagModel";

export const useAdditionalTagLoad = (additionalTagId?: AdditionalTag["id"]) => {
    return useCallback(async () => {
        try {
            const item = additionalTagId
                ? await AdditionalTagModel.getById(additionalTagId)
                : await AdditionalTagModel.createBlank();

            return {
                item,
            };
        } catch (err) {
            message.error("При загрузке данных произошла ошибка");
            console.error(err);
        }
    }, [additionalTagId]);
};

export const useAdditionalTagCreate = () => {
    return useCallback(async (additionalTag: AdditionalTagEmpty) => {
        return AdditionalTagModel.create({
            ...additionalTag,
        });
    }, []);
};

export const useAdditionalTagUpdate = () => {
    return useCallback(async (additionalTag: AdditionalTag) => {
        return AdditionalTagModel.update({
            ...additionalTag,
        });
    }, []);
};
