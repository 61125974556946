import {DataNode} from 'antd/lib/tree';
import {CascaderTree} from '../components/Cascader/Cascader-types';
import Filter from '../interfaces/Filter';
import {ProductSaved} from '../interfaces/Product';
import {capitalizeFirstLetter} from './StringUtils';


export const searchParents = (items: Filter[], itemsId: number | null) => {
    const parentsIds: number[] = [];
    let parentId: number | null = itemsId;

    while (parentId) {
        const id = parentId;
        parentsIds.push(id);

        const itemIndex = items.findIndex(item => item.id === id);
        parentId = items[itemIndex]?.parentId;
    }

    return parentsIds;
}

export const searchChildren = (items: Filter[], itemsId: number) => {
    const childrenIds: number[] = [];

    childrenIds.push(itemsId);

    const childrenItems = items.filter(item => item.parentId === itemsId);

    childrenItems.forEach(childrenItem => {
        const itemId = childrenItem.id;

        const resultIds = searchChildren(items, itemId);

        resultIds.forEach(id => {
            childrenIds.push(id);
        });
    });

    return childrenIds;
}

export const checkChildren = (itemsId: number[], itemId: number) => {
    return itemsId.includes(itemId);
}

export const convertFiltersToDataNodes = (items: Filter[], currentItemId: number = 0) => {
    const hashTable: DataNode[] = [];
    const dataTree: DataNode[] = [];

    const childrenIds = searchChildren(items, currentItemId);

    const itemsFilter = currentItemId ? items.filter(item => !checkChildren(childrenIds, item.id)) : items;

    itemsFilter.forEach(aData => hashTable[aData.id] = {
        key: aData.id,
        title: capitalizeFirstLetter(aData.title),
        children: [],
    });

    itemsFilter.forEach(aData => {
        if (aData.parentId) {
            hashTable[aData.parentId].children?.push(hashTable[aData.id]);
        } else {
            dataTree.push(hashTable[aData.id]);
        }
    });

    dataTree.unshift({key: 0, title: "Нет"});

    return dataTree;
}

export const convertFiltersToCascaderTrees = (filters: Filter[]): CascaderTree[] => {
    const hashFilterTrees: CascaderTree[] = [];
    const filterTrees: CascaderTree[] = [];

    filters.sort((filterA, filterB) => filterA.position - filterB.position);

    filters.forEach((item) => hashFilterTrees[item.id] = {
        key: item.id,
        title: capitalizeFirstLetter(item.title),
        children: [],
        isActive: item.isActive,
        position: item.position,
    });

    filters.forEach(aData => {
        if (aData.parentId) {
            if (hashFilterTrees[aData.parentId]) {
                hashFilterTrees[aData.parentId].children?.push(hashFilterTrees[aData.id]);
            } else {
                filterTrees.push(hashFilterTrees[aData.id]);
            }
        } else {
            filterTrees.push(hashFilterTrees[aData.id]);
        }
    });

    return filterTrees;
}

export const findProductFromFilterTree = (filterTrees: CascaderTree[], products: ProductSaved[]) => {
    let resultProducts: ProductSaved[] = [];
    const filterTreesReverse = filterTrees.reverse();

    for (let index = 0; index < filterTreesReverse.length; index++) {
        let currentProducts: ProductSaved[] = [];
        const currentTree = filterTreesReverse[index];

        if (currentTree.children && currentTree.children.length) {
            currentProducts = [...findProductFromFilterTree(currentTree.children, products)];
        }

        const product = products.filter(({filters}) => {
            const hasFilter = filters.some(el => currentTree.key === el.id);

            return hasFilter;
        });

        resultProducts = [...resultProducts, ...currentProducts, ...product];
    }

    return resultProducts;
}

export const getFilterTreesById = (filterTrees: CascaderTree[], idFilterTree: number): CascaderTree[] => {
    let resultProducts: CascaderTree[] = [];

    for (let index = 0; index < filterTrees.length; index++) {
        const currentTree = filterTrees[index];
        const checkId = Number(currentTree.key) === idFilterTree;

        if (checkId) {
            resultProducts.push(currentTree);

            break;
        } else if (currentTree.children?.length) {
            resultProducts = [...getFilterTreesById(currentTree.children, idFilterTree)];

            if (resultProducts.length) {
                break;
            }
        }
    }

    return resultProducts;
}

export const getPathBranchById = (filterTrees: CascaderTree[], idFilterTree: string): string => {
    let resultPath: string = '';

    for (let index = 0; index < filterTrees.length; index++) {
        const currentTree = filterTrees[index];
        const position = String(currentTree.position);
        const checkId = String(currentTree.key) === idFilterTree;

        if (checkId) {
            resultPath = resultPath + position;

            break;
        } else if (currentTree.children?.length) {
            resultPath = resultPath + getPathBranchById(currentTree.children, idFilterTree);

            if (resultPath) {
                resultPath = position + resultPath;

                break;
            }
        }
    }

    return resultPath;
}
