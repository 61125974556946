/**
 * Toggle object property by key and sets value that passed to function argument.
 * Removes key if existed either add it
 * No mutation.
 */
export const objectKeyToggle = <T extends Object>(obj: T, key: keyof T, value: T[keyof T]): T => {
    const data = {...obj};

    if (data.hasOwnProperty(key)) {
        delete data[key];
    } else {
        data[key] = value;
    }

    return data;
};

export const getObjectKeyByValue = (object: {[key: number]: string}, value: string) => {
    return Object.keys(object).find(key => object[Number(key)] === value);
}
