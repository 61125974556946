import {Draggable} from 'react-beautiful-dnd';
import styled from "styled-components";

export const Wrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const BodyDraggable = styled(Draggable)`
    width: 100%;
`;
