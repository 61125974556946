import React from "react";
import {TemplateProps} from "./LookPresetListProps";
import LayoutPage from "../../components/LayoutPage/LayoutPage";
import {Button, Modal, Popconfirm, Space, Table} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import LookPresetBuilder from "../../components/LookPresetBuilder/LookPresetBuilder";
import {stopPropagation} from "../../helpers/ActionUtils";
import {LookPreset} from "../../interfaces/LookPreset";

import "./LookPresetListStyles.scss";

const LookPresetListTemplate: React.FC<TemplateProps> = (props) => (
    <LayoutPage className="look-preset-list" title="Пресеты образов" extra={
        <Button icon={<PlusCircleOutlined/>} type="primary" onClick={props.onItemBuilderNew}>Создать новый пресет</Button>
    }>

        <Table dataSource={props.items} rowKey="id" pagination={false}>
            <Table.Column title="" dataIndex="title" key="title" width={40} render={(dumb: any,item: LookPreset) => (
                item.isDefault && <span className="look-preset-list__default" />
            )}/>
            <Table.Column title="Название" dataIndex="title" key="title" width={350} render={(dumb: any,item: LookPreset) => (
                <strong>{item.title}</strong>
            )}/>
            <Table.Column title="Блоки" dataIndex="blocks" key="blocks" width="50%" render={(dumb: any, item: LookPreset) => (
                <div className="look-preset-list__filters">
                    {
                        Object.values(item.blocks).map(block => (
                            <div className="look-preset-list__filter-item" key={block.sectionId}>
                                <span data-block-id={block.sectionId}/>
                                {block.filter.title}
                            </div>
                        ))
                    }
                </div>
            )}/>
            <Table.Column title="" key="actions" render={(dumb: any, item: LookPreset) => (
                <Space>
                    <Button type="link" onClick={props.onItemBuilderEdit} data-item-id={item.id}>Редактировать</Button>

                    <Popconfirm title="Вы точно хотите удалить?" onConfirm={props.onItemRemove.bind(null, item.id)} onCancel={stopPropagation}>
                        <Button type="link" style={{color: "red"}} onClick={stopPropagation}>Удалить</Button>
                    </Popconfirm>
                </Space>
            )}/>
        </Table>

        <Modal title={props.editId ? "Редактирование пресета" : "Создание нового пресета"}
               visible={props.isItemBuilder}
               onCancel={props.onItemBuilderClose}
               width="800px"
               destroyOnClose
               bodyStyle={{padding: 0}}
               footer={null}>
                <LookPresetBuilder id={props.editId}
                                   onAfterSave={props.onItemBuilderSaved}/>

        </Modal>

    </LayoutPage>
);

export default LookPresetListTemplate;