import React, {FC} from "react";
import {CheckableTagProps} from "antd/lib/tag";
import {Tag} from "antd";
import "./TagCheckableStyles.scss";

const TagCheckable: FC<CheckableTagProps> = (props) => (
    <Tag.CheckableTag {...props} className="tag-checkable"/>
);

export default TagCheckable;
