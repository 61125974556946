import {CascaderTree} from '../Cascader-types';


export const changePosition = (value: CascaderTree, index: number): CascaderTree => {
    return (
        {
            ...value,
            position: index
        }
    );
}
