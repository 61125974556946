import BaseModel from "./BaseModel";
import ENDPOINTS from "../constants/Endpoints";
import {LookPreset, LookPresetEmpty} from "../interfaces/LookPreset";


class LookPresetModel extends BaseModel<LookPreset, LookPresetEmpty> {

    getInstanceId(item: LookPreset): number | null {
        return item.id;
    }

    public createBlank = async (): Promise<LookPresetEmpty> => {
        const item: LookPresetEmpty = {
            title: "",
            blocks: {},
            isDefault: false,
        };

        return Promise.resolve(item);
    }

}

export default new LookPresetModel(ENDPOINTS.lookPreset);