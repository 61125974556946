import ENDPOINTS from "../constants/Endpoints";
import {Currency} from "../interfaces/Currency";
import ApiService from "./ApiService";


class CurrencyService extends ApiService {
    getCurrencyRUB = async () => {
        //TODO: заменить на axios (через axios выдает CORS error)
        // const response: Currency = await this.get(ENDPOINTS.currencyRUB);
        const response: Currency = await fetch(ENDPOINTS.currencyRUB).then(currency => currency.json());

        if (response) {
            return response;
        } else {
            throw new Error("Incorrect currency RUB");
        }
    };
}

export default new CurrencyService();