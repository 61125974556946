
import Filter from "../../interfaces/Filter";
import {ProductSaved} from "../../interfaces/Product";
import Channel from "../../interfaces/Channel";
import {RouteComponentProps} from "react-router-dom";
import {OnlineStoreUrl} from "../../interfaces/OnlineStore/OnlineStoreUrl";
import {HandlerSelectorChange} from "../../interfaces/Selector";
import {CheckboxCheck} from "../../interfaces/Checkbox";
import {AdditionalTag} from "../../interfaces/AdditionalTag";
import {Color} from "../../interfaces/Color";
import {Currency} from "../../interfaces/Currency";
import {DatePickerProps} from "antd/lib/date-picker";
import {Season} from "../../interfaces/Season";
import {Gender, HandlerGenderChange} from "../../interfaces/Gender";

interface MatchParams {
    id: string,
}

export enum PriceOptions {
    default = "по умолчанию",
    ascending = "по возрастанию",
    descending = "по убыванию",
}

export interface HandlerPriseSelectorChange {
    (value: PriceOptions): void
}

export interface Props extends RouteComponentProps<MatchParams> {
    isArchive?: boolean,
}

export interface State {
    products: ProductSaved[],
    filters: Filter[],
    filtersVisible: {[key: number]: string},
    selectedFilters: {[key: number]: string},
    channels: Channel[],
    channelSelected: 'all' | 'empty' | number,
    channelSelectVisible: boolean,
    channelToSend: string | undefined,
    isSending: boolean,
    isBest?: boolean,
    selectedYear?: number | null,
    shopList?: typeof OnlineStoreUrl,
    shopSelected?: string | null,
    additionalTags?: AdditionalTag[],
    additionalTagSelected?: string | null,
    colorList?: Color[],
    colorSelected?: string | null,
    seasonSelected?: Season,
    currencyRUB?: Currency,
    priceOptions?: string[],
    priceOptionsSelected?: PriceOptions,
    page: number,
    pageSize: number,
    genderSelected?: Gender,
}

export interface TemplateProps extends State {
    isArchive: boolean,
    title?: string,
    productId?: string,
    productUrl: string,
    productsTotal: number,
    onFilterClick: (filter: Filter, checked: boolean) => void,
    onChannelClick: (filter: State['channelSelected']) => void,
    onProductRemove: (item: ProductSaved) => void,
    onProductsSend: (channelId: string, productFrom: number | null, productTo: number | null) => void,
    onChannelSelectOpen: () => void,
    onChannelSelectClose: () => void,
    onChangeSelectedFilters: (filterIds: number[]) => void,
    onBestChange?: CheckboxCheck,
    onDateChange?: DatePickerProps["onChange"],
    onShopSelectedChange?: HandlerSelectorChange,
    onAdditionalTagSelectedChange?: HandlerSelectorChange,
    onColorsSelectedChange?: HandlerSelectorChange,
    onPriceOptionsSelectedChange?: HandlerPriseSelectorChange,
    onSeasonSelectedChange?: HandlerSelectorChange,
    onGenderSelectedChange?: HandlerGenderChange,

    onProductClose: () => void,
    onProductSaved: (product: ProductSaved) => void,

    pageStartIndex: number,
    onPageChange: (page: number) => void,
}
