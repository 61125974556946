import React from "react";
import {Select} from "antd";
import {CloseOutlined as Icon} from "@ant-design/icons";
import {LookDropdownProps} from "./LookDropdownProps";
import {FilterSelectable} from "../../interfaces/FilterSelectable";

const {Option} = Select;

const LookOption = (blockId: string, value: string | number, label: string, id: number, isDisabled: boolean) => (
    <Option value={value} key={id} data-block-id={blockId} disabled={isDisabled}>
        {label}
    </Option>
)

const LookDropdown: React.FC<LookDropdownProps> = (props) => (
    <div className={props.className}>
        <Select
            onChange={props.onChange}
            value={props.value || ""}
            showArrow={props.value === ""}
            disabled={props.disabled || false}
            clearIcon={<Icon className="look-builder__clear" onClick={props.onClear} data-block-id={props.blockId}/>}
            allowClear={!!props.value}>
            {
                props.value === "" &&
                LookOption(props.blockId, "", "Не выбрано", 0, true)
            }
            {
                props.filters.map((el: FilterSelectable) => (
                    el.blocks && el.blocks.includes(Number(props.blockId))
                        ? LookOption(props.blockId, el.id, el.title, el.id, el.isSelected)
                        : null
                ))
            }
        </Select>
    </div>
);

export default LookDropdown;