import React from "react";
import {Props} from "./TopbarMenuShapes";
import {Menu} from "antd";
import {NavLink} from "../Routing";

const TopbarMenu: React.FC<Props> = (props) => (
    <Menu {...props} theme="dark" mode="horizontal" defaultSelectedKeys={['/']}>
        {
            props.items &&
            props.items.map((item) => (
                <Menu.Item key={item.url}>
                    <NavLink to={item.url}>{item.title}</NavLink>
                </Menu.Item>
            ))
        }
    </Menu>
);

export default TopbarMenu;