import BaseModel from "./BaseModel";
import ENDPOINTS from "../constants/Endpoints";
import Filter, {FilterEmpty} from "../interfaces/Filter";
import {SYSTEM_PER_PAGE} from "../constants/SystemConfig";
import RequestOptions from "../interfaces/RequestOptions";


class FilterModel extends BaseModel<Filter, FilterEmpty> {

    getInstanceId(item: Filter): number | null {
        return item.id;
    }

    public createBlank = async (): Promise<FilterEmpty> => {
        const item: FilterEmpty = {
            title: '',
            blocks: [],
            parentId: null,
            position: 0,
            isActive: true,
            isDeleted: false,
            products: [],
            channels: [],
        }
        return Promise.resolve(item)
            .then(result => this.enrichItem<FilterEmpty>(result));
    }

    public getAllActive = async (options: RequestOptions = {}): Promise<Filter[]> => {
        return this.getAll(1, SYSTEM_PER_PAGE, options)
            .then(items => items.filter(el => el.isActive));
    }

    public enrichList = (items: Filter[]) => {
        return items
            .filter(item => !item.isDeleted)
            .map(item => this.enrichItem<Filter>(item));
    }

    public prepareForSaveList = (items: Filter[]) => {
        return items.map(item => this.prepareForSave(item));
    }

    public updateList = async (items: Filter[]): Promise<any> => {
        const url = `${this.endpointList}/update-list`;

        const itemsPrepared = this.prepareForSaveList(items);

        return this.put<Filter[]>(url, itemsPrepared);
    }

}

export default new FilterModel(ENDPOINTS.filter);
