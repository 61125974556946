import {MouseEventHandler, PureComponent} from "react";
import {Props, State} from "./LookPresetListProps";
import Template from "./LookPresetListTemplate";
import LookPresetModel from "../../models/LookPresetModel";
import {LookPreset} from "../../interfaces/LookPreset";
import {message} from "antd";

export class LookPresetList extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            items: [],
            editId: null,
            isItemBuilder: false,
        };
    }

    componentDidMount() {
        this.itemsInit().then();
    }

    itemsInit = async () => {
        const items = await LookPresetModel.getAll();
        this.setState({items});
    };

    handleItemBuilderNew = () => {
        this.setState({editId: null, isItemBuilder: true});
    };

    handleItemBuilderEdit: MouseEventHandler<HTMLButtonElement> = (e) => {
        const itemId = e.currentTarget.dataset.hasOwnProperty('itemId')
            ? Number(e.currentTarget.dataset.itemId)
            : null;

        this.setState({editId: itemId, isItemBuilder: true});
    };

    handleItemBuilderClose = () => {
        this.setState({editId: null, isItemBuilder: false});
    }

    handleItemBuilderSaved = (item: LookPreset) => {
        const items = [...this.state.items];
        const index = this.state.items.findIndex(el => el.id === item.id);

        switch (true) {
            case (index >= 0 && item.isDefault):
                items.forEach((el, elIndex) => {
                    items[elIndex].isDefault = index === elIndex;
                });
                break;
            case (index >= 0 && !item.isDefault):
                items.splice(index, 1, item)
                break;
            default:
                items.unshift(item);
                break;
        }

        this.setState({editId: null, isItemBuilder: false, items});
    }

    handleItemRemove = async (itemId: LookPreset['id']) => {
        try {
            const index = this.state.items.findIndex(el => el.id === itemId);
            if (index >= 0) {
                const items = [...this.state.items];
                const title = items[index].title;
                await LookPresetModel.remove(itemId);

                items.splice(index, 1);
                this.setState({items}, () => {
                    message.success(`Пресет "${title}" успешно удален`);
                });


            } else {
                console.error(`No look preset with id ${itemId}`);
                message.error(`Пресет с ID: ${itemId} на странице не найден, попробуйте перезагрузить страницу!`);
            }
        } catch (err) {
            console.error('Error ocured during look preset removing: ', err);
            message.error('При удалении пресета возникла ошибка!');
        }
    };




    render() {
        return Template({
            ...this.state,
            onItemRemove: this.handleItemRemove,
            onItemBuilderNew: this.handleItemBuilderNew,
            onItemBuilderEdit: this.handleItemBuilderEdit,
            onItemBuilderClose: this.handleItemBuilderClose,
            onItemBuilderSaved: this.handleItemBuilderSaved,
        });
    };
}

export default LookPresetList;