import ENDPOINTS from "../constants/Endpoints";
import {SYSTEM_PER_PAGE} from "../constants/SystemConfig";
import {Color, ColorEmpty} from "../interfaces/Color";
import RequestOptions from "../interfaces/RequestOptions";
import BaseModel from "./BaseModel";

class ColorModel extends BaseModel<Color, ColorEmpty> {
    getInstanceId(item: Color): number | null {
        return item.id;
    }

    public createBlank = async (): Promise<ColorEmpty> => {
        const item: ColorEmpty = {
            title: "",
            isActive: true,
            isDeleted: false,
        };

        return Promise.resolve(item).then((result) =>
            this.enrichItem<ColorEmpty>(result)
        );
    };

    public getAllActive = async (options: RequestOptions = {}): Promise<Color[]> => {
        return this.getAll(1, SYSTEM_PER_PAGE, options).then((items) =>
            items.filter((el) => el.isActive)
        );
    };
}

export default new ColorModel(ENDPOINTS.color);