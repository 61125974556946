import React, {useEffect} from "react";
import {TemplateProps} from "./DrawerPanelShapes";
import {Drawer} from "antd";
import "./DrawerPanelStyles.scss";

const DrawerPanel: React.FC<TemplateProps> = (props) => {
    const {children, ...rest} = props;

    useEffect(() => {
        const checkDocument = typeof window != "undefined" && window.document;

        if (rest.visible) {
            if (checkDocument) {
                const hasVerticalScrollbar =
                    document.body.scrollHeight > document.body.clientHeight;

                document.body.style.overflow = "hidden";

                document.body.style.width = hasVerticalScrollbar
                    ? "calc(100% - 17px)"
                    : "100%";
            }
        } else {
            if (checkDocument) {
                document.body.style.overflow = "auto";
                document.body.style.width = "100%";
            }
        }
    }, [rest]);

    return (
        <Drawer {...rest} width={rest.width || "650px"}>
            {children}
        </Drawer>
    );
};

export default DrawerPanel;
