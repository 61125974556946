import styled, {css} from "styled-components";
import {IsTelegram} from "../../interfaces/BaseTypes";

export const imageBorder = "#d9d9d9";
export const colorThemeActive = "#1890ff";

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

export const Item = styled.div<IsTelegram>`
    position: relative;
    flex: 0 0 calc(100% / 6 - 20px);
    padding-top: 15%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 20px;
    border: 1px solid ${imageBorder};
    transition: box-shadow 0.3s;

    &:hover {
        cursor: pointer;
        -webkit-box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09) !important;
        box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09) !important;
    }

    &:before {
        content: "";
        position: absolute;
        bottom: -8px;
        left: -8px;
        width: 25px;
        height: 25px;
        opacity: 0.00001;
        z-index: 10;
        transition: opacity 0.3s;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 240 240'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='.667' x2='.417' y1='.167' y2='.75'%3E%3Cstop offset='0' stop-color='%2337aee2'/%3E%3Cstop offset='1' stop-color='%231e96c8'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' x1='.66' x2='.851' y1='.437' y2='.802'%3E%3Cstop offset='0' stop-color='%23eff7fc'/%3E%3Cstop offset='1' stop-color='%23fff'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ccircle cx='120' cy='120' r='120' fill='url(%23a)'/%3E%3Cpath fill='%23c8daea' d='M98 175c-3.888 0-3.227-1.468-4.568-5.17L82 132.207 170 80'/%3E%3Cpath fill='%23a9c9dd' d='M98 175c3 0 4.325-1.372 6-3l16-15.558-19.958-12.035'/%3E%3Cpath fill='url(%23b)' d='M100.04 144.41l48.36 35.729c5.519 3.045 9.501 1.468 10.876-5.123l19.685-92.763c2.015-8.08-3.08-11.746-8.36-9.349l-115.59 44.571c-7.89 3.165-7.843 7.567-1.438 9.528l29.663 9.259 68.673-43.325c3.242-1.966 6.218-.91 3.776 1.258'/%3E%3C/svg%3E");
    }

    ${(props) => props.IsTelegram && css`
        opacity: 1;
    `}
`;

export const ItemLoading = styled.div`
    position: absolute;
    top: 50%;
    left: 20px;
    right: 20px;
    height: 12px;
    z-index: 10;
    display: block;
    float: left;
    box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.1);
    transition: width 0.8s ease-in-out;
    transform: translateY(-50%);
    background: ${colorThemeActive} linear-gradient(
                    -45deg,
                    rgba(255, 255, 255, 0.75) 25%,
                    transparent 25%,
                    transparent 50%,
                    rgba(255, 255, 255, 0.75) 50%,
                    rgba(255, 255, 255, 0.75) 75%,
                    transparent 75%,
                    transparent
                );
    background-size: 35px 35px;
    animation: cssProgressActive 2s linear infinite;
`;

export const ItemImage = styled.div`
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #fff;

    ${ItemLoading} ~ & {
        filter: grayscale(0.4) blur(3px);
    }
`;

export const ItemRemove = styled.div`
    opacity: 0.8;
    position: absolute !important;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px !important;
    border-radius: 50% !important;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    padding: 0 !important;
    line-height: 20px !important;
    color: #fff;
    font-weight: 500;
    text-align: center;
    transition: opacity 0.4s;
    cursor: pointer;

    svg {
        width: 100%;
        height: 100%;
    }

    ${ItemImage}:hover & {
        opacity: 0.8;
    }

    &:hover {
        opacity: 1 !important;
    }
`;

