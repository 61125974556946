import {PureComponent} from "react";
import LookCreateTemplate from "./LookCreateTemplate";
import ROUTES from "../../constants/Routes";
import {Props, State} from "./LookCreateShapes";
import {Look} from "../../interfaces/Look";


class LookCreate extends PureComponent<Props, State> {

    handleAfterSave = (item: Look) => {
        this.props.history.push(ROUTES.looks);
    }

    render() {
        return LookCreateTemplate({
            ...this.state,
            onAfterSave: this.handleAfterSave,
        });
    };
}


export default LookCreate;