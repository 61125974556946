import {PureComponent} from "react";
import {Props, State} from "./ChannelSelectModalShapes";
import Template from "./ChannelSelectModalTemplate";
import {message} from "antd";

export class ChannelSelectModal extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            channelId: undefined,
            productFrom: '1',
            productTo: props.productsCount?.toString() || '',
        };
    }


    componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.props.productsCount !== prevProps.productsCount) {
            this.setState(state => ({
                ...state,
                productFrom: '1',
                productTo: this.props.productsCount?.toString() || '',
            }))
        }
    }

    productCountChange = (e: any) => {
        const name: 'productFrom' | 'productTo' = e.currentTarget.name;
        let value = e.currentTarget.value;

        const isValid = value === '' || Number(value);
        const isLimited = isValid && this.props.productsCount && Number(value) > this.props.productsCount;

        if (isValid && !isLimited) {
            this.setState(state => ({...state, [name]: value}));
        }
    }

    channelSelected = (channelId: string) => {
        this.setState(state => ({...state, channelId}));
    }

    submit = () => {

        if (!this.state.channelId) {
            message.warn('Не выбран канал для отправки');
            return;
        }

        const productFrom = this.state.productFrom ? Number(this.state.productFrom) : null;
        const productTo = this.state.productTo ? Number(this.state.productTo) : null;

        if (productFrom && productTo && productFrom > productTo) {
            message.warn('Значение "ОТ" должно быть меньше, чем "ДО"');
            return;
        }

        this.props.onSubmit(this.state.channelId, productFrom, productTo);

    };

    render() {
        return Template({
            ...this.props,
            ...this.state,
            onOk: this.submit,
            onChannelSelected: this.channelSelected,
            onProductCountChange: this.productCountChange,
        });
    };
}

export default ChannelSelectModal;