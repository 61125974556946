import {message} from "antd";
import {useCallback} from "react";
import {Color, ColorEmpty} from "../../interfaces/Color";
import ColorModel from "../../models/ColorModel";


export const useColorLoad = (colorId?: Color["id"]) => {
    return useCallback(async () => {
        try {
            const item = colorId
                ? await ColorModel.getById(colorId)
                : await ColorModel.createBlank();

            return {
                item,
            };
        } catch (err) {
            message.error("При загрузке данных произошла ошибка");
            console.error(err);
        }
    }, [colorId]);
};

export const useColorCreate = () => {
    return useCallback(async (colorTag: ColorEmpty) => {
        return ColorModel.create({
            ...colorTag,
        });
    }, []);
};

export const useColorUpdate = () => {
    return useCallback(async (color: Color) => {
        return ColorModel.update({
            ...color,
        });
    }, []);
};