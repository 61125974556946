import React from "react";
import {TemplateProps} from "./UploadDropzoneProps";
import ProductGallery from "../ProductGallery/ProductGallery";
import IconUpload from "../../assets/svg/icon-upload.svg";

import "./UploadDropzoneStyles.scss";


const UploadDropzoneTemplate: React.FC<TemplateProps> = (props) => (
    <div className="upload-dropzone" data-drag-over={props.isOver || false}>

        <label
            className="upload-dropzone__body"
            onDragOver={props.onDragOver}
            onDragLeave={props.onDragLeave}
            onDrop={props.onFilesDrop}>
            {
                props.images &&
                props.images.length > 0 ? (
                    <div className="upload-dropzone__items" onDrop={props.onDragBlock}>
                        <ProductGallery images={props.images}
                                        onImageRemove={props.onImageRemove}
                                        onImageSelect={props.onImageSelect}
                                        onUpdatePosition={props.onUpdatePosition}/>
                    </div>
                ) : (
                    <div className="upload-dropzone__empty" onDrop={props.onDragBlock}>
                        <div className="upload-dropzone__empty-icon">
                            <img src={IconUpload} alt=""/>
                        </div>
                        <div className="upload-dropzone__empty-text">
                            Перетащите файлы сюда или кликните, чтобы загрузить
                        </div>
                        <div className="upload-dropzone__empty-message">
                            Можно загрузить сразу несколько файлов
                        </div>
                    </div>
                )
            }


            <input className="upload-dropzone__files" type="file" multiple onChange={props.onFilesPick}/>
        </label>


    </div>
);

export default UploadDropzoneTemplate;