import {message} from "antd";
import Channel from '../interfaces/Channel';
import {ChannelSelected} from '../interfaces/ChannelSelected';
import {Product, ProductSaved} from "../interfaces/Product";
import {TransferData} from "../interfaces/TransferData";
import ProductModel from "../models/ProductModel";


export const validateBeforeSendToTelegram = (product: ProductSaved): boolean => {
    let isValid = true;

    const emptyImages = product.images.filter(image => image.toTelegram).length < 1;
    const emptyPrice = !product?.price || Number(product.price) < 0;
    const emptyTitle = !product?.title;

    if (emptyImages) {
        message.warn('Выбрано недостаточно изображений');
        isValid = false;
    }

    if (isValid && emptyPrice) {
        message.warn('Заполните цену');
        isValid = false;
    }

    if (isValid && emptyTitle) {
        message.warn('Заполните назвыание');
        isValid = false;
    }

    return isValid;
}

export const chanelCheck = (channelSelected: number | "all" | "empty", channels: Channel[]) => {
    return channelSelected === ChannelSelected.empty
        ? channels.length === 0
        : channels.some(el => channelSelected === el.id);
}

export const productsLoadFromServerUtil = async (archived: boolean = false) => {
    try {
        if (archived) {
            return await ProductModel.getAllArchived();
        }

        const products = await ProductModel.getAll();

        return productsFilterForList(products);
    } catch (err) {
        console.error(err)
    }
}

export const productsFilterForList = (products: ProductSaved[]): ProductSaved[] => {
    return products
        .filter(item => item.channels.some(el => el.isActive) || item.channels.length === 0)
}

export const productConvertForTransferData = (product: ProductSaved): TransferData => {
    return ({
        key: String(product.id),
        description: product.images.length ? product.images[0].link : "#",
        title: product.title,
        disabled: false,
    });
};

export const productFollowerFound = (productsIds: string[], products: Product[]) => {
    return productsIds?.reduce((accumulatorProducts: Product[], currentProductId) => {
        const newProductFollower = products.find(product => currentProductId === String(product?.id));

        if (newProductFollower) {
            accumulatorProducts.push(newProductFollower);
        }

        return accumulatorProducts;
    }, []);
}
