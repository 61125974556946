import Url from "url-parse";
import {isOnlineStoreName, OnlineStoreName} from "../interfaces/OnlineStore/OnlineStoreName";
import {OnlineStoreUrl} from "../interfaces/OnlineStore/OnlineStoreUrl";

export const DetectShopByLink = (link: string): OnlineStoreName | null => {
    const stores = Object.keys(OnlineStoreUrl);
    const hostname = new Url(link).hostname;

    for (const name of stores) {
        if (isOnlineStoreName(name) && OnlineStoreUrl[name].includes(hostname)) {
            return name;
        }
    }

    return null;
}
