import {PureComponent} from "react";
import {Props, State} from "./LookPresetBuilderProps";
import Template from "./LookPresetBuilderTemplate";
import LookModel from "../../models/LookModel";
import {LookBuilderBlock} from "../../interfaces/LookBuilderBlock";
import {FilterSelectable} from "../../interfaces/FilterSelectable";
import {message} from "antd";
import LookPresetModel from "../../models/LookPresetModel";
import {LookPresetBlock} from "../../interfaces/LookPresetBlock";
import HandlerTextInputChange from "../../interfaces/HandlerTextInputChange";
import {LookPreset} from "../../interfaces/LookPreset";
import {LookBuilderFilterChange, LookBuilderFilterClear} from "../LookBuilder/LookBuilderHandlers";

export class LookPresetBuilder extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            title: "",
            blocks: {},
            filters: [],
            isDefault: false,
        };
    }

    componentDidMount() {
        this.filtersInit().then();
        this.itemInit().then();
    }

    getId = (): LookPreset['id'] | null => {
        return this.props.id || null;
    };

    filtersInit = async () => {
        const filters = await LookModel.getLookFilters();

        this.setState((state) => ({...state, filters}));
    }


    itemInit = async () => {
        try {
            const itemId = this.getId();
            const item = itemId
                ? await LookPresetModel.getById(itemId)
                : await LookPresetModel.createBlank()

            this.setState({title: item.title, blocks: item.blocks, isDefault: item.isDefault});
        } catch (err) {
            console.error('Error while look preset loading.');
            message.error('При загрузке данных произошла ошибка.');
        }
    };


    calculateSelectedFilters = (blocks: (LookBuilderBlock | null)[]): FilterSelectable[] => {
        const filtersSelected = blocks.map(el => el && el.filter ? el.filter.id : null);
        return this.state.filters.map(el => ({...el, isSelected: filtersSelected.includes(el.id)}));
    }

    handleTitleChange: HandlerTextInputChange = (e) => {
        const title = e.currentTarget.value;
        this.setState({title})
    }

    handleDefaultChange = () => {
        this.setState({isDefault: !this.state.isDefault});
    };

    handleFilterChange: LookBuilderFilterChange = (value, data: any) => {
        if (value && data) {
            const sectionId = data['data-block-id'] as string;
            const filterIndex = this.state.filters.findIndex(el => el.id === value);

            if (filterIndex >= 0) {

                const filter = {...this.state.filters[filterIndex]};

                const block: LookPresetBlock = this.state.blocks.hasOwnProperty(sectionId)
                    ? {...this.state.blocks[sectionId], filter}
                    : {sectionId, filter};

                const blocks = {...this.state.blocks, [sectionId]: block};
                const filters = this.calculateSelectedFilters(Object.values(blocks));

                this.setState((state) => ({...state, blocks, filters}));
            }
        }
    };

    handleFilterClear: LookBuilderFilterClear = (e) => {
        const blockId = e.currentTarget.dataset.blockId || null;
        const blocks = {...this.state.blocks};

        if (blockId) {
            delete blocks[blockId];

            const filters = this.calculateSelectedFilters(Object.values(blocks));

            this.setState((state) => ({...state, blocks, filters}));
        }
    };


    handleSave = async () => {
        if (!this.validate()) {
            return;
        }

        try {
            const itemId = this.getId();
            let item: LookPreset | null = null;


            if (itemId) {
                const lookPreset = await LookPresetModel.getById(itemId);
                lookPreset.title = this.state.title;
                lookPreset.blocks = {...this.state.blocks};
                lookPreset.isDefault = this.state.isDefault;

                item = await LookPresetModel.update(lookPreset);
            } else {
                const lookPreset = await LookPresetModel.createBlank();
                lookPreset.title = this.state.title;
                lookPreset.blocks = {...this.state.blocks};

                item = await LookPresetModel.create(lookPreset);
            }

            message.success('Пресет успешно сохранен');

            this.props.onAfterSave &&
            this.props.onAfterSave(item);

        } catch (err) {
            message.error('При сохранении пресета произошла ошибка!')
        }
    };

    validate = (): boolean => {
        const blocks = Object.keys(this.state.blocks);

        if (blocks.length <= 0) {
            message.warn('Необходимо выбрать хотя бы один фильтр для создания пресета');
            return false
        }

        if (!this.state.title) {
            message.warn('Необходимо ввести название пресета');
            return false;
        }

        return true;
    };


    render() {
        return Template({
            ...this.state,
            onSave: this.handleSave,
            onTitleChange: this.handleTitleChange,
            onFilterChange: this.handleFilterChange,
            onFilterClear: this.handleFilterClear,
            onDefaultChange: this.handleDefaultChange,
        });
    };
}

export default LookPresetBuilder;