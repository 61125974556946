import React, {FC, memo} from 'react';

import {Wrap, Handler, DragIcon, Body} from './CascaderDragItem-styles';
import {CascaderDragItemProps} from './CascaderDragItem-types';


const CascaderDragItem: FC<CascaderDragItemProps> = (props) => {
    const {children, isDisabled, draggableProps, dragHandleProps, innerRef} = props;

    return (
        <Wrap {...draggableProps} ref={innerRef} isDisabled={isDisabled}>
            <Handler {...dragHandleProps}>
                <DragIcon />
            </Handler>
            <Body>
                {children}
            </Body>
        </Wrap>
    )
};

export default memo(CascaderDragItem);
