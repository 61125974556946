import React from "react";
import {TemplateProps} from "./ChannelSelectModalShapes";
import {Input, Modal, Select} from "antd";
import ChannelCard from "../ChannelCard/ChannelCard";
import './ChannelSelectModalStyles.scss';

const ChannelSelectModalTemplate: React.FC<TemplateProps> = ({channels, isSending, ...props}) => (
    <Modal {...props} centered
           destroyOnClose={true}
           okText={isSending ? 'Отправка...' : 'Отправить'}
           cancelText="Отмена"
           confirmLoading={isSending}
           title="Выберите канал для отправки">

        <Select className="products-list__channel-select"
                onChange={props.onChannelSelected}
                placeholder="выберите канал"
                optionLabelProp="label"
                value={props.channelId}
                disabled={isSending}>
            {
                channels &&
                channels
                    .filter(channel => channel.isActive)
                    .map(channel => (
                        <Select.Option value={channel.cid} label={channel.title} key={channel.cid}>
                            <ChannelCard item={channel}/>
                        </Select.Option>
                    ))
            }
        </Select>

        {
            props.productsCount &&
            <div className="channel-select-modal__limits">

                <div className="channel-select-modal__limits-head">
                    Отправить товары
                </div>
                <div className="channel-select-modal__limits-body">

                    <Input className="channel-select-modal__limits-from"
                           name="productFrom"
                           value={props.productFrom?.toString()}
                           onChange={props.onProductCountChange}
                           addonBefore="min: 1"/>

                    <span className="channel-select-modal__limits-separator"> — </span>

                    <Input className="channel-select-modal__limits-to"
                           name="productTo"
                           value={props.productTo?.toString()}
                           onChange={props.onProductCountChange}
                           addonAfter={`max: ${props.productsCount}`}/>

                </div>

            </div>
        }

    </Modal>
);

export default ChannelSelectModalTemplate;