import {useCallback} from "react";
import FilterModel from "../../models/FilterModel";
import {message} from "antd";
import Filter, {FilterEmpty} from "../../interfaces/Filter";
import ChannelModel from "../../models/ChannelModel";
import {SelectedLookBlocks, SelectedChannels} from "./FilterEditor-types";


export const useFilterLoad = (filterId?: Filter['id']) => {
    return useCallback(async () => {
        try {
            const item = filterId
                ? await FilterModel.getById(filterId)
                : await FilterModel.createBlank();

            const selectedLookBlocks = item.blocks.reduce<SelectedLookBlocks>((total, blockId) => {
                total[blockId] = '';
                return total;
            }, {});

            const selectedChannels = item.channels.reduce<SelectedChannels>((total, channel) => {
                total[channel.id] = channel;
                return total;
            }, {});

            return {
                item,
                selectedLookBlocks,
                selectedChannels,
            }
        } catch (err) {
            message.error('При загрузке данных произошла ошибка');
            console.error(err);
        }
    }, [filterId])
};

export const useChannelsLoad = () => {
    return useCallback(async () => {
        try {
            const channels = await ChannelModel.getAll();

            return channels.filter(el => el.isActive);
        } catch (err) {
            console.error('При загрузке telegram-каналов произошла ошибка: ' + err);
        }
    }, [])
};

export const useFilterCreate = () => {
    return useCallback(async (filter: FilterEmpty, selectedChannels: SelectedChannels, selectedLookBlocks: SelectedLookBlocks) => {
        return FilterModel.create({
            ...filter,
            blocks: Object.keys(selectedLookBlocks).map(el => Number(el)),
            channels: Object.values(selectedChannels),
        })
    }, [])
};

export const useFilterUpdate = () => {
    return useCallback(async (filter: Filter, selectedChannels: SelectedChannels, selectedLookBlocks: SelectedLookBlocks) => {
        return FilterModel.update({
            ...filter,
            blocks: Object.keys(selectedLookBlocks).map(el => Number(el)),
            channels: Object.values(selectedChannels),
        })
    }, [])
};
