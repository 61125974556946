class TokenService {

    protected storageItem = 'ORLTOK';

    public getToken = (): string | null => {
        return localStorage.getItem(this.storageItem) || null;
    };

    public setToken = (token: string) => {
        localStorage.setItem(this.storageItem, token);
    };

    public removeToken = () => {
        localStorage.removeItem(this.storageItem);
    };
}

export default new TokenService();