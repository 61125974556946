import {Season} from "../interfaces/Season";

export const getSeasonsOptions = () => {
    return Object.values(Season).map((season) => ({
        label: getSeasonLabel(season),
        value: season,
    }));
}

export const getSeasonLabel = (season: Season): string | null => {
    const labels = {
        [Season.winter]: 'зима',
        [Season.spring]: 'весна',
        [Season.summer]: 'лето',
        [Season.autumn]: 'осень',
    };

    return labels[season] || null;
}
