import {Look} from "../interfaces/Look";
import {LookBlock} from "../interfaces/LookBlock";

export const lookTotalCount = (look: Look): number => {
    return Object.values(look.blocks).reduce((total: number, block: LookBlock) => {
        const price: number = block.product
            ? Number(block.product.priceDiscount) || Number(block.product.price) || 0
            : 0;

        return total + price;
    }, 0);
}