import axios, {AxiosError, AxiosRequestConfig, AxiosResponse, Method} from 'axios';
import TokenService from "./TokenService";

interface ApiHeaders {
    Authorization?: string,
}

class ApiService {

    protected instance = axios;
    protected cancelToken = axios.CancelToken;

    private request = <T>(method: Method, url: string, config?: AxiosRequestConfig, data?: any): Promise<T> => {

        const token = TokenService.getToken();

        const headers: ApiHeaders = {
            Authorization: token ? `Bearer ${token}` : undefined,
        };

        return axios({method, url, data, headers, ...config})
            .then((response: AxiosResponse) => this.processAuthToken(response))
            .then((response: AxiosResponse) => response.data)
            .catch((response: AxiosError) => this.errorHandler(response))
            .catch((response: AxiosError) => this.errorUnuathorized(response))
    };

    private processAuthToken = (response:AxiosResponse) => {
        if (response.headers.hasOwnProperty('authorization')) {
            const header = response.headers.authorization;
            if (header.includes('Token ')) {
                TokenService.setToken(header.replace('Token ', ''));
            } else {
                throw new Error('Token is not correct');
            }
        }
        return response;
    }

    public isCancel = (err: any) => {
        return axios.isCancel(err);
    };

    protected get = <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
        return this.request('get', url, config);
    };

    protected post = <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> => {
        return this.request('post', url, config, data);
    };

    protected put = <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> => {
        return this.request('put', url, config, data);
    };

    protected delete = <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
        return this.request('delete', url, config);
    };

    private errorHandler = (error: AxiosError): void => {
        console.error(`Request Catch. Message: ${error.message}`);
        throw error;
    };

    private errorUnuathorized = (error: AxiosError): void => {
        throw error;
    }

}

export default ApiService;