import React from "react";
import {TemplateProps} from "./DraftsListShapes";
import LayoutPage from "../../components/LayoutPage/LayoutPage";
import {Pagination} from "antd";
import ProductDraftCard from "../../components/ProductDraftCard/ProductDraftCard";
import ProductView from "../ProductView/ProductView";
import DrawerPanel from "../../components/DrawerPanel/DrawerPanel";
import "./DraftsListStyles.scss";
import ProductDraftFailedCard from "../../components/ProductDraftFailedCard/ProductDraftFailedCard";

const DraftsListTemplate = (props: TemplateProps) => (
    <LayoutPage className="drafts-list" title="Список черновиков">
        <div className="drafts-list__body">
            {
                props.products &&
                props.products.map((item, index) => (
                    <div className="drafts-list__item" key={item.id} data-position={props.pageStartIndex + index + 1}>
                        <div className="drafts-list__item-card">
                            {
                                item.dateParsed
                                    ? <ProductDraftCard item={item}
                                                        productUrl={props.productUrl}
                                                        onRemove={props.onProductRemove}/>
                                    : <ProductDraftFailedCard item={item}
                                                              onRemove={props.onProductRemove}/>
                            }
                        </div>
                    </div>
                ))
            }
        </div>

        {
            props.products &&
            props.products.length > 0 &&
            <Pagination className="drafts-list__pagination"
                        current={props.page}
                        pageSize={props.pageSize}
                        onChange={props.onPageChange}
                        total={props.productsTotal}
                        showSizeChanger={false}/>
        }


        <DrawerPanel title="Просмотр черновика"
                     width="70%"
                     visible={!!props.productId}
                     onClose={props.onProductClose}>
            {
                !!props.productId &&
                <ProductView productId={Number(props.productId)}
                             afterSaved={props.onProductSaved}
                             isArchive={false}/>
            }
        </DrawerPanel>

    </LayoutPage>
);

export default DraftsListTemplate;
