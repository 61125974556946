import styled, {css} from "styled-components";

interface ItemProps {
    columnsCount: number
}

export const Wrap = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
`;

export const Item = styled.div<ItemProps>`
    
    & + & {
        padding-left: 4px;
    }
    
    ${(props) => css`
        width: calc(100% / ${props.columnsCount <= 3 ? 3 : props.columnsCount});
    `}
`;
