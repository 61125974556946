import React from "react";
import {TemplateProps} from "./ChannelsListShapes";
import LayoutPage from "../../components/LayoutPage/LayoutPage";
import ChannelCard from "../../components/ChannelCard/ChannelCard";
import {Button, Alert, Select} from "antd";
import "./ChannelsListStyles.scss";
import TagCheckable from "../../components/TagCheckable/TagCheckable";
import DrawerPanel from "../../components/DrawerPanel/DrawerPanel";
import ChannelView from "../ChannelView/ChannelView";
import {Link} from "../../components/Routing";
import ROUTES from "../../constants/Routes";
import {getGendersOptions} from "../../helpers/GenderUtils";

const ChannelsListTemplate: React.FC<TemplateProps> = (props) => (
    <LayoutPage className="channels-list" title="Telegram каналы">

        <div className="channels-list__filters">
            <TagCheckable checked={props.status === 'active'} onChange={props.showActive}>Только активные</TagCheckable>
            <TagCheckable checked={props.status === 'archived'} onChange={props.showArchived}>Только в
                архиве</TagCheckable>
        </div>

        <div className="channels-list__items">
            {
                props.items &&
                props.items.map((item) => (
                    <div className="channels-list__items-row" data-channel-active={item.isActive} key={item.id}>
                        <div className="channels-list__items-card">
                            <ChannelCard item={item}/>
                        </div>
                        <div className="channels-list__items-actions">
                            <Select
                                allowClear
                                defaultValue={item.gender || undefined}
                                placeholder="Пол"
                                style={{width: 100, marginRight: 15}}
                                onChange={(gender) => props.onItemGenderChange(gender, item.id)}
                                options={getGendersOptions()}
                            ></Select>
                            {
                                item.isActive &&
                                <Link to={ROUTES.channels.replace(':cid?', item.cid)}>Фильтры</Link>
                            }
                            {
                                item.isActive
                                    ? <Button type="link"
                                              key="list-loadmore-more"
                                              onClick={props.onItemRemove}
                                              data-id={item.id}>Архивировать</Button>
                                    : <Button type="link"
                                              key="list-loadmore-more"
                                              onClick={props.onItemRestore}
                                              data-id={item.id}>Восстановить</Button>
                            }
                        </div>
                    </div>
                ))
            }
        </div>

        <br/>
        <br/>

        <Alert message="Добавление и обновление данных о канале"
               description={<>Для того чтобы добавить канал в список вам необходимо: <br/>
                   <b> 1.</b> Создать канал; <br/>
                   <b> 2.</b> Добавить в него бота (<b>{props.telegramBotName ? `@${props.telegramBotName}` : "Загрузка имени бота..."}</b>). Боту нужны права администратора; <br/>
                   <b> 3.</b> Написать команду <b>/add</b>. Данные подтянутся из канала (Название, Описание,
                   Картинка). <br/><br/>
                   Чтобы обновить информацию о канале, необходимо написать команду <b>/update</b>.<br/>
                   Данные подтянутся из канала, а если они изменились - обновятся.</>}
               type="info"
               showIcon/>


        <DrawerPanel visible={!!props.cid} title="Редактирование канала" onClose={props.handleGoToList}>
            {
                props.cid &&
                <ChannelView cid={props.cid}
                             afterSaved={props.handleGoToList}/>
            }
        </DrawerPanel>

    </LayoutPage>
);

export default ChannelsListTemplate;
