import styled, {css} from "styled-components";
import {Button} from "antd";
import {IsArchived, IsEdit} from "../../../interfaces/BaseTypes";


export const Wrap = styled.div<IsArchived & IsEdit>`
    width: 100%; 
    display: flex;
    min-height: 42px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    
    transition: opacity 0.3s, background-color 0.3s;
    border: 1px solid #d9d9d9;
    cursor: pointer;
    background-color: #ffffff;
    padding: 5px 2px 5px 5px;

    &:hover {
        opacity: 1;
        background-color: #fafafa;
    }
    
    ${(props) => props.isArchived && css`
        opacity: 0.6;
        &:hover {
            opacity: 0.6;
        }
    `}

    ${(props) => props.isEdit && css`
        padding: 5px 2px 5px 35px;
    `}
`;

export const Head = styled.div`
    margin-right: 10px;
`;

export const Body = styled.div`
    min-width: 50px
`;

export const Foot = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: auto;
`;

export const Title = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-wrap: nowrap;
    align-items: center;
    color: #000000;
    display: block;
`;

const ActionButton = styled(Button)`
    color: #000000;
    padding: 4px 8px;
    transition: background-color 0.3s;

    &:hover {
        color: #000000;
        background-color: #f2f2f2;
    }
`;

export const ActionArchive = styled(ActionButton)`
    &[data-is-archive='false'] {
        color: #fadb14;
    }
`;

export const ActionEdit = styled(ActionButton)`
`;

export const ActionRemove = styled(ActionButton)`
    color: red;

    &:hover {
        color: red;
    }
`;

export const ChildArrow = styled.div`
    min-width: 30px;
    color: #000000;
    padding: 4px 8px;
`;