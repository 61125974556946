import React, {FC, memo, useCallback, useEffect, useState} from "react";
import {treeChildrenFind, treeChildrenReplace, treeSelectedFind} from "./Cascader-utils";
import {CascaderProps, CascaderTree} from './Cascader-types';
import CascaderList from "./CascaderList/CascaderList";
import {CascaderItemClickHandler} from "./CascaderNode/CascaderNode-types";
import {Wrap, Item} from "./Cascader-styles";
import CascaderListView from './CascaderListView/CascaderListView';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';
import {convertFiltersToCascaderTrees} from '../../helpers/FilterTreeUtils';


const Cascader: FC<CascaderProps> = memo((props) => {
    const {items, isEdit, selectedFilters, onChecked, onEdit, onRemove, onArchive, onUpdatePosition} = props;

    const [dataTree, setDataTree] = useState<CascaderTree[]>([]);
    const [parentIds, setParentIds] = useState<number[]>([0]);
    const [cascadeIds, setCascadeIds] = useState<number[]>([]);
    const [checkedIds, setCheckedIds] = useState<number[]>([]);

    const handleClick: CascaderItemClickHandler = useCallback((key, event) => {
        const isSelected = event?.currentTarget.dataset.isSelected === "true";

        const currentParentIds = treeSelectedFind(dataTree, key);
        const currentChildren = treeChildrenFind(dataTree, key);

        if (currentParentIds.length) {
            const currentSelectedIds = [...currentParentIds];

            const isKeyPresence = currentSelectedIds.includes(key);

            if (!isKeyPresence) {
                currentSelectedIds.push(key);
            }

            if (!currentChildren.length) {
                currentParentIds.pop();
            }

            if (isSelected) {
                const selectIndex = currentSelectedIds.indexOf(key);
                const parentIndex = currentParentIds.indexOf(key);

                selectIndex >= 0 && currentSelectedIds.pop();
                parentIndex >= 0 && currentParentIds.pop();
            }

            currentParentIds.unshift(0);

            setParentIds(currentParentIds);
            setCascadeIds(currentSelectedIds);
        }
    }, [dataTree]);

    const handleCheck = useCallback((e: CheckboxChangeEvent) => {
        const isChecked = e.target.checked;
        const key = e.target.value;
        const currentCheckedIds = [...checkedIds];

        if (isChecked) {
            currentCheckedIds.push(key);
        } else {
            const index = currentCheckedIds.indexOf(key);

            currentCheckedIds.splice(index, 1);
        }

        onChecked && onChecked(currentCheckedIds);
    }, [checkedIds, onChecked])

    const updatePosition = useCallback((items: CascaderTree[]) => {
        const newDataTree = treeChildrenReplace(dataTree, items);

        setDataTree(newDataTree);

        onUpdatePosition && onUpdatePosition(items);
    }, [dataTree, onUpdatePosition]);

    useEffect(() => {
        if (items) {
            const dataTree = convertFiltersToCascaderTrees(items);

            setDataTree(dataTree);
        }
    }, [items]);

    useEffect(() => {
        if (selectedFilters) {
            const values = Object.values(selectedFilters).map(value => Number(value));

            setCheckedIds(values);
        }
    }, [selectedFilters]);

    return (
        <Wrap>
            {
                parentIds.map(parentId => {
                    const tree: CascaderTree[] = parentId ? treeChildrenFind(dataTree, parentId) : dataTree;

                    return (
                        <Item columnsCount={parentIds.length}>
                            {
                                !!tree.length && (
                                    isEdit ?
                                        <CascaderList
                                            key={parentId}
                                            dataCascade={tree}
                                            cascadeIds={cascadeIds}
                                            updatePosition={updatePosition}
                                            onClick={handleClick}
                                            onEdit={onEdit}
                                            onRemove={onRemove}
                                            onArchive={onArchive}
                                            isEdit={true} /> :
                                        <CascaderListView
                                            key={parentId}
                                            dataCascade={tree}
                                            cascadeIds={cascadeIds}
                                            checkedIds={checkedIds}
                                            onCheck={onChecked && handleCheck}
                                            onClick={handleClick}
                                            isEdit={false}
                                            isCheckbox={true}/>
                                )
                            }
                        </Item>
                    )
                })
            }
        </Wrap>
    );
});

export default Cascader;
