import {PureComponent} from "react";
import {Props, State} from "./LooksListShapes";
import Template from "./LooksListTemplate";
import LookModel from "../../models/LookModel";
import {Look} from "../../interfaces/Look";
import {message} from "antd";

export class LooksList extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        this.looksInit().then();
    }

    looksInit = async () => {
        const items = await LookModel.getAll(1);
        this.setState((state) => ({...state, items}));
    };

    lookRemove = async (item: Look) => {
        try {
            await LookModel.remove(item.id);
            const items = this.state.items.filter(el => el.id !== item.id);

            message.success(`Образ #${item.id} успешно удален!`);
            this.setState((state) => ({...state, items}));
        } catch (err) {
            message.error(`При удалении образа #${item.id} возникла ошибка!`);
        }
    };

    render() {
        return Template({
            ...this.state,
            lookRemove: this.lookRemove,
        });
    };
}

export default LooksList;