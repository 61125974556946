import React from "react";
import {TemplateProps} from "./ProductCreatorShapes";
import ProductEditor from "../../components/ProductEditor/ProductEditor";

const ProductCreatorTemplate: React.FC<TemplateProps> = (props) => (
    <div>
        <ProductEditor
            onSubmit={props.onSubmit}
            onCancel={props.onCancel}/>
    </div>
);

export default ProductCreatorTemplate;