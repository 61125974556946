import {PureComponent} from "react";
import {Props, State} from "./ProductsArchiveLooksShapes";
import ProductModel from "../../models/ProductModel";
import {ProductSaved} from "../../interfaces/Product";
import Template from "./ProductsArchiveLooksTemplate";

export class ProductsArchiveLooks extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            products: [],
            page: 1,
            pageSize: 40,
        };
    }

    componentDidMount() {
        this.productsLoadFromServer()
            .catch(err => console.error(err));
    }

    productsLoadFromServer = async () => {
        try {
            const products = (await ProductModel.getAllArchived()).map(product => {
                return {
                    ...product,
                    dateAdded: new Date(String(product.dateAdded))
                }
            });

            this.productsUpdateInList(products);
        } catch (err) {
            console.error(err)
        }
    }

    productsUpdateInList = (products: ProductSaved[]) => {
        this.setState(state => ({...state, products: this.filterByFollowers(products)}));
    };

    filterByFollowers = (products: ProductSaved[]) => {
        return products.filter(product => product.followers && product.followers.length > 0);
    }

    handlePageChange = (page: number) => {
        this.setState(state => ({...state, page}), () => {
            document.getElementById('container')?.scrollTo(0, 50);
        });
    }

    get products() {
        return this.state.products;
    }

    get pageStartIndex(): number {
        return (this.state.page - 1) * this.state.pageSize;
    }

    get pageEndIndex(): number {
        return this.state.page * this.state.pageSize;
    }

    render() {
        return Template({
            ...this.state,
            productsTotal: this.state.products ? this.products.length : 0,
            products: this.products.slice(this.pageStartIndex, this.pageEndIndex),

            pageStartIndex: this.pageStartIndex,
            onPageChange: this.handlePageChange,
        });
    };
}

export default ProductsArchiveLooks;
