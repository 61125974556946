import styled, {css} from 'styled-components';
import {HolderOutlined} from "@ant-design/icons";
import {IsDisabled} from "../../../interfaces/BaseTypes";

export const Wrap = styled.div<IsDisabled>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    position: relative;

    ${(props) => props.isDisabled && css`
        opacity: 0.75;
    `}
`;

export const Handler = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    width: 30px;
    left: 0;
    top: 0;
    bottom: 0;
`;

export const DragIcon = styled(HolderOutlined)`
    font-size: 20px;
    line-height: 1;
    margin-top: 3px;
`;

export const Body = styled.div`
    width: 100%;
`;
