import {Input, message, Switch} from "antd";
import React, {FC, useCallback, useEffect, useState} from "react";
import {AdditionalTagEditorProps} from "./AdditionalTagEditor-types";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import DrawerPanelFooter from "../DrawerPanel/DrawerPanelFooter";
import {
    AdditionalTag,
    AdditionalTagEmpty,
} from "../../interfaces/AdditionalTag";
import {
    useAdditionalTagCreate,
    useAdditionalTagLoad,
    useAdditionalTagUpdate,
} from "./AdditionalTagEditor-hooks";
import {Body, Foot, Label, Row, Wrap} from "./AdditionalTagEditor-styles";

const AdditionalTagEditor: FC<AdditionalTagEditorProps> = (props) => {
    const {itemId, onSubmit, onCancel} = props;

    const [item, setItem] = useState<AdditionalTag | AdditionalTagEmpty | null>(
        null
    );

    const loadAdditionalTagData = useAdditionalTagLoad(itemId);
    const additionalTagCreate = useAdditionalTagCreate();
    const fAdditionalTagUpdate = useAdditionalTagUpdate();

    const isAdditionalTag = (
        value: AdditionalTag | AdditionalTagEmpty
    ): value is AdditionalTag => {
        return value.id != null;
    };

    const handleAdditionalTagSubmit = useCallback(async () => {
        if (item) {
            const itemLocal = {...item};

            try {
                const itemCurrent: AdditionalTag = isAdditionalTag(itemLocal)
                    ? await fAdditionalTagUpdate(itemLocal)
                    : await additionalTagCreate(itemLocal);
                onSubmit(itemCurrent);
            } catch (err) {
                message.error("При сохранении фильтра возникла ошибка");
            }
        }
    }, [additionalTagCreate, fAdditionalTagUpdate, item, onSubmit]);

    const handleAdditionalTagCancel = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onCancel(e);
        },
        [onCancel]
    );

    const handleFilterTitleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (item) {
                const title: string = e.currentTarget.value;

                setItem({...item, title});
            }
        },
        [item]
    );

    const handleFilterStatusChange = useCallback(
        (isActive: boolean) => {
            if (item) {
                setItem({...item, isActive});
            }
        },
        [item]
    );

    useEffect(() => {
        loadAdditionalTagData()
            .then((response) => {
                if (response) {
                    setItem(response.item);
                }
            })
            .catch((err) => console.error(err));
    }, [loadAdditionalTagData]);

    return (
        <Wrap>
            <Body>
                <Row>
                    <Label>
                        Название тега
                    </Label>
                    <Input
                        className="additional-tag__input"
                        value={item?.title}
                        onChange={handleFilterTitleChange}
                    />
                </Row>

                <Row>
                    <Label>Статус</Label>
                    <Switch
                        className="additional-tag__switch"
                        checked={item?.isActive}
                        onChange={handleFilterStatusChange}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Row>
            </Body>

            <Foot>
                <DrawerPanelFooter
                    textClose="Отмена"
                    textSubmit="Сохранить"
                    onSubmit={handleAdditionalTagSubmit}
                    onClose={handleAdditionalTagCancel}
                />
            </Foot>
        </Wrap>
    );
};

export default AdditionalTagEditor;
