import {PureComponent} from "react";
import {Props, State} from "./LooksViewShapes";
import Template from "./LooksViewTemplate";
import LookModel from "../../models/LookModel";
import {message} from "antd";
import {LookBlock} from "../../interfaces/LookBlock";

export class LooksView extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            item: null,
            total: 0,
        };
    }

    componentDidMount() {
        this.lookInit().then();
    }

    getLookId = () => {
        return Number(this.props.match.params.id);
    }

    getTotal = (blocks: (LookBlock | null)[]): number => {
        return blocks.reduce((acc: number, block: LookBlock | null) => {
            const price = block && block.product
                ? block.product.priceDiscount || block.product.price
                : 0;

            return acc + Number(price);
        }, 0);
    };

    lookInit = async () => {
        try {
            const lookId = this.getLookId();
            const item = await LookModel.getById(lookId);
            const total = this.getTotal(Object.values(item.blocks));

            this.setState((state) => ({...state, item, total}));
        } catch (err) {
            message.error('При загрузке образа произошла ошибка!')
        }

    }

    render() {
        return Template({
            ...this.state
        });
    };
}

export default LooksView;