import React from "react";
import {TemplateProps} from "./LookPresetBuilderProps";

import "./LookPresetBuilderStyles.scss";
import {Button, Checkbox, Input} from "antd";
import LookBlock from "../LookBlock/LookBlock";

const onHandle = () => {
};

const LookPresetBuilderTemplate: React.FC<TemplateProps> = (props) => (
    <main className="look-preset-builder">
        <header className="look-preset-builder__head">
            <Input className="look-preset-builder__title"
                   value={props.title}
                   onChange={props.onTitleChange}
                   placeholder="Введите название"/>
        </header>

        <section className="look-preset-builder__body">
            <div className="look-preset-builder__column" data-column="left">
                {
                    ['1', '2'].map(blockId => (
                        <LookBlock
                            blockId={blockId}
                            key={blockId}
                            isLocked={false}
                            filters={props.filters}
                            lookBlock={props.blocks[blockId] || null}
                            onChange={props.onFilterChange}
                            onClear={props.onFilterClear}
                            onLock={onHandle}/>
                    ))
                }
            </div>
            <div className="look-preset-builder__column" data-column="right">
                {
                    ['3', '4', '5', '6', '7'].map(blockId => (
                        <LookBlock
                            blockId={blockId}
                            key={blockId}
                            isLocked={false}
                            filters={props.filters}
                            lookBlock={props.blocks[blockId] || null}
                            onChange={props.onFilterChange}
                            onClear={props.onFilterClear}
                            onLock={onHandle}/>
                    ))
                }
            </div>
        </section>

        <footer className="look-preset-builder__foot">
            <Checkbox className="look-preset-builder__default" onChange={props.onDefaultChange} checked={props.isDefault}>
                Отображать по умолчанию
            </Checkbox>
            <Button className="look-preset-builder__save"
                    type="primary"
                    onClick={props.onSave}>
                Сохранить
            </Button>
        </footer>

    </main>
);

export default LookPresetBuilderTemplate;