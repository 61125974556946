import {PlusCircleOutlined} from "@ant-design/icons";
import {Button, message} from "antd";
import React, {FC, useEffect, useState} from "react";
import ColorEditor from "../../components/ColorEditor/ColorEditor";
import {ContentListNodeArchiveClickHandler, ContentListNodeEditClickHandler, ContentListNodeRemoveClickHandler} from "../../components/ContentListView/ContentListNode/ContentListNode-types";
import ContentListView from "../../components/ContentListView/ContentListView";
import DrawerPanel from "../../components/DrawerPanel/DrawerPanel";
import LayoutPage from "../../components/LayoutPage/LayoutPage";
import {Color} from "../../interfaces/Color";
import ColorModel from "../../models/ColorModel";
import {List} from "./ColorList-style";
import {ColorListProps} from "./ColorList-type";


const ColorListList: FC<ColorListProps> = (props) => {
    const [Colors, setColors] = useState<Color[]>([]);
    const [editorVisible, setEditorVisible] = useState(false);
    const [editorItemId, setEditorItemId] = useState<number | null>(null);

    const handleEditorClose = () => {
        setEditorVisible(!editorVisible);
    };

    const handleEditorOpen = () => {
        setEditorItemId(null);
        setEditorVisible(!editorVisible);
    };

    const handleEditToggle: ContentListNodeEditClickHandler = (itemId) => {
        const editorItemId = Number(itemId) || null;

        setEditorItemId(editorItemId);
        setEditorVisible(!editorVisible);
    };

    const handleRemove: ContentListNodeRemoveClickHandler = async (
        itemId
    ) => {
        try {
            await ColorModel.remove(itemId);

            const items = Colors.filter(
                (element) => element.id !== itemId
            );

            setColors(items);
            message.success("Фильтр успешно удален");
        } catch (err) {
            message.error("Не удается получить ID фильтра");
            console.error(err);
        }
    };

    const handleArchiveToggle: ContentListNodeArchiveClickHandler = async (
        itemId
    ) => {
        try {
            const items = [...Colors];
            const itemIndex: number = items.findIndex((el) => el.id === itemId);

            if (itemIndex >= 0) {
                const item = {...items[itemIndex]};
                item.isActive = !item.isActive;

                await ColorModel.update(item);

                items[itemIndex] = item;

                setColors(items);

                message.success(
                    item.isActive
                        ? "Фильтр успешно восстановлен"
                        : "Фильтр успешно деактивирован"
                );
            } else {
                message.error("Не удается получить ID фильтра");
            }
        } catch (err) {
            message.error("При архивировании произошла ошибка");
            console.error(err);
        }
    };

    const handleSubmit = (item: Color) => {
        const items = [...Colors];
        const itemIndex = Colors.findIndex((el) => el.id === item.id);

        if (itemIndex >= 0) {
            items.splice(itemIndex, 1, {...item});
        } else {
            items.push({...item});
        }

        setColors(items);
        setEditorItemId(null);
        setEditorVisible(false);

        message.success(
            itemIndex >= 0
                ? "Фильтр успешно отредактирован"
                : "Фильтр успешно создан"
        );
    };

    useEffect(() => {
        (async () => {
            try {
                const items = await ColorModel.getAll();

                if (!items || items.length <= 0) {
                    throw new Error("Additional tag models are not loaded!");
                }

                setColors(items);
            } catch (err) {
                message.error("Проблема при загрузке данных с сервера");
                console.error(err);
            }
        })();
    }, []);

    return (
        <LayoutPage
            className="tags-list"
            title="Цвета"
            extra={
                <Button
                    icon={<PlusCircleOutlined />}
                    type="primary"
                    onClick={handleEditorOpen}
                >
                    Новый цвет
                </Button>
            }
        >
            <List>
                <ContentListView
                    items={Colors}
                    onEdit={handleEditToggle}
                    onRemove={handleRemove}
                    onArchive={handleArchiveToggle}
                    isEdit
                />
            </List>

            <DrawerPanel
                visible={editorVisible}
                title={
                    editorItemId ? "Редактирование цвета" : "Создание цвета"
                }
                onClose={handleEditorClose}
                destroyOnClose
            >
                <ColorEditor
                    itemId={editorItemId || undefined}
                    onSubmit={handleSubmit}
                    onCancel={handleEditToggle}
                />
            </DrawerPanel>
        </LayoutPage>
    );
};

export default ColorListList;