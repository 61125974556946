import {PureComponent} from "react";
import {Props, State} from "./LookBuilderShapes";
import {message} from "antd";
import Template from "./LookBuilderTemplate";
import LookModel from "../../models/LookModel";
import {LookBuilderBlock} from "../../interfaces/LookBuilderBlock";
import {FilterSelectable} from "../../interfaces/FilterSelectable";
import HandlerTextInputChange from "../../interfaces/HandlerTextInputChange";
import {LookPreset} from "../../interfaces/LookPreset";
import {
    LookBuilderBlockLock,
    LookBuilderFilterChange,
    LookBuilderFilterClear,
    LookBuilderPresetSelect
} from "./LookBuilderHandlers";
import {KeyValCollection} from "../../interfaces/KeyValCollection";
import {GetRandomArrayElement} from "../../helpers/ArrayUtils";
import LookPresetModel from "../../models/LookPresetModel";
import {LookPresetBlock} from "../../interfaces/LookPresetBlock";

export class LookBuilder extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            title: "",
            blocks: {},
            filters: [],
            presets: [],
            presetSelected: null,
            total: 0,
            blocksLocked: {},
        };
    }

    componentDidMount() {
        this.filtersInit().then();
        this.presetsInit().then()
        this.itemInit().then();
    }

    getId = (): LookPreset['id'] | null => {
        return this.props.id || null;
    };

    filtersInit = async () => {
        const filters = await LookModel.getLookFilters();
        this.setState({filters});
    };

    presetsInit = async () => {
        const id = this.getId();
        const presets = await LookPresetModel.getAll();
        const presetDefault = presets.find(el => el.isDefault) || null;

        this.setState((state) => ({
            ...state,
            presets,
            blocks: (presetDefault && !id) ? presetDefault.blocks : {...state.blocks},
            presetSelected: (presetDefault) ? presetDefault.id : state.presetSelected,
        }), () => {
            if (presetDefault && !id) {
                this.handleStart();
            }
        });
    };


    itemInit = async () => {
        const itemId = this.getId();
        itemId
            ? await this.itemInitExisted(itemId)
            : await this.itemInitNew();
    };

    itemInitNew = async () => {
        try {
            const item = await LookModel.createBlank();

            this.setState((state) => ({
                ...state,
                title: item.title,
                blocks: Object.keys(state.blocks).length <= 0 ? {...item.blocks} : {...state.blocks},
            }));
        } catch (err) {
            message.error('При загрузке данных произошла ошибка.');
        }
    };

    itemInitExisted = async (itemId: number) => {
        try {
            const item = await LookModel.getById(itemId);
            this.setState({title: item.title, blocks: item.blocks});
        } catch (err) {
            console.error('Error while look loading.');
            message.error('При загрузке данных произошла ошибка.');
        }
    };

    calculateTotal = (blocks: (LookBuilderBlock | null)[]): number => {
        return blocks.reduce((acc: number, block: LookBuilderBlock | null) => {
            const price = block && block.product
                ? block.product.priceDiscount || block.product.price
                : 0;

            return acc + Number(price);
        }, 0);
    };

    calculateSelectedFilters = (blocks: (LookBuilderBlock | null)[]): FilterSelectable[] => {
        const filtersSelected = blocks.map(el => el && el.filter ? el.filter.id : null);
        return this.state.filters.map(el => ({...el, isSelected: filtersSelected.includes(el.id)}));
    }

    handleTitleChange: HandlerTextInputChange = (e) => {
        const title = e.currentTarget.value;
        this.setState({title})
    }

    handleFilterChange: LookBuilderFilterChange = (value, data: any) => {
        if (value && data) {
            const sectionId = data['data-block-id'] as string;
            const filterIndex = this.state.filters.findIndex(el => el.id === value);

            if (filterIndex >= 0) {

                const filter = {...this.state.filters[filterIndex]};

                const block: LookBuilderBlock = this.state.blocks.hasOwnProperty(sectionId)
                    ? {...this.state.blocks[sectionId], filter}
                    : {sectionId, filter};

                const blocks = {...this.state.blocks, [sectionId]: block};
                const filters = this.calculateSelectedFilters(Object.values(blocks));

                this.setState((state) => ({...state, blocks, filters}))
            }
        }
    };

    handleFilterClear: LookBuilderFilterClear = (e) => {
        const blockId = e.currentTarget.dataset.blockId || null;
        const blocks = {...this.state.blocks};

        if (blockId) {
            delete blocks[blockId];

            const total = this.calculateTotal(Object.values(blocks));
            const filters = this.calculateSelectedFilters(Object.values(blocks));

            this.setState((state) => ({...state, blocks, filters, total}));
        }
    };


    handleBlockLock: LookBuilderBlockLock = (e) => {
        const blockId = e.currentTarget.dataset.blockId || null;

        if (blockId) {
            const blocksLocked = {...this.state.blocksLocked};

            if (blocksLocked.hasOwnProperty(blockId)) {
                delete blocksLocked[blockId];
            } else {
                blocksLocked[blockId] = '';
            }

            this.setState((state) => ({...state, blocksLocked}));
        }
    };

    handleStart = async () => {
        const filterIds = Object.values(this.state.blocks).map(block => Number(block.filter?.id));

        if (filterIds.length === 0) {
            message.error('Вы не выбрали ни одной категории!');
            return;
        }

        const lookData = await LookModel.getLookData(filterIds);

        let total = 0;
        const blocks: KeyValCollection<LookBuilderBlock> = {...this.state.blocks};

        for (let sectionId in this.state.blocks) {
            if (this.state.blocks.hasOwnProperty(sectionId)) {
                const block = this.state.blocks[sectionId];
                const filterId = block.filter?.id;

                const filter = lookData.find((el: FilterSelectable) => el.id === filterId) || null;
                const product = filter && filter.products && filter.products.length > 0 ? filter.products[0] : null;

                if (product && filter && !this.state.blocksLocked.hasOwnProperty(sectionId)) {
                    const images = [...product.images];
                    delete product.images;

                    block.image = GetRandomArrayElement(images);
                    block.product = product;
                    block.filter = filter;
                    block.sectionId = sectionId;
                }

                blocks[sectionId] = {...block};
                total = this.calculateTotal(Object.values(blocks));
            }
        }

        this.setState((state) => ({...state, blocks, total}));
    }

    handleSave = async () => {
        if (!this.validate()) {
            return;
        }


        try {
            const look = await LookModel.createBlank();
            look.blocks = {...this.state.blocks};
            look.title = this.state.title;

            const item = await LookModel.create(look);

            message.success('Новый образ успешно сохранен');

            this.props.onAfterSave &&
            this.props.onAfterSave(item);

        } catch (err) {
            message.error('При сохранении образа произошла ошибка!')
        }
    };

    validate = (): boolean => {
        const blocks = Object.values(this.state.blocks);

        if (blocks.length <= 0 || !blocks.some(el => el.image && el.product)) {
            message.warn('Необходимо сначала создать образ, а после сохранять!');
            return false
        }

        if (!this.state.title) {
            message.warn('Необходимо ввести название');
            return false;
        }

        return true;
    };


    handlePresetSelect: LookBuilderPresetSelect = (presetId) => {
        const presetSelected = presetId || null;
        const preset: LookPreset | null = (presetSelected)
            ? this.state.presets.find(el => el.id === presetId) || null
            : null;

        const blocks: KeyValCollection<LookPresetBlock> = {};

        if (preset != null) {
            for (const [, value] of Object.entries(preset.blocks)) {
                const block = {...value};
                delete block.id;
                blocks[block.sectionId] = block;
            }
        }

        const blocksLocked = {};
        const total = 0;

        this.setState({presetSelected, blocks, total, blocksLocked});
    };

    render() {
        return Template({
            ...this.state,
            onSave: this.handleSave,
            onStart: this.handleStart,
            onBlockLock: this.handleBlockLock,
            onTitleChange: this.handleTitleChange,
            onFilterChange: this.handleFilterChange,
            onFilterClear: this.handleFilterClear,
            onPresetSelect: this.handlePresetSelect,
        });
    };
}

export default LookBuilder;