import ApiService from "./ApiService";
import {ProductScrapped} from "../interfaces/Product";
import ENDPOINTS from "../constants/Endpoints";

class ScrapperService extends ApiService {

    parse = async (url: string): Promise<ProductScrapped> => {
        return this.post<ProductScrapped>(ENDPOINTS.parseStore, {url});
    };

}

export default new ScrapperService();