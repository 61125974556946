import React, {FC, memo, useEffect, useState} from "react";
import {CascaderListProps} from './CascaderList-types';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import {useFilterLoad} from './CascaderList-hooks';
import {BodyDraggable, Wrap} from "./CascaderList-styles";
import CascaderNode from "../CascaderNode/CascaderNode";
import CascaderDragItem from "../CascaderDragItem/CascaderDragItem";
import {CascaderTree} from '../Cascader-types';


const CascaderList: FC<CascaderListProps> = (props) => {
    const {dataCascade, cascadeIds, isEdit, onClick, updatePosition, onEdit, onRemove, onArchive} = props;

    const [finalDataCascade, setFinalDataCascade] = useState<CascaderTree[]>(dataCascade);

    const handleDragEnd = useFilterLoad(finalDataCascade, updatePosition, setFinalDataCascade);

    useEffect(() => {
        setFinalDataCascade(dataCascade);
    }, [dataCascade]);

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="filters">
                {(provider) => (
                    <Wrap {...provider.droppableProps} ref={provider.innerRef}>
                        {
                            finalDataCascade?.map((item, index) => (
                                    <BodyDraggable key={item.key} draggableId={String(item.key)} index={index}>
                                        {(provider) => (
                                            <CascaderDragItem key={item.key} {...provider} isDisabled={!item.isActive}>
                                                <CascaderNode
                                                    item={item}
                                                    cascadeIds={cascadeIds}
                                                    onClick={onClick}
                                                    onEdit={onEdit}
                                                    onRemove={onRemove}
                                                    onArchive={onArchive}
                                                    isEdit={isEdit} />
                                            </CascaderDragItem>
                                        )}
                                    </BodyDraggable>
                            ))
                        }
                        {provider.placeholder}
                    </Wrap>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default memo(CascaderList);
