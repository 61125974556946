import {ChangeEventHandler, DragEventHandler, MouseEventHandler, PureComponent} from "react";
import {Props, State} from "./UploadDropzoneProps";
import Template from "./UploadDropzoneTemplate";
import Attachment from "../../interfaces/Attachment";
import HandlerImageRemove from "../../interfaces/HandlerImageRemove";
import UploadService from "../../services/UploadService";
import AttachmentUploaded from "../../interfaces/AttachmentUploaded";


export class UploadDropzone extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            isOver: false,
        };
    }


    imageSelect: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation();
        e.preventDefault();

        this.props.onImageSelect(e);
    };

    imageRemove: HandlerImageRemove = (link, e) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        this.props.onImageRemove(link, e);
    };

    filesPrepareAndUpload = (files: FileList | null) => {
        const items = files ? this.convertFileListToArray(files) : [];
        this.filesUpload(items);
    };

    filesAddedByPicking: ChangeEventHandler<HTMLInputElement> = (e) => {
        e.preventDefault();
        const files = e.target.files;
        this.filesPrepareAndUpload(files);
    };

    filesAddedByDropping: DragEventHandler<HTMLElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const files = e.dataTransfer.files;
        this.filesPrepareAndUpload(files);

        this.setState((state) => ({...state, isOver: false}));
    };

    filesUpload = (files: File[]) => {
        const images = [...this.props.images];

        files.forEach((file: File, index) => {
            const attachment = this.convertFileToAttachment(file, index);
            images.push(attachment);

            UploadService.fileUpload(file)
                .then(this.fileUploadSuccess)
                .catch(this.fileUploadError);
        });

        this.props.onImagesChanged(images);
    };

    fileUploadSuccess = (file: AttachmentUploaded) => {
        const images = [...this.props.images];

        const imageUploaded = {...file.file};
        const imageOriginal = {...file.fileOriginal};

        const imageIndex = images.findIndex((el: Attachment) => el.name === imageOriginal.name && el.size === imageOriginal.size);

        if (imageIndex > -1) {
            images[imageIndex] = imageUploaded;
        }

        this.props.onImagesChanged(images);
    };

    fileUploadError = () => {

    };

    convertFileToAttachment = (file: File, index: number): Attachment => ({
        name: file.name,
        size: file.size,
        type: "image",
        link: URL.createObjectURL(file),
        toTelegram: false,
    });

    convertFileListToArray = (list: FileList): File[] => {
        const files: File[] = [];

        for (let i = 0; i < list.length; i++) {
            const item: File | null = list.item(i);

            if (item != null) {
                files.push(item);
            }
        }

        return files;
    }

    dragOver: DragEventHandler<HTMLElement> = (e) => {
        e.preventDefault();

        this.setState((state) => ({...state, isOver: true}));
    };

    dragLeave: DragEventHandler<HTMLElement> = () => {
        this.setState((state) => ({...state, isOver: false}));
    };

    dragBlock: DragEventHandler<HTMLElement> = (e) => {
        e.preventDefault();
    };


    render() {
        return Template({
            ...this.state,
            images: this.props.images,
            onImageSelect: this.imageSelect,
            onImageRemove: this.imageRemove,
            onDragOver: this.dragOver,
            onDragLeave: this.dragLeave,
            onDragBlock: this.dragBlock,
            onFilesPick: this.filesAddedByPicking,
            onFilesDrop: this.filesAddedByDropping,
            onUpdatePosition: this.props.onUpdatePosition,
        });
    };
}

export default UploadDropzone;