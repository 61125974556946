
const ENDPOINTS = {
    authTelegram: '/api/auth/telegram' as string,
    authSimple: '/api/auth/simple' as string,
    authSimpleVerify: '/api/auth/simple-verify' as string,
    authDefault: '/api/auth/default' as string,
    parseStore: '/api/parse' as string,
    telegramGetBotName: '/api/telegram/botname' as string,
    telegramSendProduct: '/api/telegram/product' as string,
    telegramSendProudctsList: '/api/telegram/products-list' as string,
    filter: '/api/filter' as string,
    product: '/api/product' as string,
    productVer2: '/api/product/ver2/' as string,
    channel: '/api/channel' as string,
    look: '/api/look' as string,
    lookFilters: '/api/look/filters' as string,
    lookVariant: '/api/look/variant' as string,
    lookPreset: '/api/look-preset' as string,
    upload: '/api/upload' as string,
    additionalTag: '/api/additional-tag' as string,
    color: '/api/color' as string,

    currencyRUB: 'https://www.cbr-xml-daily.ru/latest.js' as string,
}

export default ENDPOINTS;
