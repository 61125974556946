import styled from "styled-components";


export const Wrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const Body = styled.div`
    width: 100%;
    flex: 1 0 auto;
    padding: 24px;
    overflow: auto;
    height: calc(100% - 50px);
`;

export const Foot = styled.div`
    width: 100%;
    height: 50px;
    flex: 0 0 auto;
    padding: 0 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
`;

export const Row = styled.label`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 30px;
`;

export const Label = styled.span`
    margin-bottom: 10px;
    font-weight: 500;
`;