import React from "react";
import {Popconfirm} from "antd";
import {LookCardShapes} from "./LookCardShapes";
import ROUTES from "../../constants/Routes";
import {Link} from "../Routing";
import "./LookCard.scss";
import {CloseSquareOutlined} from "@ant-design/icons";
import {lookTotalCount} from "../../helpers/LookUtils";

const LookCard: React.FC<LookCardShapes> = (props) => {
    const stopPropagation = (e: any) => {
        e.stopPropagation();
    }

    const handleRemove = (e: any) => {
        e.stopPropagation();
        props.onRemove(props.item);
    };

    return (
        <Link className="look-card" to={ROUTES.looksView.replace(':id?', props.item.id.toString())}>
            <div className="look-card__head">
                {
                    Object.values(props.item.blocks).map(block => (
                        <img className="look-card__image"
                             src={block.image ? ROUTES.uploads(block.image.name) : ""}
                             alt=""
                             key={block.sectionId}/>
                    ))
                }
                <div className="product-card__actions">
                    <Popconfirm title="Вы точно хотите удалить?" onConfirm={handleRemove} onCancel={stopPropagation}>
                        <button className="product-card__remove" title="Удалить" onClick={stopPropagation}>
                            <CloseSquareOutlined/>
                        </button>
                    </Popconfirm>
                </div>
            </div>

            <div className="look-card__info">
                <div className="look-card__title">{props.item.title}</div>
                <div className="look-card__price">{lookTotalCount(props.item)} ₽</div>
            </div>
        </Link>
    );
}

export default LookCard;