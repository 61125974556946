import {OnlineStoreName} from "./OnlineStoreName";

export const OnlineStoreUrl: Record<OnlineStoreName, string[]> = (() => {
    const urls = {
        [OnlineStoreName.MassimoDutti]: ["www.massimodutti.com", "massimodutti.com", "massimodutti"],
        [OnlineStoreName.Mango]: ["shop.mango.com", "mango"],
        [OnlineStoreName.Lime]: ["www.lime-shop.com", "lime-shop.com", "www.lime-shop.ru", "lime-shop.ru", "lime"],
        [OnlineStoreName.Zara]: ["www.zara.com", "m.zara.com", "zara.com", "zara"],
        [OnlineStoreName.HM]: ["www2.hm.com", "hm"],
        [OnlineStoreName.Vagabond]: ["www.vagabond.com", "vagabond.com", "vagabond"],
        [OnlineStoreName.Ekonika]: ["www.ekonika.ru", "ekonika.ru", "ekonika"],
        [OnlineStoreName.TwelweStoreez]: ["www.12storeez.com", "12storeez.com", "12storeez"],
        [OnlineStoreName.Gate31]: ["www.gate31.ru", "gate31.ru", "gate31"],
        [OnlineStoreName.CosStores]: ["www.cos.com", "cos.com", "cosstores"],
        [OnlineStoreName.AllWeNeed]: ["www.allweneed.ru", "allweneed.ru", "allweneed"],
        [OnlineStoreName.IamStudio]: ["www.iamstudio.ru", "iamstudio.ru", "iamstudio"],
        [OnlineStoreName.AndOtherStories]: ["www.stories.com", "stories.com", "andotherstories"],
        [OnlineStoreName.Agreeg]: ["www.agreeg.com", "agreeg.com", "agreeg"],
        [OnlineStoreName.PoisonDrop]: ["www.poisondrop.ru", "poisondrop.ru", "poisondrop"],
        [OnlineStoreName.Uniqlo]: ["www.uniqlo.com", "m.uniqlo.com", "uniqlo.com", "uniqlo"],
        [OnlineStoreName.Uterque]: ['www.uterque.com', 'uterque.com', "uterque"],
        [OnlineStoreName.Zarina]: ['www.zarina.ru', 'zarina.ru', "zarina"],
        [OnlineStoreName.Bershka]: ['www.bershka.com', 'bershka.com', "bershka"],
        [OnlineStoreName.PullAndBear]: ['www.pullandbear.com', 'pullandbear.com', "pullandbear"],
        [OnlineStoreName.Reserved]: ['www.reserved.com', 'reserved.com', "reserved"],
        [OnlineStoreName.Stradivarius]: ['www.stradivarius.com', 'stradivarius.com', "stradivarius"],
        [OnlineStoreName.ArnyPraht]: ['www.arnypraht.com', 'arnypraht.com', "arnypraht"],
        [OnlineStoreName.TwoMoodStore]: ['www.2moodstore.com', '2moodstore.com', "2moodstore"],
        [OnlineStoreName.Farfetch]: ['www.farfetch.com', 'farfetch.com', "farfetch"],
        [OnlineStoreName.EmkaShop]: ['www.emkashop.ru', 'emkashop.ru', "emkashop"],
        [OnlineStoreName.Incity]: ['www.incity.ru', 'incity.ru', "incity"],
        [OnlineStoreName.Studio29]: ['www.studio-29.ru', 'studio-29.ru', "studio-29", "studio29"],
        [OnlineStoreName.Charuel]: ['www.charuel.ru', 'charuel.ru', "charuel"],
        [OnlineStoreName.VivaLaVika]: ['www.vivalavika.com', 'vivalavika.com', "vivalavika"],
        [OnlineStoreName.AshRussia]: ['www.ashrussia.ru', 'ashrussia.ru', "ashrussia"],
        [OnlineStoreName.Rendezvous]: ['www.rendez-vous.ru', 'rendez-vous.ru', "rendez-vous", "rendezvous"],
        [OnlineStoreName.Divnome]: ['www.divno.me', 'divno.me', "divno"],
        [OnlineStoreName.Loverepublic]: ['www.loverepublic.ru', 'loverepublic.ru', "loverepublic"],
        [OnlineStoreName.Lichi]: ['www.lichi.com', 'lichi.com', "lichi"],
        [OnlineStoreName.Stores1811]: ['www.1811stores.com', '1811stores.com', "stores1811"],
        [OnlineStoreName.Arket]: ['www.arket.com', 'arket.com', "arket"],
    };

    const storesCount = Object.keys(OnlineStoreName).length;
    const urlsCount = Object.keys(urls).length;

    if (storesCount !== urlsCount) {
        console.error('OnlineStoreUrl Error: Seems you forgot to add URLs of a new store');
    }

    return urls;
})();
