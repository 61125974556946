import React, {MouseEventHandler, useEffect, useState} from "react";
import {LoginType, Props} from "./LoginShapes";
import {UserOutlined, KeyOutlined} from "@ant-design/icons";
import {Button, Col, message, Row, Typography} from "antd";
import AuthWelcome from "../../components/AuthWelcome/AuthWelcome";
import {AuthDefault, AuthSimple} from "../../components/AuthForm";
import {AuthAfterSubmit} from "../../components/AuthForm/interfaces/AuthAfterSubmit";
import AuthService from "../../services/AuthService";
import User from "../../interfaces/User";
import ROUTES from "../../constants/Routes";

import "./LoginStyles.scss";

const Login: React.FC<Props> = (props) => {

    const [loginType, setLoginType] = useState<LoginType>("simple");

    const loginTypeGuard = (checkedType: any): checkedType is LoginType => {
        const typesAvailable: LoginType[] = ["default", "simple", "telegram"];
        return typesAvailable.includes(checkedType);
    };

    const handleAfterSubmit: AuthAfterSubmit = (user: User | null) => {
        if (user == null) {
            message.error('Неправилный логин или пароль');
        } else {
            message.success('Вы успешно авторизовались!');

            props.history.push(user.role === "ADMIN" ? ROUTES.privateApp : ROUTES.publicApp);
        }
    }

    const handleLoginTypeChange: MouseEventHandler<HTMLButtonElement> = (e) => {
        const value = e.currentTarget.dataset.loginType;
        const type: LoginType = loginTypeGuard(value) ? value : "simple";

        setLoginType(type);
    };


    useEffect(() => {
        if (AuthService.hasPrivateAccess()) {
            return props.history.push(ROUTES.privateApp);
        }
        if (AuthService.hasPublicAccess()) {
            return props.history.push(ROUTES.publicApp);
        }
    }, [props.history]);


    return (
        <Row className="login-layout">
            <Col className="login-layout__body" span={14}>

                <Typography.Title className="login-layout__title" level={3}>
                    Авторизация
                </Typography.Title>

                <div className="login-layout__switch">
                    <Button className="login-layout__switch-item"
                            title="Войти используя одноразовый пароль"
                            icon={<KeyOutlined/>}
                            data-login-type="simple"
                            data-active={loginType === 'simple'}
                            onClick={handleLoginTypeChange}/>

                    <Button className="login-layout__switch-item"
                            title="Войти используя логин/пароль"
                            icon={<UserOutlined/>}
                            data-active={loginType === 'default'}
                            data-login-type="default"
                            onClick={handleLoginTypeChange}/>
                </div>


                {
                    loginType === 'default' &&
                    <AuthDefault afterSubmit={handleAfterSubmit}/>
                }
                {
                    loginType === 'simple' &&
                    <AuthSimple afterSubmit={handleAfterSubmit}/>
                }

                {/*<Divider className="login-layout__divider" plain>*/}
                {/*    или войдите через*/}
                {/*</Divider>*/}

                {/*<div className="login-layout__alternatives">*/}
                {/*    <AuthTelegram afterSubmit={handleAfterSubmit}/>*/}
                {/*</div>*/}

            </Col>
            <Col className="login-layout__foot" span={10} style={{padding: '0 8%'}}>
                <AuthWelcome/>
            </Col>
        </Row>
    );
}

export default Login;
