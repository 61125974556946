import React from "react";
import {TemplateProps} from "./ProductsListShapes";
import LayoutPage from "../../components/LayoutPage/LayoutPage";
import {Button, Checkbox, Pagination, Select, DatePicker} from "antd";
import ProductCard from "../../components/ProductCard/ProductCard";
import ChannelSelectModal from "../../components/ChannelSelectModal/ChannelSelectModal";
import TagCheckable from "../../components/TagCheckable/TagCheckable";
import "./ProductsListStyles.scss";
import ProductView from "../ProductView/ProductView";
import DrawerPanel from "../../components/DrawerPanel/DrawerPanel";
import Cascader from '../../components/Cascader/Cascader';
import {optionsLabelSort} from "../../helpers/SelectUtils";
import "moment/locale/ru";
import locale from "antd/lib/date-picker/locale/ru_RU";
import ProductCardArchive from "../../components/ProductCardArchive/ProductCardArchive";
import {getSeasonsOptions} from "../../helpers/SeasonUtils";
import {getGendersOptions} from "../../helpers/GenderUtils";

const ProductsListTemplate = (props: TemplateProps) => (
    <LayoutPage className="products-list" title={props.isArchive ? "Архив товаров" : "Список товаров"}>
        <div className="products-list__head">
            <div className='product-editor__tags'>
                <Cascader
                    items={props.filters}
                    selectedFilters={Object.keys(props.selectedFilters)}
                    onChecked={props.onChangeSelectedFilters} />
            </div>

            <div className="products-list__action">
                {
                    !props.isArchive &&
                    <Button type="dashed" onClick={props.onChannelSelectOpen} disabled={props.products.length <= 0}>
                        Отправить в телеграм
                    </Button>
                }
            </div>

        </div>
        <div className="products-list__head">
            <div className="products-list__filters">
                {
                    !props.isArchive &&
                    <TagCheckable
                        className="products-list__tag"
                        checked={props.channelSelected === 'empty'}
                        onChange={() => props.onChannelClick('empty')}>
                        Товары без канала
                    </TagCheckable>
                }

                {
                    props.channels &&
                    props.channels.map(channel => (
                        <TagCheckable
                            className="products-list__tag"
                            key={channel.id}
                            checked={props.channelSelected === channel.id}
                            onChange={() => props.onChannelClick(channel.id)}>
                            {channel.title}
                        </TagCheckable>
                    ))
                }
            </div>
        </div>

        {
            props.isArchive && 
            <div className="products-list__head">
                <div className="products-list__filter-list">
                    <div className="products-list__filter">
                        <div className="products-list__filter-label">
                            <div className="products-list__filter-label-text">Лучшее:</div>
                        </div>
                        <div className="products-list__filter-action">
                            <Checkbox
                                onChange={props.onBestChange}
                                checked={props.isBest}
                            />
                        </div>
                    </div>

                    <div className="products-list__filter">
                        <div className="products-list__filter-label">
                            <div className="products-list__filter-label-text">Дата:</div>
                        </div>
                        <div className="products-list__filter-action">
                            <DatePicker
                                picker="year"
                                placeholder="Выберите год"
                                onChange={props.onDateChange}
                                locale={locale}
                            />
                        </div>
                    </div>

                    {
                        props.shopList &&
                            <div className="products-list__filter">
                                <div className="products-list__filter-label">
                                    <div className="products-list__filter-label-text">Магазин:</div>
                                </div>
                                <div className="products-list__filter-action">
                                    <div className="products-list__filter-action-selector">
                                        <Select
                                            allowClear
                                            showSearch
                                            style={{width: "100%"}}
                                            placeholder="Выберите магазин"
                                            onChange={props.onShopSelectedChange}
                                            options={Object.keys(props.shopList)
                                                .sort()
                                                .map((store) => ({
                                                    label: store,
                                                    value: store,
                                            }))}
                                        />
                                    </div>
                                </div>
                            </div>
                    }

                    {
                        props.additionalTags &&
                        <div className="products-list__filter">
                            <div className="products-list__filter-label">
                                <div className="products-list__filter-label-text">Доп. теги:</div>
                            </div>
                            <div className="products-list__filter-action">
                                <div className="products-list__filter-action-selector">
                                    <Select
                                        allowClear
                                        showSearch
                                        style={{width: "100%"}}
                                        placeholder="Выберите дополнительный тег"
                                        onChange={props.onAdditionalTagSelectedChange}
                                        options={props.additionalTags.map((tag) => ({
                                                label: tag.title,
                                                value: tag.title,
                                        })).sort(optionsLabelSort)}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                    {
                        props.colorList &&
                        <div className="products-list__filter">
                            <div className="products-list__filter-label">
                                <div className="products-list__filter-label-text">Цвет:</div>
                            </div>
                            <div className="products-list__filter-action">
                                <div className="products-list__filter-action-selector">
                                    <Select
                                        allowClear
                                        showSearch
                                        style={{width: "100%"}}
                                        placeholder="Выберите цвет"
                                        onChange={props.onColorsSelectedChange}
                                        options={props.colorList.map((color) => {
                                            return {
                                                label: color.title,
                                                value: color.title,
                                            };
                                        }).sort(optionsLabelSort)}
                                    />
                                </div>
                            </div>
                        </div>
                    }


                    <div className="products-list__filter">
                        <div className="products-list__filter-label">
                            <div className="products-list__filter-label-text">Сезон:</div>
                        </div>
                        <div className="products-list__filter-action">
                            <div className="products-list__filter-action-selector">
                                <Select
                                    allowClear
                                    style={{width: "100%"}}
                                    placeholder="Выберите сезон"
                                    onChange={props.onSeasonSelectedChange}
                                    options={getSeasonsOptions()}
                                />
                            </div>
                        </div>
                    </div>

                    {
                        props.priceOptions &&
                        <div className="products-list__filter">
                            <div className="products-list__filter-label">
                                <div className="products-list__filter-label-text">Цена:</div>
                            </div>
                            <div className="products-list__filter-action">
                                <div className="products-list__filter-action-selector">
                                    <Select
                                        style={{width: "100%"}}
                                        onChange={props.onPriceOptionsSelectedChange}
                                        defaultValue={props.priceOptionsSelected}
                                        options={props.priceOptions.map((priceOption) => {
                                            return {
                                                label: priceOption,
                                                value: priceOption,
                                            };
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                    <div className="products-list__filter">
                        <div className="products-list__filter-label">
                            <div className="products-list__filter-label-text">Пол:</div>
                        </div>
                        <div className="products-list__filter-action">
                            <div className="products-list__filter-action-selector">
                                <Select
                                    allowClear
                                    style={{width: "100%"}}
                                    placeholder="Выберите пол"
                                    onChange={props.onGenderSelectedChange}
                                    options={getGendersOptions()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        {
            props.isArchive ? 
            <div className="products-list__body-archive">
                {
                    props.products &&
                    props.products.map((item, index) => (
                        <div className="products-list__item-archive" key={item.id}>
                            <div className="products-list__item-archive-card">
                                <ProductCardArchive
                                    item={item}
                                    productUrl={props.productUrl}
                                    onRemove={props.onProductRemove} />
                            </div>
                        </div>
                    ))
                }
            </div> :
            <div className="products-list__body">
                {
                    props.products &&
                    props.products.map((item, index) => (
                        <div className="products-list__item" key={item.id} data-position={props.pageStartIndex + index + 1}>
                            <div className="products-list__item-card">
                                <ProductCard
                                    item={item}
                                    productUrl={props.productUrl}
                                    onRemove={props.onProductRemove} />
                            </div>
                        </div>
                    ))
                }
            </div>
        }

        {
            props.products &&
            props.products.length > 0 &&
            <Pagination
                className="products-list__pagination"
                current={props.page}
                pageSize={props.pageSize}
                onChange={props.onPageChange}
                total={props.productsTotal}
                showSizeChanger={false} />
        }

        {
            props.products &&
            props.products.length > 0 &&
            <ChannelSelectModal
                visible={props.channelSelectVisible}
                channels={props.channels}
                isSending={props.isSending}
                productsCount={props.productsTotal}
                onSubmit={props.onProductsSend}
                onCancel={props.onChannelSelectClose} />
        }


        <DrawerPanel
            title="Редактирование товара"
            width="70%"
            visible={!!props.productId}
            onClose={props.onProductClose}>
            {
                !!props.productId &&
                <ProductView
                    productId={Number(props.productId)}
                    afterSaved={props.onProductSaved}
                    isArchive={props.isArchive} />
            }
        </DrawerPanel>

    </LayoutPage>
);

export default ProductsListTemplate;
