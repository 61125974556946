import React from "react";
import ROUTES from "../../constants/Routes";
import {FilterBlockShape} from "./LookBlockProps";
import {LockFilled, UnlockFilled} from "@ant-design/icons";
import LookDropdown from "../LookDropdown/LookDropdown";

import "./LookBlockStyles.scss";

const LookBlock: React.FC<FilterBlockShape> = (props) => {
    const image = props.lookBlock ? props.lookBlock.image : null;
    const product = props.lookBlock ? props.lookBlock.product : null;
    const filter = props.lookBlock ? props.lookBlock.filter : null;

    return (
        <div className="look-builder-block" data-block-id={props.blockId}>
            <LookDropdown
                className="look-builder-block__select"
                disabled={props.isLocked}
                value={filter?.id || ""}
                blockId={props.blockId}
                onChange={props.onChange}
                onClear={props.onClear}
                filters={props.filters}/>

            {
                product && image &&
                <>
                    <div className="look-builder-block__canvas">
                        <div className="look-builder-block__image">
                            <img src={ROUTES.uploads(image.name)} alt=""/>
                        </div>
                    </div>

                    <div className="look-builder-block__actionbar">
                        <div className="look-builder-block__price">
                            {product.priceDiscount || product.price} ₽
                        </div>

                        <a className="look-builder-block__link" href={product.sourceUrl} target="_blank"
                           rel="noopener noreferrer">
                            <svg version="1.1" x="0px" y="0px" viewBox="0 0 283.922 283.922">
                                <path d="M266.422,0h-97.625c-9.65,0-17.5,7.851-17.5,17.5c0,9.649,7.85,17.5,17.5,17.5h55.377l-92.375,92.374
                                        c-3.307,3.305-5.127,7.699-5.127,12.375c0,4.676,1.819,9.069,5.125,12.371c3.306,3.309,7.699,5.13,12.375,5.13
                                        c4.674,0,9.069-1.82,12.376-5.127l92.374-92.375v55.377c0,9.649,7.851,17.5,17.5,17.5c9.649,0,17.5-7.851,17.5-17.5V17.5
                                        C283.922,7.851,276.071,0,266.422,0z"/>
                                <path d="M201.137,253.922H30V82.785h128.711l30-30H15c-8.284,0-15,6.716-15,15v201.137c0,8.284,6.716,15,15,15h201.137
                                        c8.284,0,15-6.716,15-15V95.211l-30,30V253.922z"/>
                            </svg>
                        </a>

                        <div className="look-builder-block__lock" onClick={props.onLock} data-block-id={props.blockId}>
                            {
                                props.isLocked
                                    ? <LockFilled/>
                                    : <UnlockFilled/>
                            }
                        </div>
                    </div>
                </>
            }
        </div>
    );
};

export default LookBlock;