import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {Input, message, Switch} from "antd";
import React, {FC, useCallback, useEffect, useState} from "react";
import {Color, ColorEmpty} from "../../interfaces/Color";
import DrawerPanelFooter from "../DrawerPanel/DrawerPanelFooter";
import {useColorCreate, useColorLoad, useColorUpdate} from "./ColorEditor-hooks";
import {Body, Foot, Label, Row, Wrap} from "./ColorEditor-styles";
import {ColorEditorProps} from "./ColorEditor-types";

const ColorEditor: FC<ColorEditorProps> = (props) => {
    const {itemId, onSubmit, onCancel} = props;

    const [item, setItem] = useState<Color | ColorEmpty | null>(
        null
    );

    const loadColorData = useColorLoad(itemId);
    const colorCreate = useColorCreate();
    const colorUpdate = useColorUpdate();

    const isColor = (
        value: Color | ColorEmpty
    ): value is Color => {
        return value.id != null;
    };

    const handleColorSubmit = useCallback(async () => {
        if (item) {
            const itemLocal = {...item};

            try {
                const itemCurrent: Color = isColor(itemLocal)
                    ? await colorUpdate(itemLocal)
                    : await colorCreate(itemLocal);
                onSubmit(itemCurrent);
            } catch (err) {
                message.error("При сохранении фильтра возникла ошибка");
            }
        }
    }, [colorCreate, colorUpdate, item, onSubmit]);

    const handleColorCancel = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onCancel(e);
        },
        [onCancel]
    );

    const handleFilterTitleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (item) {
                const title: string = e.currentTarget.value;

                setItem({...item, title});
            }
        },
        [item]
    );

    const handleFilterStatusChange = useCallback(
        (isActive: boolean) => {
            if (item) {
                setItem({...item, isActive});
            }
        },
        [item]
    );

    useEffect(() => {
        loadColorData()
            .then((response) => {
                if (response) {
                    setItem(response.item);
                }
            })
            .catch((err) => console.error(err));
    }, [loadColorData]);

    return (
        <Wrap>
            <Body>
                <Row>
                    <Label>
                        Название цвета
                    </Label>
                    <Input
                        className="color-list__input"
                        value={item?.title}
                        onChange={handleFilterTitleChange}
                    />
                </Row>

                <Row>
                    <Label>Статус</Label>
                    <Switch
                        className="color-list__switch"
                        checked={item?.isActive}
                        onChange={handleFilterStatusChange}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                    />
                </Row>
            </Body>

            <Foot>
                <DrawerPanelFooter
                    textClose="Отмена"
                    textSubmit="Сохранить"
                    onSubmit={handleColorSubmit}
                    onClose={handleColorCancel}
                />
            </Foot>
        </Wrap>
    );
};

export default ColorEditor;