import {HandlerPriseSelectorChange, PriceOptions} from "./../ProductsList/ProductsListShapes";
import {PureComponent} from "react";
import {Props, State, TemplateProps} from "../ProductsList/ProductsListShapes";
import Template from "../ProductsList/ProductsListTemplate";
import FilterModel from "../../models/FilterModel";
import Filter from "../../interfaces/Filter";
import ProductModel from "../../models/ProductModel";
import {ProductSaved} from "../../interfaces/Product";
import {message} from "antd";
import Channel from "../../interfaces/Channel";
import ChannelModel from "../../models/ChannelModel";
import ROUTES from "../../constants/Routes";
import {OnlineStoreUrl} from "../../interfaces/OnlineStore/OnlineStoreUrl";
import {HandlerSelectorChange} from "../../interfaces/Selector";
import AdditionalTagModel from "../../models/AdditionalTagModel";
import ColorModel from "../../models/ColorModel";
import CurrencyService from "../../services/CurrencyService";
import {sortProductById, sortProductPriceAscending, sortProductPriceDescending} from "../ProductsList/ProductsListUtils";
import {isSeason} from "../../interfaces/Season";

export class ProductsArchive extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            products: [],
            filters: [],
            filtersVisible: {},
            selectedFilters: {},
            channels: [],
            channelSelected: 'all',
            channelSelectVisible: false,
            channelToSend: undefined,
            isSending: false,
            page: 1,
            pageSize: 40,
            isBest: false,
            selectedYear: null,
            shopList: {...OnlineStoreUrl},
            shopSelected: null,
            additionalTags: [],
            additionalTagSelected: null,
            colorList: [],
            colorSelected: null,
            seasonSelected: undefined,
            currencyRUB: undefined,
            priceOptions: Object.values(PriceOptions),
            priceOptionsSelected: PriceOptions.default,
            genderSelected: undefined,
        };
    }

    componentDidMount() {
        this.filtersLoadFromServer()
            .catch(err => console.error(err));

        this.productsLoadFromServer()
            .catch(err => console.error(err));

        this.channelsLoadFromServer()
            .catch(err => console.error(err));

        this.additionalTagLoadFromServer()
            .catch(err => console.error(err));

        this.colorsLoadFromServer()
            .catch(err => console.error(err));

        this.currencyRUBLoad()
            .catch(err => console.error(err));
    }

    productsLoadFromServer = async () => {
        try {
            const products = (await ProductModel.getAllArchived()).map(product => {
                return {
                    ...product,
                    dateAdded: new Date(String(product.dateAdded))
                }
            });

            this.productsUpdateInList(products);
        } catch (err) {
            console.error(err)
        }
    }

    productsUpdateInList = (products: ProductSaved[]) => {
        this.setState(state => ({...state, products}));
    };

    filtersLoadFromServer = async () => {
        try {
            const filters = await FilterModel.getAll();
            this.filtersUpdateInList(filters);
        } catch (err) {
            console.error(err)
        }
    }

    filtersUpdateInList = (filters: Filter[]) => {
        this.setState(state => ({
            ...state,
            filters: filters.filter(el => !el.isDeleted)
        }));
    }

    additionalTagLoadFromServer = async () => {
        try {
            const additionalTags = await AdditionalTagModel.getAllActive();

            this.setState(state => ({
                ...state, additionalTags,
            }));
        } catch (err) {
            console.error(err)
        }
    }

    colorsLoadFromServer = async () => {
        try {
            const colorList = await ColorModel.getAllActive();

            this.setState(state => ({
                ...state, colorList,
            }));
        } catch (err) {
            console.error(err);
        }
    }

    currencyRUBLoad = async () => {
        try {
            const currencyRUB = await CurrencyService.getCurrencyRUB();

            this.setState(state => ({
                ...state,
                currencyRUB
            }))
        } catch (err) {
            console.error(err);
        }
    }

    filterClick = (filter: Filter) => {
        const selectedFilters = {...this.state.selectedFilters};

        if (selectedFilters.hasOwnProperty(filter.id)) {
            delete selectedFilters[filter.id];
        } else {
            selectedFilters[filter.id] = "";
        }

        this.setState(state => ({...state, selectedFilters: selectedFilters}));
    };

    changeSelectedFilters = (filtersIds: number[]) => {
        const selectedFilters: State['selectedFilters'] = {};

        filtersIds.forEach(filtersId => {
            selectedFilters[filtersId] = "";
        });

        this.setState(state => ({...state, selectedFilters}));
    };

    get filters() {
        const {channelSelected, filtersVisible} = this.state;
        return typeof channelSelected === 'number'
            ? this.state.filters.filter(el => filtersVisible.hasOwnProperty(el.id))
            : this.state.filters;
    };

    channelClick = (selected: State['channelSelected']) => {
        const filtersVisible: State['filtersVisible'] = {};
        const channelSelected: State['channelSelected'] = this.state.channelSelected === selected
            ? 'all'
            : selected;

        if (typeof channelSelected === 'number') {
            const channel = this.state.channels.find(el => el.id === selected);

            if (channel) {
                channel.filters.forEach(filter => {
                    filtersVisible[filter.id] = "";
                });
            }
        }

        this.setState(state => ({
            ...state,
            page: 1,
            channelSelected,
            filtersVisible,
        }));
    };

    productRemove = async (item: ProductSaved) => {
        try {
            const products = [...this.state.products];
            const index = products.findIndex(el => el.id === item.id);
            if (index >= 0) {
                const product = products.splice(index, 1)[0];
                await ProductModel.remove(product.id);

                this.setState(state => ({...state, products}), () => {
                    message.success(`Товар "${product.title}" успешно удален`);
                });
            }
        } catch (err) {
            message.error('Во время удаления возникла ошибка');
            console.error(err.message);
        }

    }

    channelsLoadFromServer = async () => {
        try {
            const channels = await ChannelModel.getAll();
            channels.map(channel => ({
                ...channel,
                filters: channel.filters.filter(el => !el.isDeleted)
            }));
            this.channelsUpdateInList(channels);
        } catch (err) {
            console.error('При загрузке telegram-каналов произошла ошибка: ' + err);
        }
    };

    channelsUpdateInList = (channels: Channel[]) => {
        this.setState(state => ({
            ...state,
            channels: channels.filter(el => !el.isActive)
        }));
    };


    handleChannelSelectOpen = () => { }

    handleChannelSelectClose = () => { }

    handleProductsSend = () => {
        message.error('Вы не можете отправлять в канал товары из архива');
    };

    handleProductClose = () => {
        this.props.history.push(ROUTES.archive.replace(':id?', ''));
    };

    handleProductSaved = (/*product: ProductSaved*/) => {
        message.error('Вы не можете изменять товары в архиве');
    };

    handlePageChange = (page: number) => {
        this.setState(state => ({...state, page}), () => {
            document.getElementById('container')?.scrollTo(0, 50);
        });
    };

    handleBestChange: TemplateProps["onBestChange"] = () => {
        this.setState(prevState => ({...prevState, isBest: !prevState.isBest}));
    }

    handleDateChange: TemplateProps["onDateChange"] = (date) => {
        const selectedYear = date ? date.year() : null;

        this.setState(prevState => ({...prevState, selectedYear}));
    }

    handleShopSelectedChange: HandlerSelectorChange = (shop) => {
        const shopSelected = shop || null;

        this.setState(state => ({...state, shopSelected}));
    }

    handleAdditionalTagSelectedChange: HandlerSelectorChange = (additionalTag) => {
        const additionalTagSelected = additionalTag || null;

        this.setState(state => ({...state, additionalTagSelected}));
    }

    handleColorsSelectedChange: HandlerSelectorChange = (color) => {
        const colorSelected = color || null;

        this.setState(state => ({...state, colorSelected}));
    }

    handleSeasonSelectedChange: HandlerSelectorChange = (season) => {
        if (!isSeason(season) && season !== undefined) {
            return;
        }
        this.setState(state => ({...state, seasonSelected: season}));
    }

    handlePriceOptionsSelectedChange: HandlerPriseSelectorChange = (priceOptions) => {
        const priceOptionsSelected = priceOptions;

        this.setState(state => ({...state, priceOptionsSelected}));
    }

    handleGenderSelectedChange: TemplateProps["onGenderSelectedChange"] = (gender) => {
        this.setState(state => ({...state, genderSelected: gender}));
    }

    get products() {
        const {selectedFilters: filtersSelected, channelSelected} = this.state;
        const filtersLength = Object.keys(filtersSelected).length;
        const currencyRUB = this.state.currencyRUB;

        let items: ProductSaved[];

        if (filtersLength > 0 && channelSelected !== 'all') {
            items = this.state.products.filter(({filters, channels}) => {
                const hasFilter = filters.some(el => filtersSelected.hasOwnProperty(el.id));
                const hasChannel = channelSelected === 'empty'
                    ? channels.length === 0
                    : channels.some(el => channelSelected === el.id);

                return hasFilter && hasChannel;
            });

        } else if (filtersLength > 0) {
            items = this.state.products.filter(({filters}) => {
                return filters.some(el => filtersSelected.hasOwnProperty(el.id))
            });

        } else if (channelSelected !== 'all') {
            items = this.state.products.filter(({channels}) => {
                return channelSelected === 'empty'
                    ? channels.length === 0
                    : channels.some(el => channelSelected === el.id)
            });

        } else {
            items = this.state.products;
        }

        if (this.state.isBest) {
            items = items.filter(item => item.isBest);
        }

        if (this.state.selectedYear) {
            items = items.filter(item => item.dateAdded?.getFullYear() === this.state.selectedYear);
        }

        if (this.state.shopSelected) {
            items = items.filter(item => item.source === this.state.shopSelected);
        }

        if (this.state.additionalTagSelected) {
            items = items.filter(item => {
                const isTagPresence = item.additionalTag?.some(tag => tag.title === this.state.additionalTagSelected);

                return isTagPresence;
            });
        }

        if (this.state.colorSelected) {
            items = items.filter(item => item.colorSelect?.title === this.state.colorSelected);
        }

        if (this.state.seasonSelected) {
            items = items.filter(item => item.season === this.state.seasonSelected);
        }

        if (this.state.genderSelected) {
            items = items.filter((item) => {
                return item.channels.some((channel) => channel.gender === this.state.genderSelected);
            });
        }

        if (this.state.priceOptionsSelected && currencyRUB) {
            switch (this.state.priceOptionsSelected) {
                case PriceOptions.ascending:
                    items = items.sort((itemA, itemB) => sortProductPriceAscending(itemA, itemB, currencyRUB));

                    break;
                case PriceOptions.descending:
                    items = items.sort((itemA, itemB) => sortProductPriceDescending(itemA, itemB, currencyRUB));

                    break;
                default:
                    items = items.sort(sortProductById);

                    break;
            }
        }

        return items;
    }

    get pageStartIndex(): number {
        return (this.state.page - 1) * this.state.pageSize;
    }

    get pageEndIndex(): number {
        return this.state.page * this.state.pageSize;
    }

    render() {
        return Template({
            ...this.state,
            isArchive: true,
            title: "Архив товаров",
            productId: this.props.match.params.id,
            productUrl: ROUTES.archive,
            productsTotal: this.state.products ? this.products.length : 0,
            products: this.products.slice(this.pageStartIndex, this.pageEndIndex),
            filters: this.filters,
            onFilterClick: this.filterClick,
            onChannelClick: this.channelClick,
            onProductRemove: this.productRemove,
            onProductsSend: this.handleProductsSend,
            onChannelSelectOpen: this.handleChannelSelectOpen,
            onChannelSelectClose: this.handleChannelSelectClose,
            onChangeSelectedFilters: this.changeSelectedFilters,
            onBestChange: this.handleBestChange,
            onDateChange: this.handleDateChange,
            onShopSelectedChange: this.handleShopSelectedChange,
            onAdditionalTagSelectedChange: this.handleAdditionalTagSelectedChange,
            onColorsSelectedChange: this.handleColorsSelectedChange,
            onPriceOptionsSelectedChange: this.handlePriceOptionsSelectedChange,
            onSeasonSelectedChange: this.handleSeasonSelectedChange,
            onGenderSelectedChange: this.handleGenderSelectedChange,

            onProductClose: this.handleProductClose,
            onProductSaved: this.handleProductSaved,

            pageStartIndex: this.pageStartIndex,
            onPageChange: this.handlePageChange,
        });
    };
}

export default ProductsArchive;
