import React from "react";
import {RouteComponentProps} from 'react-router-dom';
import {Redirect, RoutePrivate, Switch} from "../../components/Routing";
import LayoutPrivate from "../../components/LayoutPrivate/LayoutPrivate";
import RouteOption from "../../interfaces/RouteOption";
import ROUTES from "../../constants/Routes";
import ProductParser from "../ProductParser/ProductParser";
import ProductCreator from "../ProductCreator/ProductCreator";
import ProductsList from "../ProductsList/ProductsList";
import ChannelsList from "../ChannelsList/ChannelsList";
import FiltersList from "../FiltersList/FiltersList";
import ProductsArchive from "../ProductsArchive/ProductsArchive";
import DraftsList from "../DraftsList/DraftsList";
import LookPresetList from "../LookPresetList/LookPresetList";
import AdditionalTagList from "../AdditionalTagList/AdditionalTagList";
import ColorListList from "../ColorList/ColorList";
import ProductsArchiveLooks from "../ProductsArchiveLooks/ProductsArchiveLooks";

const privateApp: RouteOption[] = [
    {path: ROUTES.archive, component: ProductsArchive},
    {path: ROUTES.productParser, component: ProductParser},
    {path: ROUTES.productCreator, component: ProductCreator},
    {path: ROUTES.products, component: ProductsList},
    {path: ROUTES.drafts, component: DraftsList},
    {path: ROUTES.channels, component: ChannelsList},
    {path: ROUTES.filters, component: FiltersList},
    {path: ROUTES.lookPreset, component: LookPresetList},
    {path: ROUTES.additionalTag, component: AdditionalTagList},
    {path: ROUTES.colors, component: ColorListList},
    {path: ROUTES.archiveLooks, component: ProductsArchiveLooks},
];

const AppPrivate: React.FC<RouteComponentProps> = () => (
    <LayoutPrivate>
        <Switch>
            {
                privateApp.map(route => (
                    <RoutePrivate {...route} key={route.path} />
                ))
            }
            <Redirect to={ROUTES.products} />
        </Switch>
    </LayoutPrivate>
);

export default AppPrivate;