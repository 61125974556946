import React from "react";
import {TemplateProps} from "./LooksListShapes";
import LayoutPage from "../../components/LayoutPage/LayoutPage";
import LookCard from "../../components/LookCard/LookCard";
import "./LooksListStyles.scss";
import {Link} from "../../components/Routing";
import ROUTES from "../../constants/Routes";

const LooksListTemplate: React.FC<TemplateProps> = (props) => (
    <LayoutPage className="looks-list" title="Сохраненные образы" extra={
        <Link to={ROUTES.looksBuilder}>Создать новый образ</Link>
    }>

        <div className="looks-list__body products-list__body">
            {
                props.items.map((item, index) => (
                    <div className="products-list__item" key={item.id} data-position={index + 1}>
                        <div className="products-list__item-card">
                            <LookCard item={item} onRemove={props.lookRemove} />
                        </div>
                    </div>
                ))
            }
        </div>
    </LayoutPage>
);

export default LooksListTemplate;