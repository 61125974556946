import React, {useEffect, useState} from "react";
import {ProductGalleryProps} from "./ProductGallery-types";
import {Popconfirm} from "antd";
import {CloseCircleFilled} from "@ant-design/icons";
import "./ProductGalleryStyles.scss";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useFilterLoad} from "./ProductGallery-hooks";
import Attachment from "../../interfaces/Attachment";
import {Item, ItemImage, ItemLoading, ItemRemove, Content} from "./ProductGallery-styles";

const ProductGallery: React.FC<ProductGalleryProps> = (props) => {
    const {images, onImageRemove, onImageSelect, onUpdatePosition} = props;

    const [finalImages, setFinalImages] = useState<Attachment[]>(images);

    useEffect(() => {
        setFinalImages(images);
    }, [images])


    const handleDragEnd = useFilterLoad(finalImages, setFinalImages, onUpdatePosition);

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="images">
                {(provider) => {
                    return (
                        <Content
                            {...provider.droppableProps}
                            ref={provider.innerRef}>
                            {
                                finalImages.map((image, index) => {
                                    const imageName = image.name ? image.name : image.link;

                                    return (
                                        <Draggable
                                            key={imageName}
                                            draggableId={String(imageName)}
                                            index={index}>
                                            {(provider) => (
                                                <Item
                                                    key={imageName}
                                                    {...provider.draggableProps}
                                                    {...provider.dragHandleProps}
                                                    IsTelegram={image.toTelegram}
                                                    ref={provider.innerRef}>
                                                    {
                                                        image.link.substring(0, 5) !== 'blob:' &&
                                                        <Popconfirm title="Удалить?" onConfirm={(e) => onImageRemove(image.link, e)}>
                                                            <ItemRemove>
                                                                <CloseCircleFilled style={{color: "#ff4d4f"}} />
                                                            </ItemRemove>
                                                        </Popconfirm>
                                                    }
                                                    {
                                                        image.link.substring(0, 5) === 'blob:' &&
                                                        <ItemLoading />
                                                    }
                                                    <ItemImage
                                                        style={{backgroundImage: `url(${image.link})`}}
                                                        onClick={onImageSelect}
                                                        data-link={image.link} />
                                                </Item>
                                            )}
                                        </Draggable>
                                    )
                                })
                            }
                            {provider.placeholder}
                        </Content>
                    )
                }}
            </Droppable>
        </DragDropContext>
    )
};

export default ProductGallery;
