import React, {FC, memo} from 'react';
import {Body, CheckboxWrap, Handler, Wrap} from './CascaderCheckbox-styles';
import {CascaderCheckboxProps} from './CascaderCheckbox-types';


const CascaderCheckbox: FC<CascaderCheckboxProps> = (props) => {
    const {children, onCheck, item, checkedIds} = props;

    const isChecked = checkedIds?.includes(item.key);

    return (
        <Wrap>
            <Handler>
                {
                    onCheck &&
                    <CheckboxWrap onChange={onCheck} value={item.key} checked={isChecked} />
                }
            </Handler>
            <Body>
                    {children}
            </Body>
        </Wrap>
    )
};

export default memo(CascaderCheckbox);
