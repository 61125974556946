import {AdditionalTag} from "./../../interfaces/AdditionalTag";
import {PureComponent} from "react";
import {onProductEditorSelectChange, Props, State, TemplateProps} from "./ProductEditorShapes";
import Template from "./ProductEditorTemplate";
import {Product} from "../../interfaces/Product";
import {message} from "antd";
import ProductModel from "../../models/ProductModel";
import HandlerTextInputChange from "../../interfaces/HandlerTextInputChange";
import Filter from "../../interfaces/Filter";
import FilterModel from "../../models/FilterModel";
import Channel from "../../interfaces/Channel";
import ChannelModel from "../../models/ChannelModel";
import Attachment from "../../interfaces/Attachment";
import {ProductGalleryImageClick, ProductGalleryImageRemove} from "../ProductGallery/ProductGallery-types";
import {isValidURL} from "../../helpers/StringUtils";
import {OnlineStoreUrl} from "../../interfaces/OnlineStore/OnlineStoreUrl";
import {isOnlineStoreName} from "../../interfaces/OnlineStore/OnlineStoreName";
import {DatePickerProps} from "antd/lib/date-picker";
import AdditionalTagModel from "../../models/AdditionalTagModel";
import {productConvertForTransferData, productFollowerFound, productsLoadFromServerUtil} from "../../helpers/ProductUtils";
import ColorModel from "../../models/ColorModel";

const allowedImageExtensions = ["jpg", "jpeg", "png", "webp"];

export class ProductEditor extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            product: null,
            products: [],
            productsForTransfer: [],
            store: "",
            storesList: {...OnlineStoreUrl},
            currency: "",
            filtersList: [],
            channelsList: [],
            selectedFilters: {},
            selectedChannels: {},
            sourceCustom: "",
            isSourceCustomVisible: false,
            additionalTagList: [],
            colorsList: [],
        };
    }

    componentDidMount() {
        this.initProduct().then()
            .catch(err => console.error(err));

        this.initFilters().then()
            .catch(err => console.error(err));

        this.initChannels().then()
            .catch(err => console.error(err));

        this.initProducts().then()
            .catch(err => console.error(err));

        this.initAdditionalTag().then()
            .catch(err => console.error(err));

        this.initColor().then()
            .catch(err => console.error(err));
    }


    // INITIALIZERS


    initProduct = async () => {
        try {
            const product: Product = await ProductModel.createBlank();

            this.setState((state) => ({...state, product}));
        } catch (err) {
            message.error("При инициализации товара произошла ошибка");
        }
    };

    initFilters = async () => {
        try {
            const filtersList = await FilterModel.getAllActive();

            this.setState((state) => ({...state, filtersList}));
        } catch (err) {
            message.error("При загрузке фильтров произошла ошибка");
        }
    };

    initChannels = async () => {
        try {
            const channelsList = await ChannelModel.getAll();

            this.setState((state) => ({...state, channelsList: channelsList.filter(el => el.isActive)}));
        } catch (err) {
            message.error("При загрузке списка доступных каналов произошла ошибка");
        }
    }

    initAdditionalTag = async () => {
        try {
            const additionalTag = await AdditionalTagModel.getAllActive();

            this.setState((state) => ({...state, additionalTagList: additionalTag}));
        } catch (err) {
            message.error("При загрузке дополнительных тегов произошла ошибка");
        }
    }

    initProducts = async () => {
        const products = await productsLoadFromServerUtil();
        const productsForTransfer = products?.map(productConvertForTransferData);

        if (productsForTransfer && products) {
            this.setState(state => ({
                ...state,
                productsForTransfer,
                products,
            }));
        }
    }

    initColor = async () => {
        try {
            const colorsList = await ColorModel.getAllActive();

            this.setState((state) => ({...state, colorsList}));
        } catch (err) {
            message.error("При загрузке дополнительных тегов произошла ошибка");
        }
    }


    // HELPERS


    productInitCheck = (cb: (product: Product) => void, sbError?: () => void) => {
        if (this.state.product != null) {
            cb({...this.state.product});
        } else {
            message.error("С товаром какие-то проблемки, он не прогрузился!");
            sbError && sbError();
        }
    };

    productPriceCut = (price: string): string => {
        return price.replace(/[^\d.]/g, "");
    }


    // HANDLERS


    selectImage: ProductGalleryImageClick = (e) => {
        this.productInitCheck((product: Product) => {
            const link = e.currentTarget.dataset.link || null;

            if (link != null) {
                product.images = product.images.map(el => el.link === link ? {...el, toTelegram: !el.toTelegram} : el);
                this.setState((state) => ({...state, product}));
            }
        });
    };

    updatePositionImages = (images: Attachment[]) => {
        this.productInitCheck((product: Product) => {
            product.images = images;

            this.setState((state) => ({...state, product}));
        });
    }

    removeImage: ProductGalleryImageRemove = (link) => {
        this.productInitCheck((product: Product) => {
            product.images = product.images.filter(el => el.link !== link);
            this.setState((state) => ({...state, product}));
        });
    };

    changedImages = (images: Attachment[]) => {
        const checkedImages: Attachment[] = [];

        for (let index = 0; index < images.length; index++) {
            const image = {...images[index]};
            const imageExtension = image.name.split(".").pop()?.toLowerCase();

            const isExtensionAllowed = allowedImageExtensions.some((extension) => extension === imageExtension);

            if (isExtensionAllowed) {
                checkedImages.push(image);
            } else {
                message.error(`${image.name} данный тип файла не поддерживается`);
            }
        }

        this.productInitCheck((product: Product) => {
            product.images = checkedImages;
            this.setState((state) => ({...state, product}));
        });
    }

    changeVendorCode: HandlerTextInputChange = (e) => {
        const value = e.target.value;

        this.productInitCheck((product: Product) => {
            product.vendorCode = value;
            this.setState((state) => ({...state, product}));
        });
    };

    changeSource: onProductEditorSelectChange = (source: "custom" | string) => {
        if (source === "custom" || isOnlineStoreName(source)) {
            this.productInitCheck((product: Product) => {
                this.setState((state) => ({
                    ...state,
                    product: {...product, source: source},
                    isSourceCustomVisible: source === "custom"
                }));
            });
        } else {
            throw new Error("changeSource: source is out of available list");
        }
    };

    changeSourceCustom: HandlerTextInputChange = (e) => {
        const sourceCustom = e.target.value;
        this.setState((state) => ({...state, sourceCustom}));
    };

    changeSourceUrl: HandlerTextInputChange = (e) => {
        const value = e.target.value;

        this.productInitCheck((product: Product) => {
            product.sourceUrl = value;
            this.setState((state) => ({...state, product}));
        });
    };

    changeTitle: HandlerTextInputChange = (e) => {
        const value = e.target.value;

        this.productInitCheck((product: Product) => {
            product.title = value;
            this.setState((state) => ({...state, product}));
        });
    }

    changePrice: HandlerTextInputChange = (e) => {
        const value = e.target.value;

        this.productInitCheck((product: Product) => {
            product.price = this.productPriceCut(value);
            this.setState((state) => ({...state, product}));
        });
    }

    changePriceDiscount: HandlerTextInputChange = (e) => {
        const value = e.target.value;

        this.productInitCheck((product: Product) => {
            product.priceDiscount = this.productPriceCut(value);
            this.setState((state) => ({...state, product}));
        });
    }

    changeColor: HandlerTextInputChange = (e) => {
        const value = e.target.value;

        this.productInitCheck((product: Product) => {
            product.color = value;
            this.setState((state) => ({...state, product}));
        });
    }

    changeComposition: HandlerTextInputChange = (e) => {
        const value = e.target.value;

        this.productInitCheck((product: Product) => {
            product.composition = value;
            this.setState((state) => ({...state, product}));
        });
    }

    handleAdditionalTagChange: TemplateProps["onAdditionalTagChange"] = (values) => {
        this.productInitCheck((product: Product) => {
            const resultProduct = {...product};
            const resultAdditionalTag: AdditionalTag[] = [];
            const additionalTag = [...this.state.additionalTagList];

            for (let index = 0; index < additionalTag.length; index++) {
                const element = additionalTag[index];

                const result = values.find(value => value === element.title);

                if (result) {
                    resultAdditionalTag.push(element);
                }
            }

            resultProduct.additionalTag = [...resultAdditionalTag];

            this.setState(state => ({...state, product: resultProduct}));
        });
    }


    clickFilter = (filter: Filter, isChecked: boolean) => {
        const selectedFilters = {...this.state.selectedFilters};

        if (selectedFilters.hasOwnProperty(filter.id)) {
            delete selectedFilters[filter.id];
        } else {
            selectedFilters[filter.id] = "";
        }

        this.setState(state => ({...state, selectedFilters}));
    };

    changeSelectedFilters = (filtersIds: number[]) => {
        const selectedFilters: State["selectedFilters"] = {};

        filtersIds.forEach(filtersId => {
            selectedFilters[filtersId] = "";
        });

        this.setState(state => ({...state, selectedFilters}));
    };

    clickChannel = (channel: Channel, isChecked: boolean) => {
        const selectedChannels = {...this.state.selectedChannels};

        if (selectedChannels.hasOwnProperty(channel.id)) {
            delete selectedChannels[channel.id];
        } else {
            selectedChannels[channel.id] = "";
        }

        this.setState(state => ({...state, selectedChannels}));
    };

    handleDateAddChange: DatePickerProps["onChange"] = (date, dateString) => {
        this.productInitCheck((product: Product) => {
            product.dateAdded = date?.toDate();

            this.setState(state => ({...state, product}));
        });
    }

    handleBestChange: TemplateProps["onBestChange"] = (e) => {
        this.productInitCheck((product: Product) => {
            product.isBest = !product.isBest;

            this.setState(state => ({...state, product}));
        });
    }

    handleProductFollowersChange: TemplateProps['onProductFollowersChange'] = (items) => {
        this.productInitCheck((product: Product) => {
            const localProduct = {...product};
            const products = [...this.state.products];

            if (localProduct.followers) {
                localProduct.followers = productFollowerFound(items, products)
            }

            this.setState(state => ({...state, product: localProduct}));
        });
    }

    handleColorSelectedChange: TemplateProps["onColorSelectedChange"] = (values) => {
        this.productInitCheck((product: Product) => {
            const resultProduct = {...product};

            const resultColor = this.state.colorsList.find(color => {
                return color.title === values;
            });

            if (resultColor) {
                resultProduct.colorSelect = {...resultColor};

                this.setState(state => ({...state, product: resultProduct}));
            }
        })
    }

    handleSeasonChange: TemplateProps["onSeasonSelectedChange"] = (season) => {
        this.setState(state => {
            return state.product
                ? {...state, product: {...state.product, season}}
                : state
        })
    }

    handleColorSelectedSearch: TemplateProps["onColorSelectedSearch"] = (values) => {
    }

    formSubmit = () => {
        this.productInitCheck((product: Product) => {

            if (!this.formValidate(product)) {
                return;
            }

            product.source = product.source === "custom"
                ? this.state.sourceCustom
                : product.source;

            const filters = this.state.filtersList.filter(el => this.state.selectedFilters.hasOwnProperty(el.id));
            const channels = this.state.channelsList.filter(el => this.state.selectedChannels.hasOwnProperty(el.id));

            this.props.onSubmit(product, filters, channels);
        })
    };

    formValidate = (product: Product): boolean => {

        if (product.images.length <= 0) {
            message.error("Загрузите фотографии товара!");
            return false;
        }

        const imagesLoading = product.images.filter(image => image.link.substring(0, 5) === "blob:");
        const imagesToTelegram = product.images.filter(image => image.toTelegram);

        const isChannelsSelected = Object.keys(this.state.selectedChannels).length > 0;

        if (imagesLoading.length > 0) {
            message.info("Дождитесь завершения загрузки изображений!");
            return false;
        }

        if (imagesToTelegram.length <= 0) {
            message.error("Выберите хотя бы одно изображение для отправки в телеграм");
            return false;
        }

        if (!product.source) {
            message.error("Выберите магазин!");
            return false;
        } else if (product.source === "custom" && !this.state.sourceCustom) {
            message.error("Введите название магазина!");
            return false;
        }

        if (!product.sourceUrl || !isValidURL(product.sourceUrl)) {
            message.error("Добавьте корректную ссылку на товар!");
            return false;
        }

        if (!product.title.trim()) {
            message.error("Введите название товара!");
            return false;
        }

        if (!product.price || isNaN(Number(product.price)) || Number(product.price) <= 0) {
            message.error("Введите кооректную стоимость товара!");
            return false;

        } else if (product.priceDiscount && Number(product.priceDiscount) >= Number(product.price)) {
            message.error("Кажется, что стоимость со скидкой должна быть меньше обычной стоиомости!");
            return false;
        }

        if(!isChannelsSelected) {
            message.error('Выберите хотя бы один "Канал"');
            return false;
        }

        return true;
    };

    formCancel = () => {
        this.props.onCancel();
    };


    showError = () => {
        const max = 3;
        const min = 1;
        setTimeout(() => {
            message.error("При сохранении товара произошла ошибка!");
        }, Math.floor(Math.random() * (max - min + 1) + min) * 1000)
    }

    render() {
        return Template({
            ...this.state,
            onSelectImage: this.selectImage,
            onRemoveImage: this.removeImage,
            onChangedImages: this.changedImages,
            onUpdatePositionImages: this.updatePositionImages,

            onChangeVendorCode: this.changeVendorCode,
            onChangeSource: this.changeSource,
            onChangeSourceCustom: this.changeSourceCustom,
            onChangeSourceUrl: this.changeSourceUrl,
            onChangeTitle: this.changeTitle,
            onChangePrice: this.changePrice,
            onChangePriceDiscount: this.changePriceDiscount,
            onChangeColor: this.changeColor,
            onChangeComposition: this.changeComposition,
            onChangeSelectedFilters: this.changeSelectedFilters,
            onDateAddChange: this.handleDateAddChange,
            onBestChange: this.handleBestChange,
            onAdditionalTagChange: this.handleAdditionalTagChange,
            onProductFollowersChange: this.handleProductFollowersChange,
            onColorSelectedChange: this.handleColorSelectedChange,
            onColorSelectedSearch: this.handleColorSelectedSearch,
            onSeasonSelectedChange: this.handleSeasonChange,

            onClickFilter: this.clickFilter,
            onClickChannel: this.clickChannel,


            onFormSubmit: this.formSubmit,
            onFormCancel: this.formCancel,

            showError: this.showError,
        });
    };
}

export default ProductEditor;
