import {useCallback} from 'react';
import {DropResult} from 'react-beautiful-dnd';
import Attachment from "../../interfaces/Attachment";
import {ProductGalleryUpdate} from "./ProductGallery-types";

export const useFilterLoad = (
    finalImages: Attachment[],
    setFinalImages: (value: Attachment[]) => void,
    onUpdatePosition?: ProductGalleryUpdate,

) => {
    return useCallback((result: DropResult) => {
        if (result.destination) {
            const destinationIndex = result.destination.index;
            const sourceIndex = result.source.index;
            let items = [...finalImages];

            const itemsId = items.map(item => {
                return (
                    item.id
                )
            }).sort();

            const [recorderItem] = items.splice(sourceIndex, 1);

            items.splice(destinationIndex, 0, recorderItem);

            items = items.map((item, index) => {
                return {
                    ...item,
                    id: itemsId[index],
                }
            });

            setFinalImages(items);

            if (onUpdatePosition) {
                onUpdatePosition(items);
            }

        }
    }, [finalImages, setFinalImages, onUpdatePosition])
};
