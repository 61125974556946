import {
    CloseSquareOutlined,
    CopyOutlined,
    FormOutlined,
} from "@ant-design/icons";
import {message, Popconfirm} from "antd";
import React, {FC, MouseEvent, MouseEventHandler, useRef} from "react";
import {Link} from "react-router-dom";
import ROUTES from "../../constants/Routes";
import {stopPropagation} from "../../helpers/ActionUtils";
import {getCurrencySymbol} from "../../helpers/CurrencyTools";
import {ProductCardArchiveProps} from "./ProductCardArchiveShapes";
import "./ProductCardArchiveStyles.scss";

const ProductCardArchive: FC<ProductCardArchiveProps> = (props) => {
    const {item, productUrl = ROUTES.products, onRemove} = props;

    const productLinkElement = useRef<HTMLDivElement>(null);

    const handleRemove = (event?: MouseEvent<HTMLElement>) => {
        if (event) {
            event.stopPropagation();
            onRemove(item);
        }
    };

    const handleCopyLink: MouseEventHandler<HTMLDivElement> = (event) => {
        const productLink = productLinkElement.current?.textContent;

        if (productLink) {
            navigator.clipboard.writeText(productLink);

            message.success("Ссылка скопирована");
        } else {
            message.error("Ссылка не найдена");
        }
    };

    return (
        <div className="product-card-archive" data-source={item.source}>
            <div className="product-card-archive__body">
                {
                    <Link
                        className="product-card-archive__link"
                        to={productUrl.replace(":id?", item.id.toString())}
                    >
                        <div
                            className="product-card-archive__image"
                            key={item.images[0].name}
                            style={{
                                backgroundImage: `url(${ROUTES.uploads(
                                    item.images[0].name
                                )})`,
                            }}
                        />
                    </Link>
                }

                <div className="product-card-archive__info">
                    <div className="product-card-archive__info-wrap">
                        <div className="product-card-archive__actions">
                            <Link
                                className="product-card-archive__edit"
                                title="Редактировать"
                                to={productUrl.replace(
                                    ":id?",
                                    item.id.toString()
                                )}
                            >
                                <FormOutlined />
                            </Link>

                            <Popconfirm
                                title="Вы точно хотите удалить?"
                                onConfirm={handleRemove}
                                onCancel={stopPropagation}
                            >
                                <button
                                    className="product-card-archive__remove"
                                    title="Удалить"
                                    onClick={stopPropagation}
                                >
                                    <CloseSquareOutlined />
                                </button>
                            </Popconfirm>
                        </div>

                        <div className="product-card-archive__title">
                            {item.title}
                        </div>

                        <div className="product-card-archive__price">
                            {item.priceDiscount || item.price}{" "}
                            {getCurrencySymbol(item.currency)}
                        </div>

                        <div className="product-card-archive__url">
                            <div
                                className="product-card-archive__url-link"
                                ref={productLinkElement}
                            >
                                {item.sourceUrl}
                            </div>

                            <div
                                className="product-card-archive__url-icon"
                                onClick={handleCopyLink}
                            >
                                <CopyOutlined />
                            </div>
                        </div>

                        <div className="product-card-archive__store">
                            #{item.source}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductCardArchive;
