import React, {useEffect, useState} from "react";
import {Props} from "./LayoutPublicShapes";
import TopbarDivider from "../TopbarDivider/TopbarDivider";
import TopbarUser from "../TopbarUser/TopbarUser";
import TopbarMenu from "../TopbarMenu/TopbarMenu";
import {Layout, message} from "antd";
import AuthService from "../../services/AuthService";
import User from "../../interfaces/User";
import ROUTES from "../../constants/Routes";
import {withRouter} from "react-router";
import {TopbarMenuItem} from "../../interfaces/TopbarMenuItem";

import "./LayoutPublicStyles.scss";


const LayoutPublic: React.FC<Props> = (props) => {
    const [user, setUser] = useState<User | null>(null);
    const [menu, setMenu] = useState<TopbarMenuItem[]>([]);

    const handleLogout = async () => {
        await AuthService.logout();
        message.success('Вы успешно разлогинились!');
        props.history.push(ROUTES.login);
    };

    useEffect(() => {
        const isAdmin = AuthService.hasPrivateAccess();
        setMenu(isAdmin
            ? [{url: ROUTES.looks, title: "Публичка"}, {url: ROUTES.privateApp, title: "Админка"}]
            : [{url: ROUTES.looks, title: "Образы"}]
        );

        const authData: User | null = AuthService.getAuthData();
        setUser(authData);
    }, []);

    return (
        <Layout className="layout">
            <Layout.Header className="packtum-layout-header">
                <div className="packtum-layout-header__logo">
                    КЕНТ <span>по товарам</span>
                </div>

                <TopbarDivider/>

                <TopbarMenu className="packtum-layout-header__menu" items={menu}/>

                <TopbarDivider/>


                <div className="packtum-layout-header__search">
                    {/*    /!*<TopbarSearch/>*!/*/}
                </div>

                {/*<TopbarDivider />*/}

                {/*<div className="packtum-layout-header__informer">*/}
                {/*    <TopbarInformer count={props.informerCount} />*/}
                {/*</div>*/}

                <TopbarDivider/>

                <div className="packtum-layout-header__user">
                    {
                        user &&
                        <TopbarUser
                            username={
                                (user.lastName || user.firstName
                                        ? [user.firstName, user.lastName].filter(Boolean).join(' ')
                                        : user.email || `@${user.accTelegram}`
                                )}
                            company={user.email || `@${user.accTelegram}`}
                            onLogout={handleLogout}/>
                    }
                </div>


            </Layout.Header>
            <Layout style={{padding: '0', marginTop: 64}}>

                <Layout id="container" style={{padding: 24}}>
                    {/*<Breadcrumb style={{margin: '16px 0'}}>*/}
                    {/*    <Breadcrumb.Item>Home</Breadcrumb.Item>*/}
                    {/*    <Breadcrumb.Item>List</Breadcrumb.Item>*/}
                    {/*    <Breadcrumb.Item>App</Breadcrumb.Item>*/}
                    {/*</Breadcrumb>*/}
                    <Layout.Content style={{padding: 24, margin: 0, minHeight: 'auto', background: '#fff'}}>
                        {props.children}
                    </Layout.Content>
                </Layout>
            </Layout>
        </Layout>
    );
}

export default withRouter(LayoutPublic);