import {Checkbox} from 'antd';
import styled from 'styled-components';
import {IsDisabled} from "../../../interfaces/BaseTypes";

export const Wrap = styled.div<IsDisabled>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    position: relative;
`;

export const Handler = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    width: 30px;
    left: 0;
    top: 0;
    bottom: 0;
`;

export const Body = styled.div`
    width: 100%;
`;

export const CheckboxWrap = styled(Checkbox)`
    padding: 10px 0 10px 10px;
`;
