import React from "react";
import {LookCardArchiveShapes} from "./LookCardArchiveShapes";
import ROUTES from "../../constants/Routes";
import {Link} from "../Routing";
import "./LookCardArchive.scss";

const LookCardArchive: React.FC<LookCardArchiveShapes> = (props) => {
    return (
        <div className="look-card-archive__head">
            <Link to={ROUTES.archive.replace(':id?', props.product.id?.toString() || "")}>
                <div className="look-card-archive__image"
                     style={{backgroundImage: `url(${ROUTES.uploads(props.product.images[0].name)})`}}
                     title={props.product.title}
                     key={props.product.id}
                ></div>
            </Link>
            {
                props.product.followers &&
                props.product.followers.length > 0 && (
                    Object.values(props.product.followers).map(follower => (
                        <Link to={ROUTES.archive.replace(':id?', follower.id?.toString() || "")}>
                            <div className="look-card-archive__image"
                                 style={{backgroundImage: `url(${ROUTES.uploads(follower.images[0].name)})`}}
                                 title={follower.title}
                                 key={follower.id}
                            ></div>
                        </Link>
                    ))
                )
            }
        </div>
    );
}

export default LookCardArchive;