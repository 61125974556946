import React, {FC, memo, useEffect, useState} from "react";
import {CascaderListViewProps} from './CascaderListView-types';
import CascaderNode from '../CascaderNode/CascaderNode';
import {Body, Wrap} from './CascaderListView-styles';
import {CascaderTree} from '../Cascader-types';
import CascaderCheckbox from '../CascaderCheckBox/CascaderCheckbox';


const CascaderListView: FC<CascaderListViewProps> = memo((props) => {
    const {dataCascade, cascadeIds, checkedIds, isEdit, isCheckbox, onClick, onCheck} = props;

    const [finalDataCascade, setFinalDataCascade] = useState<CascaderTree[]>(dataCascade);

    useEffect(() => {
        setFinalDataCascade(dataCascade);
    }, [dataCascade]);

    return (
        <Wrap>
            {
                finalDataCascade?.map(item => {
                    return (
                        <Body key={item.key}>
                            <CascaderCheckbox
                                key={item.key}
                                item={item}
                                checkedIds={checkedIds}
                                onCheck={onCheck}>

                                <CascaderNode
                                    item={item}
                                    cascadeIds={cascadeIds}
                                    onClick={onClick}
                                    isEdit={isEdit}
                                    isCheckbox={isCheckbox}/>
                            </CascaderCheckbox>
                        </Body>
                    );
                })
            }
        </Wrap>
    );
});

export default CascaderListView;
