import React, {useEffect, useState} from "react";
import {withRouter} from "react-router";
import {Props} from "./LayoutPrivateShapes";
import {Layout, Menu, message} from 'antd';
import {NavLink} from "../Routing";
import {SettingOutlined, TagsOutlined, ShoppingOutlined, PlusSquareOutlined, BgColorsOutlined, EyeOutlined} from "@ant-design/icons";
import {UnorderedListOutlined, SoundOutlined, InboxOutlined} from "@ant-design/icons";
import TopbarDivider from "../TopbarDivider/TopbarDivider";
import TopbarMenu from "../TopbarMenu/TopbarMenu";
import TopbarUser from "../TopbarUser/TopbarUser";
import ROUTES from "../../constants/Routes";
import User from "../../interfaces/User";
import AuthService from "../../services/AuthService";

import './LayoutPrivateStyles.scss';
import './LayoutPrivateStylesHeader.scss';
import {TopbarMenuItem} from "../../interfaces/TopbarMenuItem";


const {Header, Sider, Content} = Layout;

const LayoutPrivate: React.FC<Props> = ({children, ...props}) => {
    const [user, setUser] = useState<User | null>(null);
    const [menu, setMenu] = useState<TopbarMenuItem[]>([]);

    const handleLogout = async () => {
        await AuthService.logout();
        message.success('Вы успешно разлогинились!')
        props.history.push(ROUTES.login);
    };

    useEffect(() => {
        const isAdmin = AuthService.hasPrivateAccess();
        setMenu(isAdmin
            ? [{url: ROUTES.looks, title: "Публичка"}, {url: ROUTES.privateApp, title: "Админка"}]
            : [{url: ROUTES.looks, title: "Образы"}]
        );

        const authData: User | null = AuthService.getAuthData();
        setUser(authData);
    }, []);

    return (
        <Layout className="layout-private">
            <Header className="packtum-layout-header">
                <div className="packtum-layout-header__logo">
                    КЕНТ <span>по товарам</span>
                </div>

                <TopbarDivider/>

                <TopbarMenu className="packtum-layout-header__menu" items={menu}/>

                <TopbarDivider/>

                <div className="packtum-layout-header__search">
                    {/*<TopbarSearch/>*/}
                </div>

                {/*<TopbarDivider />*/}

                {/*<div className="packtum-layout-header__informer">*/}
                {/*    <TopbarInformer count={props.informerCount} />*/}
                {/*</div>*/}

                {/*<TopbarDivider />*/}

                <div className="packtum-layout-header__user">
                    {
                        user &&
                        <TopbarUser
                            username={(
                                user.lastName || user.firstName
                                    ? [user.firstName, user.lastName].filter(Boolean).join(' ')
                                    : user.email || `@${user.accTelegram}`
                            )}
                            company={user.email || `@${user.accTelegram}`}
                            onLogout={handleLogout}/>
                    }
                </div>


            </Header>
            <Layout style={{padding: '0', marginTop: 64}}>
                <Sider width={230}>
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={[window.location.pathname]}
                        defaultOpenKeys={[`${ROUTES.settings}-group`, `${ROUTES.products.replace(':id?', '')}-group`]}

                        style={{height: '100%', borderRight: 0}}>

                        <Menu.SubMenu key={`${ROUTES.products.replace(':id?', '')}-group`} icon={<ShoppingOutlined/>}
                                      title="Товары">
                            <Menu.Item key={ROUTES.products.replace(':id?', '')} icon={<UnorderedListOutlined/>}>
                                <NavLink to={ROUTES.products.replace(':id?', '')}>Список товаров</NavLink>
                            </Menu.Item>
                            <Menu.Item key={ROUTES.productParser} icon={<PlusSquareOutlined/>}>
                                <NavLink to={ROUTES.productParser}>Парсер товара</NavLink>
                            </Menu.Item>
                            <Menu.Item key={ROUTES.productCreator} icon={<PlusSquareOutlined/>}>
                                <NavLink to={ROUTES.productCreator}>Создание вручную</NavLink>
                            </Menu.Item>
                            <Menu.Item key={ROUTES.drafts.replace(':id?', '')} icon={<UnorderedListOutlined/>}>
                                <NavLink to={ROUTES.drafts.replace(':id?', '')}>Черновики</NavLink>
                            </Menu.Item>
                        </Menu.SubMenu>

                        <Menu.SubMenu key={`${ROUTES.settings}-group`} icon={<SettingOutlined/>} title="Настройки">
                            <Menu.Item key={ROUTES.channels.replace(':cid?', '')} icon={<SoundOutlined/>}>
                                <NavLink to={ROUTES.channels.replace(':cid?', '')}>Каналы</NavLink>
                            </Menu.Item>
                            <Menu.Item key={ROUTES.filters} icon={<TagsOutlined/>}>
                                <NavLink to={ROUTES.filters}>Фильтры</NavLink>
                            </Menu.Item>
                            <Menu.Item key={ROUTES.lookPreset} icon={<TagsOutlined/>}>
                                <NavLink to={ROUTES.lookPreset}>Пресеты образов</NavLink>
                            </Menu.Item>
                            <Menu.Item key={ROUTES.additionalTag} icon={<TagsOutlined/>}>
                                <NavLink to={ROUTES.additionalTag}>Доп. теги</NavLink>
                            </Menu.Item>
                            <Menu.Item key={ROUTES.colors} icon={<BgColorsOutlined />}>
                                <NavLink to={ROUTES.colors}>Цвета</NavLink>
                            </Menu.Item>
                        </Menu.SubMenu>
                        <Menu.SubMenu key={`${ROUTES.archive.replace(':id?', '')}-group`} icon={<InboxOutlined/>}
                                      title="Архив">
                            <Menu.Item key={ROUTES.archive.replace(':id?', '')} icon={<UnorderedListOutlined/>}>
                                <NavLink to={ROUTES.archive.replace(':id?', '')}>Архив товаров</NavLink>
                            </Menu.Item>
                            <Menu.Item key={ROUTES.archiveLooks} icon={<EyeOutlined/>}>
                                <NavLink to={ROUTES.archiveLooks}>Образы</NavLink>
                            </Menu.Item>
                        </Menu.SubMenu>
                    </Menu>
                </Sider>

                <Layout id="container" style={{padding: 24}}>
                    <Content style={{padding: 24, margin: 0, minHeight: 'auto', background: '#fff'}}>
                        {children}
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
}

export default withRouter(LayoutPrivate);