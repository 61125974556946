import {PureComponent} from "react";
import {Props, State} from "./DraftsListShapes";
import Template from "./DraftsListTemplate";
import ProductModel from "../../models/ProductModel";
import {ProductSaved} from "../../interfaces/Product";
import ROUTES from "../../constants/Routes";
import {message} from "antd";

export class DraftsList extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            products: [],
            page: 1,
            pageSize: 40,
        };
    }

    componentDidMount() {
        this.productsLoadFromServer()
            .catch(err => console.error(err));
    }

    productsLoadFromServer = async () => {
        try {
            const products = await ProductModel.getDrafts();
            this.productsUpdateInList(products);
        } catch (err) {
            console.error(err)
        }
    }

    productsUpdateInList = (products: ProductSaved[]) => {
        this.setState(state => ({...state, products}));
    };

    productRemove = async (item: ProductSaved) => {
        try {
            const products = [...this.state.products];
            const index = products.findIndex(el => el.id === item.id);
            if (index >= 0) {
                const product = products.splice(index, 1)[0];
                await ProductModel.remove(product.id);

                this.setState(state => ({...state, products}), () => {
                    message.success(`Черновик "${product.title}" успешно удален`);
                });
            }
        } catch (err) {
            message.error('Во время удаления черновика возникла ошибка');
            console.error(err.message);
        }
    }

    handleProductClose = () => {
        this.props.history.push(ROUTES.drafts.replace(':id?', ''));
    };

    handleProductSaved = (product: ProductSaved) => {
        const products = this.state.products.filter(el => el.id !== product.id);

        this.setState(state => ({...state, products}), () => {
            this.props.history.push(ROUTES.drafts.replace(':id?', ''));
        });
    };

    handlePageChange = (page: number) => {
        this.setState(state => ({...state, page}), () => {
            document.getElementById('container')?.scrollTo(0, 50);
        });
    }

    get products() {
        return this.state.products;
    }

    get pageStartIndex(): number {
        return (this.state.page - 1) * this.state.pageSize;
    }

    get pageEndIndex(): number {
        return this.state.page * this.state.pageSize;
    }

    render() {
        return Template({
            ...this.state,
            productId: this.props.match.params.id,
            productUrl: ROUTES.drafts,
            productsTotal: this.state.products ? this.products.length : 0,
            products: this.products.slice(this.pageStartIndex, this.pageEndIndex),
            onProductRemove: this.productRemove,

            onProductClose: this.handleProductClose,
            onProductSaved: this.handleProductSaved,

            pageStartIndex: this.pageStartIndex,
            onPageChange: this.handlePageChange,
        });
    };
}

export default DraftsList;
