import {useCallback} from 'react';
import {DropResult} from 'react-beautiful-dnd';
import {CascaderTree} from '../Cascader-types';
import {changePosition} from './CascaderList-utils';

export const useFilterLoad = (
    finalDataCascade: CascaderTree[],
    updatePosition: (data: CascaderTree[]) => void,
    setFinalDataCascade: (value: CascaderTree[]) => void
) => {
    return useCallback((result: DropResult) => {
        if (result.destination) {
            const destinationIndex = result.destination.index;
            const sourceIndex = result.source.index;
            const items = [...finalDataCascade];

            const [recorderItem] = items.splice(sourceIndex, 1);

            items.splice(destinationIndex, 0, recorderItem);

            const results = items.map(changePosition);

            setFinalDataCascade(results);
            updatePosition(results);
        }
    }, [finalDataCascade, setFinalDataCascade, updatePosition])
};
