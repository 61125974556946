import {PureComponent} from "react";
import {Props, State} from "./ProductsListShapes";
import Template from "./ProductsListTemplate";
import FilterModel from "../../models/FilterModel";
import Filter from "../../interfaces/Filter";
import ProductModel from "../../models/ProductModel";
import {ProductSaved} from "../../interfaces/Product";
import {message} from "antd";
import TelegramService from "../../services/TelegramService";
import Channel from "../../interfaces/Channel";
import ChannelModel from "../../models/ChannelModel";
import ROUTES from "../../constants/Routes";
import {ChannelSelected} from '../../interfaces/ChannelSelected';
import {convertFiltersToCascaderTrees, findProductFromFilterTree, getFilterTreesById, getPathBranchById} from '../../helpers/FilterTreeUtils';
import {getObjectKeyByValue} from '../../helpers/ObjectHelpers';
import {chanelCheck} from '../../helpers/ProductUtils';
import {getUniqArray} from '../../helpers/ArrayUtils';

export class ProductsList extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            products: [],
            filters: [],
            filtersVisible: {},
            selectedFilters: {},
            channels: [],
            channelSelected: 'all',
            channelSelectVisible: false,
            channelToSend: undefined,
            isSending: false,
            page: 1,
            pageSize: 40,
        };
    }

    componentDidMount() {
        this.filtersLoadFromServer()
            .catch(err => console.error(err));

        this.productsLoadFromServer()
            .catch(err => console.error(err));

        this.channelsLoadFromServer()
            .catch(err => console.error(err));
    }

    productsLoadFromServer = async () => {
        try {
            const products = await ProductModel.getAll();
            
            this.productsUpdateInList(products);
        } catch (err) {
            console.error(err)
        }
    }

    productsUpdateInList = (products: ProductSaved[]) => {
        this.setState(state => ({
            ...state,
            products: this.productsFilterForList(products),
        }));
    };

    productsFilterForList = (products: ProductSaved[]): ProductSaved[] => {
        return products
            .filter(item => item.channels.some(el => el.isActive) || item.channels.length === 0)
            .map(this.productConvertForList)
    }

    productConvertForList = (product: ProductSaved): ProductSaved => ({
        ...product,
        images: product.images.filter(el => el.toTelegram),
    });


    filtersLoadFromServer = async () => {
        try {
            const filters = await FilterModel.getAllActive();
            this.filtersUpdateInList(filters);
        } catch (err) {
            console.error(err)
        }
    }

    filtersUpdateInList = (filters: Filter[]) => {
        this.setState(state => ({...state, filters}));
    }


    filterClick = (filter: Filter) => {
        const selectedFilters = {...this.state.selectedFilters};

        if (selectedFilters.hasOwnProperty(filter.id)) {
            delete selectedFilters[filter.id];
        } else {
            selectedFilters[filter.id] = "";
        }

        this.setState(state => ({...state, selectedFilters}));
    };

    changeSelectedFilters = (filtersIds: number[]) => {
        const selectedFilters: State['selectedFilters'] = {};

        filtersIds.forEach(filtersId => {
            selectedFilters[filtersId] = "";
        });

        this.setState(state => ({...state, selectedFilters}));
    };

    get filters() {
        const {channelSelected, filtersVisible} = this.state;
        return typeof channelSelected === 'number'
            ? this.state.filters.filter(el => filtersVisible.hasOwnProperty(el.id))
            : this.state.filters;
    };

    channelClick = (selected: State['channelSelected']) => {
        const filtersVisible: State['filtersVisible'] = {};
        const channelSelected: State['channelSelected'] = this.state.channelSelected === selected
            ? 'all'
            : selected;

        if (typeof channelSelected === 'number') {
            const channel = this.state.channels.find(el => el.id === selected);

            if (channel) {
                channel.filters.forEach(filter => {
                    filtersVisible[filter.id] = "";
                });
            }
        }

        this.setState(state => ({
            ...state,
            page: 1,
            channelSelected,
            filtersVisible,
        }));
    };

    productRemove = async (item: ProductSaved) => {
        try {
            const products = [...this.state.products];
            const index = products.findIndex(el => el.id === item.id);
            if (index >= 0) {
                const product = products.splice(index, 1)[0];
                await ProductModel.remove(product.id);

                this.setState(state => ({...state, products}), () => {
                    message.success(`Товар "${product.title}" успешно удален`);
                });
            }
        } catch (err) {
            message.error('Во время удаления возникла ошибка');
            console.error(err.message);
        }
    }

    channelsLoadFromServer = async () => {
        try {
            const channels = await ChannelModel.getAll();
            channels.map(channel => ({
                ...channel,
                filters: channel.filters.filter(el => el.isActive && !el.isDeleted)
            }));
            this.channelsUpdateInList(channels);
        } catch (err) {
            console.error('При загрузке telegram-каналов произошла ошибка: ' + err);
        }
    };

    channelsUpdateInList = (channels: Channel[]) => {
        this.setState(state => ({
            ...state,
            channels: this.props.isArchive
                ? channels.filter(el => !el.isActive)
                : channels.filter(el => el.isActive)
        }));
    };


    handleChannelSelectOpen = () => {
        this.setState(state => ({...state, channelSelectVisible: true}));
    }

    handleChannelSelectClose = () => {
        this.setState(state => ({...state, channelSelectVisible: false, isSending: false}))
    }

    handleProductsSend = (channelId: string, productFrom: number | null = null, productTo: number | null = null) => {
        if (channelId) {
            this.setState(state => ({...state, isSending: true}), async () => {
                await this.handleProductSendToTelegram(channelId, productFrom, productTo);
                await this.handleChannelSelectClose();
            })
        }
    };

    handleProductSendToTelegram = async (channelId: string, productFrom: number | null = null, productsTo: number | null = null) => {
        try {
            const host = window.location.origin;
            const products = this.products;
            const spliceFrom = productFrom ? productFrom - 1 : 0;
            const spliceTo = productsTo ? productsTo : products.length;

            const productsIds = products.slice(spliceFrom, spliceTo)
                .filter(product => product.images.find(image => image.toTelegram))
                .map(product => product.id);

            await TelegramService.sendProductsList(productsIds, channelId, host);
            message.info('Успешно отправлено в телеграм канал');

        } catch (err) {
            message.error('При отправке в телеграм произошла ошибка!');
            this.setState(state => ({...state, isSending: false}));
        }
    };

    handleProductClose = () => {
        this.props.history.push(ROUTES.products.replace(':id?', ''));
    };

    handleProductSaved = (product: ProductSaved) => {
        const products = this.state.products.map(el => el.id === product.id
            ? this.productConvertForList(product)
            : el
        );

        this.setState(state => ({...state, products}), () => {
            this.props.history.push(ROUTES.products.replace(':id?', ''));
        });
    };

    handlePageChange = (page: number) => {
        this.setState(state => ({...state, page}), () => {
            document.getElementById('container')?.scrollTo(0, 50);
        });
    }

    get products() {
        const {selectedFilters, channelSelected} = this.state;
        const filtersLength = Object.keys(selectedFilters).length;
        const filters = [...this.state.filters];

        let products: ProductSaved[] = [];

        if (filtersLength || channelSelected !== ChannelSelected.all) {
            const filtersTree = convertFiltersToCascaderTrees(filters);
            const cloneSelectedFilters = {...selectedFilters};
            const selectedFilterKeys = Object.keys(selectedFilters);

            const paths = selectedFilterKeys.map((filter) => {
                const filterId = Number(filter);
                const path = getPathBranchById(filtersTree, filter);
                cloneSelectedFilters[filterId] = path;

                return path
            }).sort();

            const selectedFilterKeysSort = paths.map(path => {
                return getObjectKeyByValue(cloneSelectedFilters, path);
            });

            for (let index = 0; index < selectedFilterKeysSort.length; index++) {
                const element = Number(selectedFilterKeysSort[index]);

                const node = getFilterTreesById(filtersTree, element);

                const result = findProductFromFilterTree(node, this.state.products).reverse();
                products = [...products, ...result];
            }

            products = getUniqArray(products);

            if (filtersLength && channelSelected !== ChannelSelected.all) {
                products = products.filter(({channels}) => {
                    const hasChannel = chanelCheck(channelSelected, channels);

                    return hasChannel;
                });
            } else if (channelSelected !== ChannelSelected.all) {
                products = this.state.products.filter(({channels}) => {
                    const hasChannel = chanelCheck(channelSelected, channels);

                    return hasChannel;
                });
            }
        } else {
            products = this.state.products;
        }

        return products;
    }

    get pageStartIndex(): number {
        return (this.state.page - 1) * this.state.pageSize;
    }

    get pageEndIndex(): number {
        return this.state.page * this.state.pageSize;
    }

    render() {
        return Template({
            ...this.state,
            isArchive: false,
            productId: this.props.match.params.id,
            productUrl: ROUTES.products,
            productsTotal: this.state.products ? this.products.length : 0,
            products: this.products.slice(this.pageStartIndex, this.pageEndIndex),
            filters: this.filters,
            onFilterClick: this.filterClick,
            onChannelClick: this.channelClick,
            onProductRemove: this.productRemove,
            onProductsSend: this.handleProductsSend,
            onChannelSelectOpen: this.handleChannelSelectOpen,
            onChannelSelectClose: this.handleChannelSelectClose,
            onChangeSelectedFilters: this.changeSelectedFilters,

            onProductClose: this.handleProductClose,
            onProductSaved: this.handleProductSaved,

            pageStartIndex: this.pageStartIndex,
            onPageChange: this.handlePageChange,
        });
    };
}

export default ProductsList;
