import React from "react";
import {TemplateProps} from "./LooksViewShapes";
import LayoutPage from "../../components/LayoutPage/LayoutPage";
import LookBlock from "../../components/LookBlock/LookBlock";
import {Link} from "../../components/Routing";
import ROUTES from "../../constants/Routes";

import "./LooksViewStyles.scss";

const dumbHandler = () => {
};

const LooksViewTemplate: React.FC<TemplateProps> = (props) => (
    <LayoutPage className="looks-view" title={props.item ? (props.item.title || `Просмотр образа #${props.item.id}`) : "Просмотр образа"} extra={
        props.total > 0 && <div className="look-builder__total">{props.total} ₽</div>
    }>
        <div className="looks-view__head">
            <Link to={ROUTES.looks}>{`назад к списку`}</Link>
        </div>

        <div className="look-builder__body">
            <div className="look-builder__column" data-column="left">
                {
                    ['1', '2'].map((blockId: string) => (
                        <LookBlock
                            blockId={blockId}
                            key={blockId}
                            isLocked={true}
                            filters={props.item?.blocks[blockId] ? [props.item?.blocks[blockId].filter] : []}
                            lookBlock={props.item?.blocks[blockId] || null}
                            onChange={dumbHandler}
                            onClear={dumbHandler}
                            onLock={dumbHandler}/>
                    ))
                }
            </div>
            <div className="look-builder__column" data-column="right">
                {
                    ['3', '4', '5', '6', '7'].map(blockId => (
                        <LookBlock
                            blockId={blockId}
                            key={blockId}
                            isLocked={true}
                            filters={props.item?.blocks[blockId] ? [props.item?.blocks[blockId].filter] : []}
                            lookBlock={props.item?.blocks[blockId] || null}
                            onChange={dumbHandler}
                            onClear={dumbHandler}
                            onLock={dumbHandler}/>
                    ))
                }
            </div>
        </div>

    </LayoutPage>
);

export default LooksViewTemplate;